import { useMediaQuery } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";

import FirstAnniversary from '../../assets/join_1st_anniversary.png';
import FifthAnniversary from '../../assets/join_5th_anniversary.png';
import SeeUZoom from '../../assets/join_miro_seeuzoom.png';
import Book from '../../assets/join_miro_weallare_book.png';
import { AnimationContext } from "../../pages/Join2024";
import StyledVoteContainerWithDelay from "./common/VoteContainer";
import { useParallax } from "react-scroll-parallax";
import WithPageLoadAnimation from "./common/WithPageLoadAnimation";

const ParallaxIcons = (props) => {
  const { pages, getFadeInTime } = useContext(AnimationContext);
  const isTablet = useMediaQuery(breakpoint.onlyTablet);
  const isMobile = useMediaQuery(breakpoint.mobile);
  const targetRef = useRef(null)

  const { ref: fastRef } = useParallax({ speed: 10, shouldDisableScalingTranslations: true, })
  const { ref: slowRef } = useParallax({ speed: isTablet ? -3 : -5, shouldDisableScalingTranslations: true })




  const renderDesktopIcons = () => {




    return <>
      <div className="faster" ref={fastRef}>


        <ParallaxImage src={FifthAnniversary} style={{ width: 204 }} alt="MOFC 5th anniversary" className="top-right" />
        <ParallaxImage src={Book} alt="Book" className="bottom-left" style={{ width: 268 }} />

      </div >
      <div className="slower" ref={slowRef} >
        <ParallaxImage src={FirstAnniversary} alt="MOFC 1st anniversary" className="top-left" style={{ width: 120 }} />
        <ParallaxImage src={SeeUZoom} alt="see u zoom" className="bottom-right" style={{ width: 180 }} />

      </div >
    </>
  };
  const renderMobileIcons = () => {
    return (
      <>
        <div className="faster" ref={fastRef} >
          <ParallaxImage src={Book} alt="Book (bottom left)" className="bottom-left" style={{ width: 180 }} />
        </div>
        <div className="slower" ref={slowRef} >
          <ParallaxImage src={FirstAnniversary} alt="MOFC 1st anniversary (top left)" className="top-left" style={{ left: '10%', width: 89 }} />
          <div className="bottom-right" >
            <ParallaxImage src={SeeUZoom} alt="see u zoom (bottom right)" style={{ width: 82, left: '10%', top: 10, position: 'relative' }} />
            <ParallaxImage src={FifthAnniversary} alt="MOFC 5th anniversary (bottom right)" style={{ width: 98, zIndex: 1, position: 'relative' }} />
          </div>
        </div>

      </>
    );
  };

  return <>
    <StyledIcons delay={getFadeInTime(pages.PARALLAX)} className="parallax-container" ref={targetRef}>
      {isMobile ? renderMobileIcons() : renderDesktopIcons()}
    </StyledIcons>


  </>
}

const ParallaxImage = styled.img`
  //position: absolute;
`

const StyledIcons = styled(WithPageLoadAnimation)`
  position: absolute;
  width: 100%; 
  height: 100%;

   @media ${breakpoint.desktop}{
    min-width: 900px;
    max-width: 1200px;
  } 
  z-index: -1;

  .faster, .slower{
    position: absolute;
    width: 100%;
    height: 100%;
  }


  .top-left, .top-right, .bottom-left, .bottom-right{
    position: absolute;
  }


  .top-left{
    top: -110%;
    @media ${breakpoint.smallTablet}{
      top: -130%;
    }
    left: 0;
    transform: translateX(-50px);

    @media ${breakpoint.desktop}{
      top: 35%;
      transform: translateX(70px);
      width:120px;
    }

  }
  .top-right{
    top: -100%;
    right: 0;
    transform: translateX(50px);

    @media ${breakpoint.desktop}{

      top: 35%;
      transform: translateX(30px);
    }
  }
  .bottom-left{
    top: 160%;
    left: 0;
    transform: translateX(-100px);

    @media ${breakpoint.desktop}{
      top: 90%;
      transform: translateX(10px);
    }
  }
  .bottom-right{
    top: 130%;
    right: max(-5vw, -200px);
    transform: translateX(50px);

    @media ${breakpoint.desktop}{
      top: 80%;
    transform: translateX(-70px);
    }
  }


`;


export default ParallaxIcons