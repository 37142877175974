import { updateTmpUser, updateUser } from "../../apis";
import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import FormTextField from "../../components/common/textfields/FormTextField";
import FormSelect from "../../components/common/selects/FormSelect";
import { useState, useRef, useContext } from "react";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import { AppContext } from "../../AppContext";
import Districts from "../../utils/districts.json";
import AddressInputForm from "../../components/AddressInputForm";
import DeliveryAddress from "./DeliveryAddress";

export default function Step3({ handlePageChange, setSessionExpired }) {
  const { t, i18n } = useTranslation("signin");
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(session?.user?.addresses[0]);

  const validate = () => {
    let error = {};
    // if (region == null || region == "")
    //   error.region = t("signin:register.invalid_region");
    // if (area == null || area == "")
    //   error.area = t("signin:register.invalid_area");
    // if (
    //   address1Ref.current.value.length < 2 ||
    //   address1Ref.current.value.length > 80
    // )
    //   error.address1 = t("signin:register.invalid_address");
    // if (
    //   address2Ref.current.value.length < 2 ||
    //   address2Ref.current.value.length > 80
    // )
    //   error.address2 = t("signin:register.invalid_address");
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const handleNext = async (back = false) => {
    if (!loading && (back || validate())) {
      setLoading(true);
      const body = {
        addresses: [address],
      };
      console.log(body);
      let result = null;
      if (session?.user?.memberType == "TMP")
        result = await updateTmpUser(session.accessToken, body);
      else result = await updateUser(session.accessToken, body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem("session", JSON.stringify(newSession));
        setSession(newSession);
        if (back) handlePageChange("step2");
        else handlePageChange("step4");
      } else {
        // set api errori
        if (result.error?.code == "E0002") {
          setSessionExpired(true);
        } else if (back) handlePageChange("step2");
      }
      setLoading(false);
    }
  };
  return (
    <DeliveryAddress
      step=" 3/6"
      session={session}
      handleNext={handleNext}
      address={address}
      setAddress={setAddress}
    />
  );
}
