import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CommonText from "../../components/common/typographies/CommonText";
import ImgWelcomePack from "../../assets/home_welcomePack.png";
import ImgLine from "../../assets/home_visitorLineBg.svg";
import MobileImgLine from "../../assets/home_visitorLineMobileBg.svg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const MemberWelcomePack = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <div style={{ position: "relative"}}>
      <StyledMainDiv>
        <ResponsiveUI
          desktop={<ImageLineBg src={ImgLine} />}
          mobile={<ImageLineBg src={MobileImgLine} />}
        />
        <StyledContent>
          {i18n.language === "zh" && (
            <CommonText fontSize="30px" mFontSize="17px">
              MIRO 會員迎新禮盒
            </CommonText>
          )}
          <CommonText
            largeFontSize="70px"
            fontSize="50px"
            mFontSize="33px"
            type="primary"
            uppercase
          >
            MIRO Member
          </CommonText>
          <StyledWelcomePackDiv>
            <CommonText
              largeFontSize="60px"
              fontSize="40px"
              mFontSize="25px"
              fontWeight="600"
              uppercase
            >
              Welcome Pack
            </CommonText>
          </StyledWelcomePackDiv>
          <CommonText fontSize="20px" mFontSize="16px">
            {t("info.welcomePackInclude")}
          </CommonText>
          <CommonText fontSize="14px" mFontSize="12px">
            {t("info.welcomePackremark")}
          </CommonText>
        </StyledContent>

        <StyledImage src={ImgWelcomePack} />
      </StyledMainDiv>
    </div>
  );
};

const StyledMainDiv = styled.div`
  background-color: #c0c0ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media ${breakpoint.mobile} {
    flex-direction: column;
    padding: 20px;
    height: auto;
    padding: 70px 20px;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
    padding: 10%;
    height: 100vh;
  }
`;

const ImageLineBg = styled.img`
  user-drag: none;
  position: absolute;
  left: 0px;

  @media ${breakpoint.mobile} {
    bottom: -30%;
  }

  @media ${breakpoint.desktop} {
    top: 10%;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  max-width: 650px;
  text-align: left;
  position: relative;

  @media ${breakpoint.mobile} {
    margin-bottom: 45px;
  }

  @media ${breakpoint.desktop} {
    margin-bottom: 0px;
  }
`;

const StyledWelcomePackDiv = styled.div`
  @media ${breakpoint.mobile} {
    margin-bottom: 20px;
    text-align: left;
  }

  @media ${breakpoint.desktop} {
    margin-bottom: 0px;
    text-align: center;
  }
`;

const StyledImage = styled.img`
  user-drag: none;
  height: auto;
  position: relative;

  @media ${breakpoint.mobile} {
    width: 100%;
  }

  @media ${breakpoint.desktop} {
    width: 50%;
  }
`;
export default MemberWelcomePack;
