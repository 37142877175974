import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import styled from 'styled-components';
import colors from '../../utils/colors';
import CommonText from '../../components/common/typographies/CommonText';
import DotIcon from '../../assets/login_feature_dot.svg';
import Dot2024Icon from '../../assets/login_feature_2024_dot.svg';
import subscription2024Image from '../../assets/subscription/2024.jpg';
import subscription2023Image from '../../assets/subscription/2023.jpg';
import subscription2022Image from '../../assets/subscription/2022.jpg';
import CommonDivider from '../../components/common/divider/CommonDivider';
import { ResponsiveUI, breakpoint } from '../../utils/responsive';
import { Grid } from '@mui/material';

const Feature = ({ year, option, children }) => {
  return (
    <FeatureContainer>
      <img
        src={year == 2024 ? Dot2024Icon : DotIcon}
        style={{ marginTop: '6px' }}
      />
      <ResponsiveUI
        mobileOffset={219}
        desktop={
          <div>
            <CommonText
              fontSize="18px"
              lineHeight="25px"
              style={{
                marginLeft: '24px',
                textAlign: 'start',
              }}
            >
              {children}
            </CommonText>
            {option && (
              <CommonText
                fontSize="17px"
                lineHeight="23px"
                style={{
                  color: '#111111',
                  opacity: 0.6,
                  marginLeft: '24px',
                  textAlign: 'start',
                }}
              >
                {option}
              </CommonText>
            )}
          </div>
        }
        mobile={
          <div>
            <CommonText
              fontSize="16px"
              lineHeight="22px"
              style={{
                marginLeft: '14px',
                textAlign: 'start',
              }}
            >
              {children}
            </CommonText>
            {option && (
              <CommonText
                fontSize="15px"
                lineHeight="21px"
                style={{
                  color: '#111111',
                  opacity: 0.6,
                  marginLeft: '14px',
                  textAlign: 'start',
                }}
              >
                {option}
              </CommonText>
            )}
          </div>
        }
      />
    </FeatureContainer>
  );
};
const FeatureContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

function SubscriptionYearCard({ t, subscription, isValid }) {
  let year = moment(subscription.expireDate).year();
  const month = moment(subscription.expireDate).month() + 1;
  const day = moment(subscription.expireDate).date();
  let type = null;
  if (year == 2024) type = subscription2024Image;
  else if (year == 2023 && month == 12 && day == 31)
    type = subscription2023Image;
  else {
    type = subscription2022Image;
    year = 2022;
  }
  return (
    <Container>
      <ResponsiveUI
        largeDesktop={
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  padding: '0px 32px',
                  minHeight: 100,
                }}
              >
                <CommonText
                  fontWeight={700}
                  lineHeight="16px"
                  style={{ fontFamily: 'Philosopher' }}
                >
                  MIRO
                </CommonText>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 4,
                  }}
                >
                  <CommonText
                    fontWeight={700}
                    lineHeight="40px"
                    fontSize="40px"
                    style={{ color: '#111111', fontFamily: 'Philosopher' }}
                  >
                    {year == '2022'
                      ? t('profile:membership.membership_first_round')
                      : year}
                  </CommonText>
                  <CommonText
                    fontWeight={400}
                    lineHeight="22px"
                    fontSize="22px"
                  >
                    {t('profile:membership.membership')}
                  </CommonText>
                </div>
              </div>
              <CommonText
                style={{
                  height: 40,
                  borderTop: '1px solid #00000033',
                  textAlign: 'left',
                  paddingLeft: 32,
                }}
                fontSize=" 16px"
                lineHeight="40px"
              >
                {moment(subscription.activateDate)
                  .locale('en')
                  .format(t('profile:membership.date_format')) +
                  ' - ' +
                  moment(subscription.expireDate)
                    .locale('en')
                    .format(t('profile:membership.date_format'))}
              </CommonText>
            </div>
            <img height={140} style={{ aspectRatio: '5/3' }} src={type} />
          </div>
        }
        mobile={
          <div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: 112,
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  padding: '0px 8px 0px 24px',
                  alignItems: 'flex-start',
                  gap: 4,
                }}
              >
                <CommonText
                  fontWeight={700}
                  lineHeight="14px"
                  fontSize="14px"
                  style={{ fontFamily: 'Philosopher' }}
                >
                  MIRO
                </CommonText>
                <CommonText
                  fontWeight={700}
                  lineHeight="36px"
                  fontSize="36px"
                  style={{ fontFamily: 'Philosopher' }}
                >
                  {year == '2022'
                    ? t('profile:membership.membership_first_round')
                    : year}
                </CommonText>
                <CommonText fontWeight={400} lineHeight="20px" fontSize="20px">
                  {t('profile:membership.membership')}
                </CommonText>
              </div>
              <img height={112} style={{ aspectRatio: '5/3' }} src={type} />
            </div>
            <CommonText
              fontSize=" 14px"
              lineHeight="42px"
              style={{
                height: 42,
                borderTop: '1px solid #00000033',
                padding: '0px 24px',
                textAlign: 'left',
              }}
            >
              {moment(subscription.activateDate)
                .locale('en')
                .format(t('profile:membership.date_format')) +
                ' - ' +
                moment(subscription.expireDate)
                  .locale('en')
                  .format(t('profile:membership.date_format'))}
            </CommonText>
          </div>
        }
      />
      {isValid && (
        <div
          style={{
            borderTop: '1px solid #00000033',
          }}
        >
          <FeatureColumnContainer>
            {year == 2024
              ? t('profile:membership.features2024', {
                  returnObjects: true,
                }).map((feature, index) => {
                  return (
                    <>
                      <Feature
                        option={t(
                          'profile:membership.features2024_desc.' + index
                        )}
                        year={year}
                      >
                        {feature}
                      </Feature>
                      {index == 0 && (
                        <CommonText
                          mFontSize="16px"
                          fontSize=" 18px"
                          lineHeight="24px"
                          style={{
                            color: '#111111',
                            textAlign: 'left',
                            paddingBottom: 10,
                          }}
                        >
                          {t('profile:membership.features2024_title')}
                        </CommonText>
                      )}
                    </>
                  );
                })
              : t('profile:membership.features', { returnObjects: true }).map(
                  (feature) => <Feature year={year}>{feature}</Feature>
                )}
          </FeatureColumnContainer>

          <RemarkContainer>
            <CommonText
              fontSize={'14px'}
              color={colors.black}
              style={{ opacity: '0.6', textAlign: 'start', marginRight: 32 }}
            >
              {t('profile:membership.remark1')}
            </CommonText>
            <CommonText
              fontSize={'14px'}
              color={colors.black}
              style={{ opacity: '0.6', textAlign: 'start' }}
            >
              {t('profile:membership.remark2')}
            </CommonText>
          </RemarkContainer>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  border: 1px solid #00000033;
  margin-bottom: 24px;
  @media (max-width: 1079px) {
    max-width: 640px;
  }
`;

const FeatureColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding 24px 32px;
  @media ${breakpoint.mobile} {
    padding 24px;
  }
`;

const RemarkContainer = styled.div`
  padding: 0px 32px 40px 32px;
  display: flex;
  flex-wrap: wrap;
  @media ${breakpoint.mobile} {
    padding 0px 24px 32px 24px;
  }
`;

export default SubscriptionYearCard;
