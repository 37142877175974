import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonLabel from "../labels/CommonLabel";
import CommonTextField from "./CommonTextField";
import FormHelperText from "@mui/material/FormHelperText";
import TextButton from "../buttons/TextButton";
import { requestEmailOTP } from "../../../apis";
import { useTranslation } from "react-i18next";
import constants from "../../../constants";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import FailIcon from "../../../assets/payment_fail.svg";
import HintIcon from "../../../assets/hint.svg";
import CloseButton from "../../../components/common/buttons/CloseButton";
import CommonText from "../typographies/CommonText";
import Space from "../Space";
import CommonButton from "../buttons/CommonButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ResponsiveUI } from "../../../utils/responsive";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 16,
    backgroundColor: "#ffffff",
    color: "#111111",
    fontSize: 14,
    border: "1px solid #E2E2E2",
    boxShadow: "0px 16px 27px rgba(0, 0, 0, 0.05)",
  },
}));

function EmailTextfield(props) {
  let {
    contactHint = false,
    helperText,
    errorText,
    label,
    requestOTP,
    defaultValue,
    purpose,
    username,
  } = props;
  let localStorageCountDownKey = purpose + "_emailOTP";
  const navigate = useNavigate();
  const [email, setEmail] = useState(defaultValue);
  const [countdown, setCountDown] = useState(null);
  const [userExist, setUserExist] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation(["common", "signin"]);
  useEffect(() => {
    const count = localStorage.getItem(localStorageCountDownKey, null);
    if (count && count > Date.now()) {
      setCountDown(Number(count));
    } else {
      onCompleteCountDown();
    }
  }, []);
  const handleRequestOTP = async () => {
    console.log("handleRequestOTP");
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (validEmail.test(email)) {
      const result = await requestEmailOTP(email, purpose, username);
      console.log(result);
      if (result.success) {
        let emailCountDown = Date.now() + 600 * 1000;
        setCountDown(emailCountDown);
        localStorage.setItem(localStorageCountDownKey, emailCountDown);
        setError(t("signin:register.email_otp_sent"));
      } else {
        if (result.error?.code == "C0001") setUserExist(true);
        else setError(t("signin:register.invalid_email"));
      }
    } else {
      setError(t("signin:register.invalid_email"));
    }
  };
  const onCompleteCountDown = () => {
    // Clear Email count down
    localStorage.removeItem(localStorageCountDownKey);
    setCountDown(null);
  };
  const handleChange = (event) => {
    setEmail(event.target.value.toLowerCase().trim());
    onCompleteCountDown();
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <TextButton
          style={{ width: "auto", whiteSpace: "nowrap", textAlign: "right" }}
          onClick={handleRequestOTP}
        >
          {t("common:request_otp")}
        </TextButton>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      );
    }
  };
  return (
    <StyledDiv>
      <StyledLabelDiv>
        <CommonLabel>{label ? label : ""}</CommonLabel>
        {contactHint && (
          <ResponsiveUI
            desktop={
              <LightTooltip
                title={t("signin:register.contact_email_hint")}
                placement="right"
              >
                <img style={{ marginLeft: 10 }} src={HintIcon} />
              </LightTooltip>
            }
            mobile={
              <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                <div
                  style={{
                    marginLeft: 10,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LightTooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    title={t("signin:register.contact_email_hint")}
                    placement="top"
                  >
                    <img onClick={() => setTooltipOpen(true)} src={HintIcon} />
                  </LightTooltip>
                </div>
              </ClickAwayListener>
            }
          />
        )}
      </StyledLabelDiv>
      <CommonTextField
        {...props}
        label=""
        type="email"
        value={email}
        InputProps={{
          endAdornment: requestOTP ? (
            countdown ? (
              <Countdown
                date={countdown}
                renderer={renderer}
                onComplete={onCompleteCountDown}
              />
            ) : (
              <TextButton
                style={{
                  width: "auto",
                  whiteSpace: "nowrap",
                  textAlign: "right",
                }}
                onClick={handleRequestOTP}
              >
                {t("common:request_otp")}
              </TextButton>
            )
          ) : null,
        }}
        onChange={handleChange}
      />
      <StyledErrorText>{error || (errorText ? errorText : "")}</StyledErrorText>
      <StyledHelperText>{helperText ? helperText : ""}</StyledHelperText>
      <Dialog
        onClose={() => {
          setUserExist(false);
        }}
        open={userExist}
      >
        <div
          style={{
            padding: 80,
            width: 960,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CommonText
            whiteSpace="pre-line"
            fontWeight={600}
            fontSize="30px"
            lineHeight="42px"
          >
            {t("signin:register.user_exist")}
          </CommonText>
          <Space size="25px" />
          <img src={FailIcon} alt="" />
          <Space size="25px" />
          <CommonButton
            size="large"
            type={"primary"}
            onClick={() => {
              navigate("/signin", { replace: true });
            }}
          >
            {t("signin:signin.login_now")}
          </CommonButton>
          <CloseButton
            onClick={() => {
              setUserExist(false);
            }}
          />
        </div>
      </Dialog>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
`;

const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff5c00;
  }
`;

const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export default EmailTextfield;
