import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import CommonNavBar from '../../components/common/navbar/CommonNavBar';
import CommonBottomBar from '../../components/common/navbar/CommonBottomBar';
import CommonText from '../../components/common/typographies/CommonText';
import colors from '../../utils/colors.js';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';

import { ReactComponent as ImgLine } from '../../assets/home_line.svg';
import { ReactComponent as ImgMobileLine } from '../../assets/home_mobileLine.svg';
import ImgVisitorBg from '../../assets/home_visitorBg.jpg';
import ImgVisitorMobileBg from '../../assets/home_visitorMobileBg.jpg';
import ImgJoinMiroMobileZh from '../../assets/home_joinMiroStickerMobileZh.svg';
import ImgJoinMiroMobileEn from '../../assets/home_joinMiroStickerMobileEn.svg';
import ImgJoinMiroZh from '../../assets/home_joinMiroStickerZh.svg';
import ImgJoinMiroEn from '../../assets/home_joinMiroStickerEn.svg';
import * as animationDataEn from '../../assets/animation/miro_btn_en.json';
import * as animationDataTc from '../../assets/animation/miro_btn_tc.json';
import ReactGA from 'react-ga4';

const VistorWelcome = ({ disableMiroReg, user }) => {
  const { t, i18n } = useTranslation('home');
  const isMiro = user && user.memberType === 'MIRO';

  const defaultTcOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataTc,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultEnOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataEn,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const _renderDesktop = () => {
    return (
      <div>
        <StyledMainDiv>
          <CommonNavBar theme="white" whiteMenu={true} />
          <StyledContentDiv>
            <StyledTextDiv>
              {i18n.language == 'zh' && (
                <ShowupAnima>
                  <CommonText
                    mFontSize="35px"
                    fontSize="2vw"
                    largeFontSize="30px"
                    fontWeight="600"
                    letterSpacing="12px"
                    color={colors.white}
                    style={{ textShadow: '0px 2px 20px rgba(0, 0, 0, 0.4)' }}
                  >
                    MIRROR 官方歌迷會
                  </CommonText>
                </ShowupAnima>
              )}
              <ShowupAnima animaDelay="0.3s">
                <CommonText
                  fontSize="4vw"
                  largeFontSize="64px"
                  fontWeight="400"
                  letterSpacing="0px"
                  color={colors.white}
                  type="primary"
                  uppercase
                  style={{ textShadow: '0px 2px 20px rgba(0, 0, 0, 0.4)' }}
                >
                  mirror official fan club
                </CommonText>
              </ShowupAnima>
              <ShowupAnima animaDelay="0.5s">
                <CommonText
                  fontSize="3vw"
                  largeFontSize="48px"
                  fontWeight="600"
                  color={colors.white}
                  uppercase
                  style={{ textShadow: '0px 2px 20px rgba(0, 0, 0, 0.4)' }}
                >
                  be our miro
                </CommonText>
              </ShowupAnima>
              {!disableMiroReg && !isMiro && (
                <Link
                  to={`/join`}
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    ReactGA.event('button_click', {
                      button_text: 'Be Our MIRO button',
                    });
                    ReactGA.event('be_miro', {
                      param: 'top',
                    });
                  }}
                >
                  <StickerJoinMiro>
                    <MiroStickerAnima>
                      <Lottie
                        options={
                          i18n.language == 'zh'
                            ? defaultTcOptions
                            : defaultEnOptions
                        }
                        height="auto"
                        width="100%"
                      />
                    </MiroStickerAnima>
                  </StickerJoinMiro>
                </Link>
              )}
              <StyledAnimationLineDiv isZhLocale={i18n.language == 'zh'}>
                <ImgLine />
              </StyledAnimationLineDiv>
            </StyledTextDiv>
          </StyledContentDiv>
        </StyledMainDiv>
        <CommonBottomBar bgColor="yellow" theme="black" />
      </div>
    );
  };

  const _renderMobile = () => {
    return (
      <StyledMobilMainDiv>
        <div>
          <CommonNavBar theme="white" whiteMenu={true} />
        </div>

        <StyledMobileTextDiv>
          <StyledTextDiv>
            <div style={{ height: 34 }}>
              {i18n.language == 'zh' && (
                <ShowupAnima>
                  <CommonText
                    fontSize="18px"
                    letterSpacing="5px"
                    color={colors.white}
                  >
                    MIRROR 官方歌迷會
                  </CommonText>
                </ShowupAnima>
              )}
            </div>
            <ShowupAnima animaDelay="0.3s">
              <CommonText
                fontSize="40px"
                color={colors.white}
                type="primary"
                uppercase
              >
                mirror official fan club
              </CommonText>
            </ShowupAnima>
            <ShowupAnima animaDelay="0.5s">
              <CommonText
                fontSize="38px"
                fontWeight="600"
                color={colors.white}
                uppercase
              >
                be our miro
              </CommonText>
            </ShowupAnima>
            {!disableMiroReg && !isMiro && (
              <Link
                to={`/join`}
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  ReactGA.event('be_miro', {
                    param: 'top',
                  });
                }}
              >
                <StickerJoinMiroMobile>
                  <MiroStickerAnima>
                    <Lottie
                      options={
                        i18n.language == 'zh'
                          ? defaultTcOptions
                          : defaultEnOptions
                      }
                      height="auto"
                      width={188}
                    />
                  </MiroStickerAnima>
                </StickerJoinMiroMobile>
              </Link>
            )}
            <StyledAnimationLineDiv isZhLocale={i18n.language == 'zh'}>
              <ImgMobileLine />
            </StyledAnimationLineDiv>
          </StyledTextDiv>
        </StyledMobileTextDiv>
        <StyledMobileBgDiv />
        <CommonBottomBar bgColor="yellow" theme="black" />
      </StyledMobilMainDiv>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
};

const StyledAnimationLineDiv = styled.div`
  position: absolute;
  bottom: ${(props) => (props.isZhLocale ? '-10%' : '-15%')};
  margin-left: 15%;
  left: 0px;
  svg {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    pointer-events: none;
    animation: theAnimation 2s ease-in-out 1s forwards 1;
  }

  @keyframes theAnimation {
    to {
      stroke-dashoffset: 0;
    }
  }
  @media ${breakpoint.desktop} {
    bottom: -20%;
    left: auto;
    right: 12%;
    transform: scale(0.5);
    margin-left: 0;
    margin-right: 0;
  }
  @media ${breakpoint.largeDesktop} {
    bottom: -16%;
    right: 12%;
    right: 20%;
    transform: scale(0.5);
    margin-left: 0;
    margin-right: 0;
  }
`;

const ShowupAnima = styled.div`
  bottom: -20px;
  opacity: 0;
  position: relative;
  animation-name: showUp;
  animation-duration: 0.8s;
  animation-delay: ${(props) => props.animaDelay};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const StyledMobileTextDiv = styled.div`
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMobileBgDiv = styled.div`
  background-image: url(${ImgVisitorMobileBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 100%;
  object-fit: contain;
  position: relative;
`;

const MiroStickerAnima = styled.div`
  animation-name: showOpacity;
  animation-duration: 0.8s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  opacity: 0;

  @keyframes showOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StickerJoinMiroMobile = styled.div`
  user-drag: none;
  position: absolute;
  bottom: -70%;
  right: 0px;
  z-index: 10;
`;

const StyledMainDiv = styled.div`
  background-image: url(${ImgVisitorBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${breakpoint.desktop} {
    height: calc(100vh - 45px);
  }
  @media ${breakpoint.largeDesktop} {
    height: calc(100vh - 58px);
  }
`;

const StyledContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledMobilMainDiv = styled.div`
  background: #0d0f10;
`;

const StyledTextDiv = styled.div`
  position: relative;
  width: auto;

  @media ${breakpoint.mobile} {
    margin-top: -70px;
  }
  @media ${breakpoint.desktop} {
    top: 2%;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.75));
  }
  @media ${breakpoint.largeDesktop} {
    top: 2%;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.75));
  }
`;

const StickerJoinMiro = styled.div`
  user-drag: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-15%, 50%);

  @media ${breakpoint.desktop} {
    left: 0%;
    right: auto;
    transform: rotate(50deg) translate(35%, 50%) scale(0.7);
  }
  @media ${breakpoint.largeDesktop} {
    left: 5%;
    right: auto;
    transform: rotate(50deg) translate(35%, 50%) scale(0.8);
  }
`;

export default VistorWelcome;
