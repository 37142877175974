import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../../utils/responsive'
import MiroPack from '../../../assets/join_miro_pack.png'
import MiroGiftTitleZh from '../../../assets/join_miro_gift_pack_zh.png'
import MiroGiftTitleEn from '../../../assets/join_miro_gift_pack_en.png'
import { ReactComponent as Hexagon } from '../../../assets/join_orange_hexagon.svg'
import { useTranslation } from 'react-i18next'
import CommonText from '../../../components/common/typographies/CommonText'
import Star from '../../../assets/join_star.png'
import { useMediaQuery } from '@mui/material'

const MiroGift = () => {
  // deal with ResizeObserver to change flex-direction
  // https://dev.to/sarmunbustillo/detect-when-an-element-gets-wrapped-flexbox-grid-f96
  const { t, i18n } = useTranslation("signin")
  const isMobile = useMediaQuery(breakpoint.mobile)

  return (
    <StyledMiroGift>
      <StyledImage src={MiroPack} alt="miro_gift_image" />
      <StyleGiftBoxSection >
        <StyledGiftBoxTitle src={i18n.language === 'zh' ? MiroGiftTitleZh : MiroGiftTitleEn} alt="miro_member_gift_title" />
        <div style={{ margin: 'auto 0' }}>
          <StyledBenefitListItem>
            <StyledHexagon />
            <CommonText fontSize="16px" largeFontSize="24px" lineHeight="140%" style={{ marginLeft: 24 }} textAlign="start">{t('join2024.membershipBenefit.miroGiftPack.0')}</CommonText>
          </StyledBenefitListItem>
          <StyledBenefitListItem>
            <StyledHexagon />
            <CommonText fontSize="16px" largeFontSize="24px" lineHeight="140%" style={{ marginLeft: 24 }} textAlign="start">{t('join2024.membershipBenefit.miroGiftPack.1')}</CommonText>
          </StyledBenefitListItem>
          <StyledBenefitListItem>
            <StyledHexagon />
            <CommonText fontSize="16px" largeFontSize="24px" lineHeight="140%" style={{ marginLeft: 24 }} textAlign="start">{t('join2024.membershipBenefit.miroGiftPack.2')}</CommonText>
          </StyledBenefitListItem>
        </div>
      </StyleGiftBoxSection>

      <img src={Star} style={{ position: 'absolute', left: isMobile ? '80%' : '90%', bottom: -100, width: 80, height: 80 }} alt="star asset" />
    </StyledMiroGift>
  )
}

const StyledHexagon = styled(Hexagon)`
  flex-shrink: 0;
`

const StyleGiftBoxSection = styled.div`
  

  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 960px){
    display: flex;
    flex-direction: row;
    padding: 0 15px;
  }
 
`

const StyledMiroGift = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 100px;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  @media ${breakpoint.mobile}{
    margin-bottom: 60px;
    flex-direction: column;
  }
  @media (max-width: 960px){
    flex-direction: column;
    img{
      margin-bottom: 15px;
    }
  }
 @media (min-width: 961px){
    flex-direction: row-reverse;
    img{
      margin-left: 30px;
    }
  }

`
const StyledImage = styled.img`
  width: 600px;
  @media ${breakpoint.mobile}{
    width: 100%;
    max-width: 500px;
  }
  @media ${breakpoint.smallTablet}{
    width: 100%;
    max-width: 390px;
  }
`

const StyledGiftBoxTitle = styled.img`
  width: 300px;
  height: 200px;
  transform: rotate(-10deg);
  margin-right: 10px;
  margin-bottom: 30px;
  @media ${breakpoint.mobile}{
    width: 150px;
    height: 100px;
    transform: none;
  }
  
  media (max-width: 960px){
    margin-right: 30px;
  }
  media (min-width: 961px){
    margin-bottom: 30px;
  }

  //margin direction depends on flex-wrap
`

const StyledBenefitListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`



export default MiroGift