import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
if (window.jwplayer)
  window.jwplayer.key = "nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA==";

const VideoDisplay = ({ item, handleOnPlay, isSelected }) => {
  // const myRef = useRef();
  // const [canPlay, setCanPlay] = useState(false);
  // useEffect(() => {
  //   myRef.current &&
  //     myRef.current.addEventListener("canplay", () => {
  //       console.log("dimension", myRef.current.videoHeight); // some resources can not get this value
  //       setCanPlay(true);
  //     });
  // }, [myRef.current]);
  const playerId = `vodplayer-${item?._id}`;
  useEffect(()=>{
    if(item != null && isSelected){
      const player = window.jwplayer(playerId);
      player.setup({
        playlist: [
          {
            sources: [
              {
                default: false,
                file: item.type === "VIDEO" ? item.video : item.streamUrl,
                type: item.type === "VIDEO" ? "mp4" : "hls",
                label: 1,
              },
            ],
            withCredentials: true,
          },
        ],
        width: "100%",
        aspectratio: "16:9",
        autostart: false,
        cast: {},
        mute: false,
        volume: 75,
        allowFullscreen: true,
      }).on('play', handleOnVideoPlay);
    }

  },[item, isSelected])

  const [firstPlayed, setFirstPlayed] = useState(true);
  
  const handleOnVideoPlay = () => {
    if (firstPlayed) {
      setFirstPlayed(false);
    }
    handleOnPlay(firstPlayed);
  };

  return (
    <Container>
      <div id={playerId}/>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 0;
  .jw-icon-rewind {
    display: none;
  }
  .jw-display-icon-rewind {
    visibility: hidden;
  }
`

export default VideoDisplay;
