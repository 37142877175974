import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CommonText from '../../../components/common/typographies/CommonText';
import IconArrowBlack from '../../../assets/ic-arrowRight-black.svg';
import { Link } from 'react-router-dom';
import Order2022Icon from '../../../assets/welcome_kit_2022.jpg';
import Order2023Icon from '../../../assets/welcome_kit_2023.jpg';
import Order2024Icon from '../../../assets/welcome_kit_2024.jpg';
import moment from 'moment';
import 'moment-timezone';
import { ProfileMenuItem } from '../../../pages/ProfileMenu';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';

const icons = {
  '2024 MIRO Subscription': Order2024Icon,
  '2023 MIRO Subscription': Order2023Icon,
  'MIRO Subscription': Order2022Icon,
};

function OrderItem(props) {
  const { onClick, order } = props;
  const { t, i18n } = useTranslation('profile');
  const icon = icons[order?.item?.name];
  const _renderContent = (isMobile) => {
    return (
      <StyledMainDiv isMobile={isMobile} onClick={onClick}>
        <Link
          to={`/${ProfileMenuItem.PROFILE_ORDER_DETAIL.replace(
            ':orderId',
            order._id
          )}`}
          style={{ textDecoration: 'none' }}
        >
          <StyledHeaderDiv>
            <CommonText
              lineHeight={isMobile ? '1.2' : '35px'}
              fontSize="25px"
              mFontSize="20px"
              fontWeight="600"
              style={{ textAlign: 'start', wordBreak: 'break-word' }}
            >
              {t('order.order', { order_number: order._id })}
            </CommonText>
            <ArrowButton src={IconArrowBlack} />
          </StyledHeaderDiv>

          <StyledItemDiv>
            <CommonText>
              {t('profile:order.order_date_count', {
                order_date: moment(order.createdAt)
                  .tz('Asia/Hong_Kong')
                  .locale(i18n.language === 'en' ? 'en' : 'zh-hk')
                  .format('YYYY.MM.DD hh:mma'),
                item_count: order.item.quantity,
              })}
            </CommonText>
            <ItemImage src={icon} />
          </StyledItemDiv>
        </Link>
      </StyledMainDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.isMobile
      ? 'padding: 20px;'
      : 'padding: 20px 0 20px 0; max-width: 592px;'}
  cursor: pointer;
`;
const StyledHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledItemDiv = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
`;

const ItemImage = styled.img`
  margin-top: 10px;

  @media ${breakpoint.mobile} {
    width: 120px;
  }

  @media ${breakpoint.desktop} {
    width: 174px;
  }
`;

const ArrowButton = styled.img`
  margin-rigth: 18px;

  @media ${breakpoint.mobile} {
    margin-rigth: 0px;
  }
`;

export default OrderItem;
