import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import Space from "../../components/common/Space";
import PasswordIcon from "../../assets/login-password-icon.svg";
import CommonText from "../../components/common/typographies/CommonText";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import FacebookSignin from "../signin/FacebookSignin";
import GoogleSignin from "../signin/GoogleSignin";
import AppleSignin from "../signin/AppleSignin";
import { breakpoint } from "../../utils/responsive";

export default function RootRegister({ handlePageChange }) {
  const { t } = useTranslation("signin");
  return (
    <Container>
      <Space size="100px" />
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">{t('register.miro_register_title')}</CommonText>
      <Space size="82px" />
      <CommonButton
        size="large"
        type="primary"
        startIcon={<img src={PasswordIcon} />}
        onClick={() => handlePageChange("step1")}>
        {t('register.email_register')}
      </CommonButton>
      {/* <Space size="20px" />
      <AppleSignin handlePageChange={handlePageChange} /> */}
      <Space size="20px" />
      <FacebookSignin handlePageChange={handlePageChange} />
      <Space size="20px" />
      <GoogleSignin handlePageChange={handlePageChange} />
      <Space size="20px" />
      <RegisterTermsText fontSize="16px" lingHeight="20px">
        <Trans
          t={t}
          i18nKey="register.miro_register_terms"
          components={[
            <Link style={{ color: '#FF5C00', fontWeight: 600, whiteSpace: 'nowrap', textDecoration: 'none' }} key={0} target="_blank" to="/privacy-terms" />
          ]}
        />
      </RegisterTermsText>
      <Space size="100px" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const RegisterTermsText = styled(CommonText)`
  max-width: 440px;
  padding: 0px 20px;
  @media ${breakpoint.mobile} {
    font-size: 14px;
  }
`
