import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import InfiniteTextLoop from "../../components/home/InfiniteTextLoop";
import colors from "../../utils/colors";
import CommonText from "../../components/common/typographies/CommonText";
import ImgSmile from "../../assets/home_smileSticker.svg";
import ImgCrown from "../../assets/home_crownLineSticker.svg";
import MobileImgSmile from "../../assets/home_smileMobileSticker.png";
import MobileImgCrown from "../../assets/home_crownLineMobileSticker.svg";
import ImgJoinMiroZh from "../../assets/home_joinMiroStickerZh.svg";
import ImgJoinMiroEn from "../../assets/home_joinMiroStickerEn.svg";
import MobileImgJoinMiroZh from "../../assets/home_joinMiroStickerMobileZh.svg";
import MobileImgJoinMiroEn from "../../assets/home_joinMiroStickerMobileEn.svg";
import CommonBottomBar from "../../components/common/navbar/CommonBottomBar";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import Stack from "@mui/material/Stack";
import ReactGA from "react-ga4";

const MemberPrice = () => {
  const { t, i18n } = useTranslation("home");

  const _renderMobile = () => {
    return (
      <StyledMainDiv>
        <StyledBarDiv>
          <GreenBar />
          <InfiniteTextLoop />
        </StyledBarDiv>
        <StyledMobileContent>
          <div style={{ height: 34 }}>
            {i18n.language === "zh" && (
              <CommonText fontSize="50px" mFontSize="24px" uppercase>
                更多福利有待發掘
              </CommonText>
            )}
          </div>
          <div>
            <CommonText
              fontSize="70px"
              mFontSize="40px"
              type="primary"
              uppercase
            >
              More benefits
            </CommonText>
          </div>
          <div>
            <CommonText
              fontSize="60px"
              mFontSize="38px"
              fontWeight="600"
              uppercase
            >
              to be EXPLORED
            </CommonText>
          </div>
          <StickerCrown src={MobileImgCrown} />
          <StickerSmile src={MobileImgSmile} />

          <Stack direction="row" justifyContent="flex-end">
            <Link
              to={`/join`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                ReactGA.event("be_miro", {
                  param: "bottom",
                });
              }}
            >
              <StyledStickerImg
                src={
                  i18n.language === "zh"
                    ? MobileImgJoinMiroZh
                    : MobileImgJoinMiroEn
                }
              />
            </Link>
          </Stack>

          {/* <Stack direction="row" justifyContent="center">
            <StyledPrice>
              <div>
                <CommonText fontSize="20px" fontWeight="600" uppercase>
                {i18n.language === "zh" ? "港幣$" : "HK$"}
                </CommonText>
              </div>

              <CommonText fontSize="80px">440</CommonText>
              <CommonText fontSize="20px" fontWeight="600" uppercase>
                {t("info.year")}
              </CommonText>
            </StyledPrice>
          </Stack> */}
        </StyledMobileContent>

        <CommonBottomBar theme="black" />
      </StyledMainDiv>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledMainDiv>
        <StyledBarDiv>
          <GreenBar />
          <InfiniteTextLoop />
        </StyledBarDiv>
        <StyledContent>
          <div style={{ height: 70 }}>
            {i18n.language === "zh" && (
              <StyledText largeFontSize="50px" fontSize="30px">
                更多福利有待發掘
              </StyledText>
            )}
          </div>
          <div style={{ marginLeft: 20 }}>
            <StyledText
              largeFontSize="70px"
              fontSize="50px"
              fontFamily="Philosopher"
            >
              More benefits
            </StyledText>
          </div>
          <div style={{ marginLeft: 100 }}>
            <StyledText largeFontSize="60px" fontSize="40px" fontWeight="600">
              to be EXPLORED
            </StyledText>
          </div>
          <StickerCrown src={ImgCrown} />
          <StickerSmile src={ImgSmile} />

          <StickerJoinMiro>
            <Link
              to={`/join`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                ReactGA.event("be_miro", {
                  param: "bottom",
                });
              }}
            >
              <StyledStickerImg
                src={i18n.language === "zh" ? ImgJoinMiroZh : ImgJoinMiroEn}
              />
            </Link>
          </StickerJoinMiro>
        </StyledContent>
        <CommonBottomBar theme="black" />
      </StyledMainDiv>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
};

const StyledMainDiv = styled.div`
  &:before {
    position: absolute;
    top: 0px;

    border-top: solid 50px ${colors.white};
    border-right: 100vw solid rgba(0, 0, 0, 0);
    content: "";

    @media ${breakpoint.desktop} {
      border-top: solid 200px ${colors.white};
    }
  }

  position: relative;
  background-color: #aee020;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  overflow: hidden;
  // @media ${breakpoint.largeDesktop} {
  //   height: 100vh;
  // }
`;

const StyledBarDiv = styled.div`
  position: absolute;
  top: 10px;
`;

const StyledMobileContent = styled.div`
  text-align: left;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 60px;
`;

const StyledContent = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${breakpoint.desktop} {
    width: auto;
    height: 85vh;
    margin-top: 100px;
    margin-left: -100px;
  }
`;

const StyledSpace = styled.div`
  @media ${breakpoint.largeDesktop} {
    padding-bottom: 50px;
  }
`;

const StyledText = styled.div`
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Open Sans"};
  font-size: ${(props) => props.fontSize};
  text-transform: uppercase;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};

  @media ${breakpoint.desktop} {
    font-size: ${(props) => props.largeFontSize};
  }
`;

const StyledPrice = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${breakpoint.desktop} {
    margin-left: 200px;
  }
`;

const GreenBar = styled.div`
  background: #0d5b57;
  width: 100%;
  height: 30px;
  transform: rotate(2deg) scale(1.1);

  position: absolute;
  top: 55%;

  @media ${breakpoint.desktop} {
    height: 70px;
    position: relative;
  }
`;

const StickerCrown = styled.img`
  user-drag: none;
  position: absolute;
  top: -5%;
  right: -10%;

  @media ${breakpoint.desktop} {
    top: 25%;
    right: -30%;
  }
`;

const StickerSmile = styled.img`
  user-drag: none;
  position: absolute;
  top: 35%;
  left: 0px;

  @media ${breakpoint.desktop} {
    top: 60%;
    left: 10px;
  }
`;

const StickerJoinMiro = styled.div`
  user-drag: none;
  cursor: pointer;
  position: absolute;
  bottom: 100;
  right: 0;

  @media ${breakpoint.desktop} {
    transform: translate(70%, 70%);
  }

  @media ${breakpoint.largeDesktop} {
    transform: translate(100%, 70%);
  }
`;

const StyledStickerImg = styled.img`
  user-drag: none;
`;

export default MemberPrice;
