import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import colors from "../../../utils/colors";
import { breakpoint } from "../../../utils/responsive";

// default: small outlined white and black button
// options:
// type: 'primary'
// size: 'large'
// Sample Usage: <CommonButton size={"large"} type={"primary"}>明白</CommonButton>

function CommonButton(props) {
  let { type, size, fontSize, children, whiteTheme, hoverBgColor, hoverFontColor } = props;

  // Grouping button style
  let background_color = "white";
  let font_color = "#111111";
  let border_color = "#B3B3B3";
  let hover_bgcolor = "";
  let hover_fontColor = "";

  switch (type) {
    case "primary": {
      background_color = "#FF5C00";
      border_color = "#FF5C00";
      hover_bgcolor = hoverBgColor ?? ""
      font_color = "white";
      break;
    }
    case "outlined": {
      background_color = "transparent";
      border_color = whiteTheme ? colors.white60Alpha : "rgb(17, 17, 17,0.3)";
      font_color = whiteTheme ? colors.white : font_color;
      hover_bgcolor = "white";
      hover_fontColor = hoverFontColor ?? font_color;
      break;
    }
    case "filter": {
      background_color = "transparent";
      border_color = "#F2B164";
      hover_bgcolor = "transparent";
      font_color = "white";
      break;
    }
    case "none": {
      background_color = "none";
      border_color = "none";
      hover_bgcolor = "rgb(211,211,211,0.5)";
      break;
    }
    case "grey": {
      background_color = "rgb(211,211,211,0.5)";
      border_color = "none";
      hover_bgcolor = "rgb(211,211,211)";
      font_color = props.fontColor;
      break;
    }
    default: {
      background_color = "white";
      font_color = "#111111";
      border_color = "#B3B3B3";
    }
  }

  return (
    <StyledButton
      {...props}
      type={type}
      size={size}
      background_color={background_color}
      font_color={font_color}
      border_color={border_color}
      hover_bgcolor={hover_bgcolor}
      hover_fontColor={hover_fontColor}
    >
      <StyledTypography fontSize={fontSize}>{children}</StyledTypography>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  && {
    background: ${(props) => props.background_color};
    color: ${(props) => props.font_color};
    border: 1px solid ${(props) => props.border_color};
    border-radius: 50px;
    height: ${(props) => props.height || "68px"};
    width: ${(props) =>
    props.width || (props.size == "large" ? "440px" : "267px")};
    max-width: 440px;
    text-transform: ${(props) =>
    props.normalCase ? "capitalize" : "uppercase"};
    &.MuiButton-root.Mui-disabled {
      background: ${(props) => props.background_color};
      color: ${(props) => props.font_color};
      filter: opacity(35%);
    }
    &:hover {
      background: ${(props) =>
    props.hover_bgcolor ? props.hover_bgcolor : props.background_color};
      color: ${(props) =>
    props.hover_fontColor ? props.hover_fontColor : props.font_color};
    }
    @media ${breakpoint.mobile} {
      max-width: ${(props) =>
    props.width || (props.size == "large" ? "320px" : "152px")};
      width: 100%;
      border-radius: 37px;
      height: 52px;
    }
    @media ${breakpoint.desktop} {
      border-radius: 37px;
      height: 60px;
    }
    @media ${breakpoint.largeDesktop} {
      border-radius: 37px;
      height: 68px;
    }
  }
`;

const StyledTypography = styled.div`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  line-height: 25px;
  text-align: center;
  @media ${breakpoint.mobile} {
    font-size: 14px;
  }
  @media ${breakpoint.desktop} {
    font-size: 16px;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 18px;
  }
`;

export default CommonButton;
