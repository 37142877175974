import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors.js";
import IconNumstarOne from "../../assets/home_numstarOneIcon.svg";
import IconNumstarTwo from "../../assets/home_numstarTwoIcon.svg";
import IconNumstarThree from "../../assets/home_numstarThreeIcon.svg";
import IconTriangle from "../../assets/home_triangleIcon.svg";
import IconTriangleOutlined from "../../assets/home_triangleOutlinedIcon.svg";
import IconSquare from "../../assets/home_squareIcon.svg";
import IconSquareOutlined from "../../assets/home_squareOutlinedIcon.svg";
import IconCircle from "../../assets/home_circleIcon.svg";
import IconCircleOutlined from "../../assets/home_circleOutlinedIcon.svg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const numStar = (step) => {
  switch (step) {
    case "1":
      return IconNumstarOne;
    case "2":
      return IconNumstarTwo;
    case "3":
      return IconNumstarThree;
    default:
      return null;
  }
};

export const StepIcon = ({ step }) => {
  const handleClick = (gotoStep) => {
    document.getElementById(`memProStep${gotoStep}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  switch (step) {
    case "1":
      return (
        <StyledIconDiv>
          <StyledIcon src={IconTriangle} onClick={() => handleClick("1")} />
          <StyledIcon
            src={IconSquareOutlined}
            onClick={() => handleClick("2")}
          />
          <StyledIcon
            src={IconCircleOutlined}
            onClick={() => handleClick("3")}
          />
        </StyledIconDiv>
      );
    case "2":
      return (
        <StyledIconDiv onClick={handleClick}>
          <StyledIcon
            src={IconTriangleOutlined}
            onClick={() => handleClick("1")}
          />
          <StyledIcon src={IconSquare} onClick={() => handleClick("2")} />
          <StyledIcon
            src={IconCircleOutlined}
            onClick={() => handleClick("3")}
          />
        </StyledIconDiv>
      );
    case "3":
      return (
        <StyledIconDiv onClick={handleClick}>
          <StyledIcon
            src={IconTriangleOutlined}
            onClick={() => handleClick("1")}
          />
          <StyledIcon
            src={IconSquareOutlined}
            onClick={() => handleClick("2")}
          />
          <StyledIcon src={IconCircle} onClick={() => handleClick("3")} />
        </StyledIconDiv>
      );
    default:
      return null;
  }
};

const MemberProsBase = ({ step, chText, enText, imgComponent }) => {
  const { i18n } = useTranslation("home");

  const _renderMobile = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh" }}
        id={`memProStep${step}`}
      >
        <StyledMobileMainDiv>
          {i18n.language === "zh" && (
            <Stack direction="row">
              <StyledStar src={numStar(step)} />
              <div style={{ marginTop: 10, position: "relative" }}>
                <CommonText fontSize="17px" uppercase>
                  {chText}
                </CommonText>
              </div>
            </Stack>
          )}
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ height: "100%", width: "100%", position: "relative" }}
          >
            {i18n.language === "en" && <StyledStar src={numStar(step)} />}
            <CommonText
              fontSize="25px"
              fontWeight="600"
              uppercase
              textAlign="left"
            >
              {enText}
            </CommonText>
          </Stack>
          <div style={{ marginTop: 30, width: "100%" }}>{imgComponent}</div>
        </StyledMobileMainDiv>
      </Stack>
    );
  };

  const _renderDesktop = () => {
    return (
      <div style={{ height: "100vh" }} id={`memProStep${step}`}>
        <StyledDiv>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            style={{ height: "auto" }}
          >
            <StyledStar src={numStar(step)} />
          </Stack>

          <StyledContent style={{ marginTop: i18n.language === "zh" ? 15 : 0 }}>
            {i18n.language === "zh" && (
              <CommonText fontSize="30px" uppercase>
                {chText}
              </CommonText>
            )}
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ height: "100%" }}
            >
              <CommonText
                fontSize="30px"
                largeFontSize="3.125vw"
                fontWeight="600"
                uppercase
              >
                {enText}
              </CommonText>
            </Stack>

            <div style={{ marginTop: 30 }}>{imgComponent}</div>
          </StyledContent>
        </StyledDiv>
      </div>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderMobile()}
      desktop={_renderDesktop()}
      mobileOffset={200}
    />
  );
};

const StyledMobileMainDiv = styled.div`
  // background-color: ${colors.white};
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
`;

const StyledStar = styled.img`
  margin-right: 20px;
  user-drag: none;

  @media ${breakpoint.mobile} {
    width: 15%;
    min-width: 40px;
  }
`;

const StyledIcon = styled.img`
  margin-top: 20px;
  height: 10px;
  user-drag: none;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

const StyledIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  @media ${breakpoint.mobile} {
    margin-top: 10px;
  }
`;

const StyledDiv = styled.div`
  // background-color: ${colors.white};
  width: 100%;
  height: 100vh;
  padding: 4% 10%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const StyledContent = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
`;

export default MemberProsBase;
