import { Grid } from "@mui/material"
import Select from "@mui/material/Select"
import styled from "styled-components"
import Members from "../../../utils/performers.json"
import CircleImage from "../images/CircleImage"

function MirrorSelectGrid(props) {
  const { handleMemberClick, isMemberSelected, isMobile } = props

  return (
    <Grid
      container
      width={isMobile ? "100%" : 438}
      spacing={0}
      justifyContent="center"
      alignItems="center"
    >
      {Members.map((item) => (
        <Grid
          item
          container
          style={{ marginTop: 9 }}
          xs={3}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircleImage
            width={isMobile ? '75px' : '95px'}
            height={isMobile ? '75px' : '95px'}
            onClick={() => handleMemberClick(item)}
            selected={isMemberSelected(item)}
            src={item.image}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const StyledSelect = styled(Select)`
  height: 54px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  background: white;
`

export default MirrorSelectGrid
