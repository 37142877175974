import { createContext, useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import CommonText from "../components/common/typographies/CommonText";
import Hero from "../pageComponents/join2024/Hero";
import JoinNavBar from "../pageComponents/join2024/JoinNavbar";
import MembershipBenefits from "../pageComponents/join2024/MembershipBenefits";
import Vote from "../pageComponents/join2024/Vote";
import { ReactComponent as JoinMiroStroke } from "../assets/join_miro_stroke.svg";
import RenewalMember from "../pageComponents/join2024/RenewalMemeber";
import FooterCalendar from "../assets/join_footer_calendar.png";
import { useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";

export const AnimationContext = createContext();
const AnimationProvider = (props) => {
  // should be a hook instead of context
  const EACH_DELAY = 500;
  const pages = {
    HERO: "HERO",
    VOTE: "VOTE",
    PARALLAX: "PARALLAX",
  };
  const fadeInSequence = {
    [pages.HERO]: 0,
    [pages.VOTE]: 1,
    [pages.PARALLAX]: 2,
  };

  const getFadeInTime = (page) => {
    return fadeInSequence[page] * EACH_DELAY;
  };

  const value = {
    /*  animationSeq,
     setAnimationSeq, */
    pages,
    getFadeInTime,
  };

  return (
    <AnimationContext.Provider value={value}>
      {props.children}
    </AnimationContext.Provider>
  );
};

export default function Join2024() {
  const { session, setSession, disableMiroReg } = useContext(AppContext);
  const [scrollEl, setScrollElement] = useState(null);
  const scrollParallaxRef = useRef();

  const navigate = useNavigate();
  const isMiro = session?.user?.memberType === "MIRO";

  useEffect(() => {
    if (disableMiroReg) navigate("/miro-register");
    if (isMiro && session?.user?.renewPrice < 0) navigate("/");

    setScrollElement(scrollParallaxRef.current);
  }, []);
  return (
    <ParallaxScrollContainer ref={scrollParallaxRef} >
      <ParallaxProvider scrollAxis="vertical" scrollContainer={scrollEl}>
        <AnimationProvider>
          <Container >
            <JoinMiroStroke style={{ position: 'absolute', width: '80%', left: '50%', top: -20, transform: 'translate(-50%, -50px)' }} />
            <JoinNavBar />
            <StyledWithParallaxIcons>
              <Hero />
              <Vote />
              <MembershipBenefits />
            </StyledWithParallaxIcons>
            <RenewalMember />
            <img src={FooterCalendar} alt="bottom left calendar" style={{ width: 350, height: 380, position: 'relative', right: 110, top: 80 }} />
          </Container>
        </AnimationProvider>
      </ParallaxProvider>
    </ParallaxScrollContainer>



  );
}

const ParallaxScrollContainer = styled.div`
   width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
`

const Container = styled.div`
  background-color: #ffaf0f;
  //background-color: green; //dev only, hard to use the inspector for orange background
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const StyledWithParallaxIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
