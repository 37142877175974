import React from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import CommonText from '../typographies/CommonText';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CommonLabel from '../labels/CommonLabel';
import CONSTANTS from '../../../constants';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

function UpgradeButton(props) {
  const { t } = useTranslation('profile');

  return (
    <StyledDiv>
      <StyledLeftDiv>
        <CommonText fontWeight="600" fontSize="18px">
          {t('profile:upgrade.become_miro_text')}
        </CommonText>
        {/* <CommonLabel>
          {t("profile:upgrade.price", {
            price: CONSTANTS.MEMBER_PRICE_PER_YEAR,
          })}
        </CommonLabel> */}
      </StyledLeftDiv>

      <StyledRightDiv>
        <StyledUpgradeButton
          variant="contained"
          disableElevation
          onClick={() => {}}
        >
          <Link
            to="/join"
            onClick={() => {
              ReactGA.event('button_click', {
                button_text: 'Membership page_Join now button',
              });
            }}
            style={{ textDecoration: 'none' }}
          >
            <CommonText
              color={colors.white}
              fontSize={'17px'}
              fontWeight={600}
              lineHeight={'25px'}
              style={{whiteSpace: 'nowrap'}}
            >
              {t('profile:upgrade.upgrade_button')}
            </CommonText>
          </Link>
        </StyledUpgradeButton>
      </StyledRightDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  padding: 25px;
  border: 1px solid #ff5c00;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const StyledRightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
`;

const StyledUpgradeButton = styled(Button)`
  && {
    background: #ff5c00;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 120px;
    color: ${colors.white};
  }
`;

export default UpgradeButton;
