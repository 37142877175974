import { socialRegister, appleRegister, updateTmpUser } from "../../apis";
import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import FormTextField from "../../components/common/textfields/FormTextField";
import { useState, useRef, useContext } from "react";
import CommonText from "../../components/common/typographies/CommonText";
import { Trans, useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import { AppContext } from "../../AppContext";
import constants from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import EmailTextfield from "../../components/common/textfields/EmailTextField";
import { ResponsiveUI } from "../../utils/responsive";
import DownArrowIcon from "../../assets/down_arrow.svg";
import { Grid } from "@mui/material";
import CommonLabel from "../../components/common/labels/CommonLabel";

export default function SocialStep1({ handlePageChange, type, profile }) {
  console.log("profile", profile);
  const { t } = useTranslation(["common", "profile", "signin"]);
  const [loading, setLoading] = useState(false);
  const [showContactEmail, setShowContactEmail] = useState(false);
  const [error, setError] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const { session, setSession } = useContext(AppContext);
  // const recaptchaRef = useRef(null);
  const emailRef = useRef();
  const emailOTPRef = useRef();
  const usernameRef = useRef();
  const user = session?.user;
  const onReCaptchaChange = (value) => {
    setRecaptcha(value);
  };
  const validate = () => {
    let error = {};
    if (
      usernameRef.current.value.length < 2 ||
      usernameRef.current.value.length > 50
    )
      error.username = t("signin:register.invalid_username");
    if (showContactEmail) {
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(emailRef.current.value))
        error.email = t("signin:register.invalid_email");
      if (emailOTPRef.current.value.length != 6)
        error.emailOTP = t("signin:register.invalid_email_otp");
    }
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleRegister = async () => {
    console.log("loading", loading, validate());
    if (!loading && validate()) {
      setLoading(true);
      if (user) {
        // update existing tmp user
        const body = {
          username: usernameRef.current.value,
        };
        if (showContactEmail) {
          body.contactEmail = emailRef.current.value;
          body.contactEmailOTP = emailOTPRef.current.value;
        }
        console.log(body);
        const result = await updateTmpUser(session.accessToken, body);
        console.log(result);
        if (result.success) {
          const newSession = {
            ...session,
            user: result.data,
          };
          localStorage.setItem("session", JSON.stringify(newSession));
          setSession(newSession);
          handlePageChange("step2");
        } else {
          // set api error
        }
      } else {
        if (type == "apple") {
          const result = await appleRegister(
            profile.code,
            profile.lastName,
            profile.firstName,
            "TMP",
            usernameRef.current.value,
            null,
            null,
            null,
            showContactEmail ? emailRef.current.value : null,
            showContactEmail ? emailOTPRef.current.value : null
          );
          console.log(result);
          if (result.success) {
            localStorage.setItem("session", JSON.stringify(result.data));
            setSession(result.data);
            handlePageChange("step2");
          } else {
            // set api error
          }
          // recaptchaRef.current.reset();
        } else if (type == "facebook" || type == "google") {
          const result = await socialRegister(
            type,
            profile[type + ".accessToken"],
            "TMP",
            usernameRef.current.value,
            null,
            null,
            null,
            showContactEmail ? emailRef.current.value : null,
            showContactEmail ? emailOTPRef.current.value : null
          );
          console.log(result);
          if (result.success) {
            localStorage.setItem("session", JSON.stringify(result.data));
            setSession(result.data);
            handlePageChange("step2");
          } else {
            // set api error
          }
          // recaptchaRef.current.reset();
        }
      }
      setLoading(false);
    }
  };
  return (
    <Container>
      <Space size="100px" />
      <CommonText lineHeight="140%">
        {t("signin:register.step") + "1/6"}
      </CommonText>
      <Space size="100px" />
      <FormTextField
        defaultValue={user?.username}
        inputRef={usernameRef}
        errorText={error?.username}
        label={t("signin:register.username")}
      />
      <Space size="20px" />
      <CommonLabel style={{ width: "100%", textAlign: "left" }}>
        {t("signin:register.email")}
      </CommonLabel>
      <ResponsiveUI
        desktop={
          <Grid container justifyContent="space-between">
            <CommonLabel style={{ textAlign: "left" }}>
              {profile?.email || user?.email}
            </CommonLabel>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={() => setShowContactEmail(!showContactEmail)}
            >
              <CommonLabel style={{ cursor: "pointer" }}>
                {t("signin:register.add_contact_email")}
              </CommonLabel>
              {!showContactEmail ? (
                <img
                  style={{ width: 20, transform: "rotate(0)" }}
                  src={DownArrowIcon}
                />
              ) : (
                <img
                  style={{ width: 20, transform: "rotate(180deg)" }}
                  src={DownArrowIcon}
                />
              )}
            </div>
          </Grid>
        }
        mobile={
          <Grid container flexDirection="column" justifyContent="flex-start">
            <CommonLabel style={{ textAlign: "left" }}>
              {profile?.email || user?.email}
            </CommonLabel>
            <Space size="10px" />
            <Grid
              container
              justifyContent="flex-start"
              onClick={() => setShowContactEmail(!showContactEmail)}
            >
              <CommonLabel style={{ cursor: "pointer" }}>
                {t("signin:register.add_contact_email")}
              </CommonLabel>
              {!showContactEmail ? (
                <img
                  style={{ width: 20, transform: "rotate(0)" }}
                  src={DownArrowIcon}
                />
              ) : (
                <img
                  style={{ width: 20, transform: "rotate(180deg)" }}
                  src={DownArrowIcon}
                />
              )}
            </Grid>
          </Grid>
        }
        mobileOffset={300}
      />
      {showContactEmail && (
        <>
          <Space size="20px" />
          <EmailTextfield
            value={user?.contactEmail}
            contactHint={true}
            errorText={error?.email}
            inputRef={emailRef}
            label={t("signin:register.contact_email_optional")}
            purpose="contact"
            requestOTP
          />
          <Space size="20px" />
          <FormTextField
            inputRef={emailOTPRef}
            errorText={error?.emailOTP}
            label={t("signin:register.email_otp")}
          />
        </>
      )}
      <Space size="20px" />
      {/* {
                !user && <ReCAPTCHA
                    sitekey={constants.RECAPTCHA}
                    onChange={onReCaptchaChange}
                    ref={recaptchaRef}
                />
            } */}
      <Space size="147px" />
      <CommonButton type={"primary"} onClick={handleRegister}>
        {t("signin:register.next")}
      </CommonButton>
      <Space size="100px" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
