import styled from "styled-components";
import Space from "../../components/common/Space";
import CommonText from "../../components/common/typographies/CommonText";
import { Trans, useTranslation } from "react-i18next";
import MemberCard from "../memberProfile/MemberCard";
import { Divider } from "@mui/material";
import CheckBox from "../../components/common/checkboxs/Checkbox";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import moment from "moment";
import CommonButton from "../../components/common/buttons/CommonButton";
import { updateUser } from "../../apis";
import { breakpoint, WindowSize } from "../../utils/responsive";
import constants from "../../constants";

const Step1 = ({ handlePageChange, data }) => {
  const { t, i18n } = useTranslation("signin");
  const isMobile = WindowSize() == "mobile";
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState({
    tnc1: false,
    promotion: false,
    promotion_other: false,
  });

  const handleNext = async () => {
    if (!loading) {
      setLoading(true);
      const body = {
        promotion: agree["promotion"],
        promotion_other: agree["promotion_other"],
      };
      console.log(body);
      let result = await updateUser(session.accessToken, body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem("session", JSON.stringify(newSession));
        setSession(newSession);
        handlePageChange("step2");
      }
      setLoading(false);
    }
  };

  let effectiveStart = moment(constants.MEMBERSHIP_START_DAY);
  let effectiveEnd = moment(constants.MEMBERSHIP_EXPIRY_DAY);

  return (
    <Container>
      <CommonText lineHeight="140%">
        {t("signin:register.step") + "1/3"}
      </CommonText>
      <Space size="20px" />
      <CommonText fontSize="20px" mFontSize="16px">{t("renew.membership_renewal")}</CommonText>
      <Space size="20px" />
      <MemberCardContainer>
        <MemberCard
          name={`${data?.user?.firstName} ${data?.user?.lastName}`}
          id={data?.user?.memberNo}
        />
      </MemberCardContainer>
      <Content>
        <MembershipInfo>
          <div>
            <CommonText fontSize="18px" mFontSize="16px" fontWeight="600">
              2024 {t("renew.miro_membership_renewal")}
            </CommonText>
            <CommonText fontSize="16px">
              {t("renew.effective_date", {
                start: effectiveStart
                  .locale("en")
                  .format(t("renew.date_format")),
                end: effectiveEnd.locale("en").format(t("renew.date_format")),
              })}
            </CommonText>
          </div>
          <MemberPrice>
            <CommonText
              fontSize="16px"
              mFontSize="14px"
              fontWeight="600"
              color="#fff"
              style={{ textTransform: "uppercase" }}
            >
              {data?.user?.renewPrice == 0
                ? t("landing.free")
                : `${t("landing.hkd")}${data?.user?.renewPrice}`}
            </CommonText>
          </MemberPrice>
        </MembershipInfo>
        <Divider style={{ margin: "30px 0px" }} />
        <CommonText fontSize="14px" color="#515151">
          {t("renew.english_name")}
        </CommonText>
        <CommonText
          fontSize="18px"
          mFontSize="16px"
        >{`${data?.user?.firstName} ${data?.user?.lastName}`}</CommonText>
        <Space size="20px" />
        <CommonText fontSize="14px" color="#515151">
          {t("renew.membership_number")}
        </CommonText>
        <CommonText fontSize="18px" mFontSize="16px">
          {data?.user?.memberNo}
        </CommonText>
        <Space size="20px" />
        <CommonText fontSize="14px" color="#515151">
          {t("renew.email")}
        </CommonText>
        <CommonText fontSize="18px" mFontSize="16px">
          {data?.user?.email}
        </CommonText>
        <Divider style={{ margin: "30px 0px" }} />
        <CheckBox
          onClick={() => setAgree({ ...agree, tnc1: !agree["tnc1"] })}
          isChecked={agree["tnc1"]}
          mFontSize="14px"
        >
          <Trans
            t={t}
            i18nKey="renew.tnc1"
            components={[
              <Link
                style={{
                  color: "#FF5C00",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={0}
                target="_blank"
                to="/tnc?id=web"
              />,
              <Link
                style={{
                  color: "#FF5C00",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={1}
                target="_blank"
                to="/tnc?id=membership"
              />,
              <Link
                style={{
                  color: "#FF5C00",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={2}
                target="_blank"
                to="/privacy-terms"
              />,
              <a
                style={{
                  color: "#FF5C00",
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={3}
                target="_blank"
                href={`http://www.pccw.com/privacy-statement/index.page?locale=${i18n.language}`}
              />,
            ]}
          />
        </CheckBox>
        <Space size="10px" />
        <CheckBox
          onClick={() => setAgree({ ...agree, promotion: !agree["promotion"] })}
          isChecked={agree["promotion"]}
          mFontSize="14px"
        >
          {t("renew.tnc2")}
        </CheckBox>
        <Space size="10px" />
        <CheckBox
          onClick={() =>
            setAgree({ ...agree, promotion_other: !agree["promotion_other"] })
          }
          isChecked={agree["promotion_other"]}
          mFontSize="14px"
        >
          {t("renew.tnc3")}
        </CheckBox>
      </Content>

      <Space size="50px" />
      <CommonButton
        disabled={!agree["tnc1"]}
        type={"primary"}
        style={{ width: isMobile ? "100%" : "50%" }}
        onClick={() => handleNext()}
      >
        {t("register.next")}
      </CommonButton>
    </Container>
  );
};

export default Step1;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;

  @media ${breakpoint.desktop} {
    max-width: 632px;
  }
`;

const MemberCardContainer = styled.div`
  z-index: 999;
  width: 100%;
  max-width: 340px;
  @media ${breakpoint.largeDesktop} {
    max-width: 438px;
  }
  @media ${breakpoint.mobile} {
    max-width: 280px;
  }
`
const Content = styled.div`
  width: 100%;
  height: auto;

  background: #ffffff;
  border: 1px solid #e3e3e3;
  text-align: left;
  margin-top: -30px;

  @media ${breakpoint.desktop} {
    padding: 64px 40px 40px;
  }

  @media ${breakpoint.mobile} {
    padding: 64px 15px 20px;
  }
`;

const MembershipInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const MemberPrice = styled.div`
  width: auto;
  background-color: #111;
  border-radius: 5px;
  height: 30px;
  padding: 4px 20px;
  white-space: nowrap;
`;
