import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Space from "../../components/common/Space";

export default function MembershipTnc() {
  const { i18n } = useTranslation("");

  return (
    <div>
      {i18n.language === "en" ? (
        // ----------------------ENGLISH VERSION----------------------
        <div style={{ textAlign: "justify" }}>
          <StyledTitle>
            MIRROR OFFICIAL FAN CLUB Membership Terms and Conditions{" "}
          </StyledTitle>

          <StyledSubTitle>About Us</StyledSubTitle>

          <StyledDiv>
            <StyledP>
              MIRROR Official Fan Club, established in 2022, is the only
              official fan club of MIRROR. Our foremost objective is to build
              and maintain an all-in-one digital platform for MIRROR fans to
              connect and support MIRROR’s work.
            </StyledP>
            <StyledP>
              As an homage of MIRROR’s loyal fans who are the true heroes behind
              their success, we proudly refer to members of the MIRROR Official
              Fan Club as “MIROs”.
            </StyledP>
          </StyledDiv>

          <StyledDiv>
            <StyledP>Important Notice</StyledP>
            <ol type="a" style={{ paddingLeft: 65 }}>
              <li style={{ marginBottom: 10 }}>
                Please read the following terms and conditions (“
                <Bold>Membership Terms</Bold>”) carefully. By applying to become
                a MIRO member, you (“<Bold>MIRO Member</Bold>", “
                <Bold>you</Bold>” and “<Bold>your</Bold>” shall be construed
                accordingly) agree to be bound by the Membership Terms as may be
                amended from time to time and the Website Terms and Conditions
                (available for viewing at{" "}
                <a href="https://www.mirrorweare.com">
                  https://www.mirrorweare.com
                </a>
                ; (“<Bold>Our Site</Bold>”)), which together shall constitute
                our agreement with you (“<Bold>Agreement</Bold>”).
              </li>
              <li style={{ marginBottom: 10 }}>
                MIRROR Official Fan Club is operated by MakerVille Company
                Limited (“<Bold>MIRROR Official Fan Club</Bold>", “
                <Bold>we</Bold>”, “<Bold>us</Bold>” and “<Bold>our</Bold>” shall
                be construed accordingly), a member of the PCCW Media Group.
              </li>
              <li style={{ marginBottom: 10 }}>
                We reserve the right to update the Membership Terms from time to
                time. It is your responsibility to check for updates to the
                Membership Terms, which will take effect upon posting on Our
                Site. You, as a MIRO Member, agree to be bound by such
                amendments whether or not perused by you except that, if
                required by applicable law, we will notify and/or obtain your
                agreement for the amendments.
              </li>
            </ol>
          </StyledDiv>

          <StyledDiv>
            <StyledSubTitle>Eligibility for MIRO Membership </StyledSubTitle>

            <StyledOrderList type="1">
              <StyledListItem>
                Applicants aged 11 or above are eligible to apply for a MIRO
                membership.{" "}
              </StyledListItem>
              <StyledListItem>
                If the applicant is under 18 years old, we require their parent
                or legal guardian to complete and submit an online consent form
                as part of the application process.
              </StyledListItem>
              <StyledSubTitle>Applying for MIRO Membership</StyledSubTitle>
              <StyledListItem>
                To become a MIRO Member, you must submit your application online
                and pay the applicable membership fee (as adjusted from time to
                time) (“Membership Fee”) directly on Our Site.
              </StyledListItem>

              <StyledListItem>
                A MIRO membership comes with various exclusive benefits and
                privileges (see further at “Membership Benefits” section below).
                As part of our efforts to ensure that each of our valued MIRO
                members has a fair opportunity to enjoy such benefits and
                privileges, we collect personal information to enable us to
                verify that each person only applies for a single MIRO
                membership (although we do not warrant that our methods
                guarantee this result). As such, your application for MIRO
                membership must include the following personal information:
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    your full legal name (as stated in your HKID card or
                    passport);
                  </StyledListItem>
                  <StyledListItem>your date of birth;</StyledListItem>
                  <StyledListItem>your email address; </StyledListItem>
                  <StyledListItem>
                    your Hong Kong contact number; and{" "}
                  </StyledListItem>
                  <StyledListItem>
                    your Hong Kong postal address.{" "}
                  </StyledListItem>
                </StyledOrderList>
                <StyledP>
                  For information about our policies and practices as to how we
                  handle your personal data, please refer to the{" "}
                  <a href="/privacy-terms">
                    Personal Information Collection Statement
                  </a>
                  .{" "}
                </StyledP>
              </StyledListItem>

              <StyledListItem>
                For the purpose of email verification, our system will send a
                verification code to the email address provided by you. It is
                your responsibility to ensure that the email address you have
                provided is correct, as we cannot be held responsible for any
                undelivered emails.
              </StyledListItem>
              <StyledListItem>
                If the applicant is under 18 years old, we require their parent
                or legal guardian to complete and submit an online consent form
                as part of the application process.
              </StyledListItem>
              <StyledListItem>
                Upon your successful payment of the Membership Fee, we will send
                you an email to confirm your registration as a MIRO Member.
              </StyledListItem>

              <StyledSubTitle>
                Validity and Renewal of MIRO membership
              </StyledSubTitle>

              <StyledListItem>
                Applications for new membership and membership renewal will only be accepted during the designated period each year, which would take effect from 1 January, and remain valid until 31 December of that same year.
              </StyledListItem>
              
              <StyledSubTitle>Membership Benefits</StyledSubTitle>

              <StyledListItem>
                <StyledP>
                  All MIRO Members will have the opportunity to enjoy the following MIRO benefits:
                </StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    A MIRO Gift Pack (as may be updated from time to
                    time){" "}
                  </StyledListItem>
                  <StyledListItem>
                    Ticket reservation for designated events*^
                  </StyledListItem>
                  <StyledListItem>
                    Participation in MIRO special events*^{" "}
                  </StyledListItem>
                  <StyledListItem>
                    Participation in {"<<"}Hi MIRO! SEE U ZOOM Online Fan Meeting{">>"}
                  </StyledListItem>
                  <StyledListItem>
                    Access to purchase limited edition merchandise of MIRROR*{" "}
                  </StyledListItem>
                  <StyledListItem>
                    Access and download MIRROR exclusive media content in our MIRO dedicated zone{" "}
                  </StyledListItem>
                  <StyledFootnote>
                    *Subject to availability, quantity allocated by event organiser and/or confirmation of event
                  </StyledFootnote>
                  <StyledFootnote>
                    ^Event may be subject to age restriction
                  </StyledFootnote>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                The quantity of event tickets (collectively “<Bold>Tickets</Bold>”) available for
                purchase by MIRO members via the MIRROR Official Fan Club is
                limited, as allocated to us by the applicable organizer. We
                cannot guarantee the availability or quantity of Tickets that
                will be available for purchase by individual MIRO members. The
                mechanism for allocating Tickets to MIRO members for purchase
                will be determined by us, as we see fit and appropriate.
              </StyledListItem>

              <StyledListItem>
                Membership benefits may include products or services supplied by
                third parties ("<Bold>Third Party Providers</Bold>"). You agree
                to comply with the applicable terms and conditions specified by
                us and/or the relevant Third Party Providers; and you agree that
                we are not responsible or liable for
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    the act, negligence or omission of Third Party Providers;
                  </StyledListItem>
                  <StyledListItem>
                    any injuries and/or loss arising from your usage of (or
                    inability to use) the products or services supplied by Third
                    Party Providers; and
                  </StyledListItem>
                  <StyledListItem>
                    any transaction or dispute between you and Third Party
                    Providers.
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledSubTitle>MIRO Member Gift Pack</StyledSubTitle>
              <StyledListItem>
                Our goal is to deliver the MIRO Member Gift Pack by using local
                delivery or local pickup options to MIRO Members in Hong Kong
                within approximately 3 months after the closing of registration
                or renewal. Please note, however, that we cannot guarantee this
                timeline.
              </StyledListItem>
              <StyledListItem>
                Please be aware that the actual shipping date of the MIRO Member
                Gift Pack may vary depending on the selected shipping method,
                product availability, weather conditions, traffic and other
                factors. The estimated shipping date of the MIRO Member Gift
                Pack will be adjusted based on these factors. Please keep an eye
                out for updates via our email notification or announcement in
                social media.
              </StyledListItem>
              <StyledListItem>
                Please note that we only offer delivery services to delivery
                addresses located within Hong Kong. Deliveries to remote areas
                will incur an additional surcharge, payable before delivery.
              </StyledListItem>
              <StyledListItem>
                All matters relating to the delivery of items (including but not
                limited to delivery surcharges) are subject to the terms and
                conditions of our delivery service provider. It is the
                responsibility of MIRO Members to provide accurate and complete
                information (included but not limited to delivery address and
                contact number). We shall not be held liable for any losses or
                damages resulting from any delays or failures in delivery.
              </StyledListItem>

              <StyledSubTitle>MIRO Member Exclusive Events</StyledSubTitle>
              <StyledListItem>
                We will organise member exclusive gatherings and fan meeting
                events from time to time (“<Bold>Member Events</Bold>”). Please
                note that certain Member Events may be subject to age
                restrictions.
              </StyledListItem>
              <StyledListItem>
                All attendees of Member Events must respect and comply with the
                rules and regulations set by us at all times.{" "}
              </StyledListItem>
              <StyledListItem>
                For Member Events that have age restrictions, we may request
                attendees under the age of 18 to present an original signed
                Parent/Guardian Authorization Declaration Form (“
                <Bold>Guardian Authorization Form</Bold>”) to our staff on the
                day of the event. Failure to present the original signed
                Guardian Authorization Form may result in denial of access to
                the event without any compensation or refund.
              </StyledListItem>
              <StyledListItem>
                We, along with the venue provider and any other organizer(s)
                involved, reserve the right to change, postpone or cancel any
                Member Event. In the event of any disputes, the decisions made
                by us, the venue provider and/or other organizer(s) of the
                Member Event shall be final and conclusive.
              </StyledListItem>

              <StyledSubTitle>MIRO Member Responsibilities</StyledSubTitle>
              <StyledListItem>
                <StyledP>As a MIRO Member, you:</StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    shall apply for only one MIRO membership;
                  </StyledListItem>
                  <StyledListItem>
                    shall not transfer or lend out your MIRO membership to
                    another person;
                  </StyledListItem>
                  <StyledListItem>
                    shall be solely responsible for safekeeping of your MIRO
                    login details and password;
                  </StyledListItem>

                  <StyledListItem>
                    shall not disclose or divulge your MIRO membership details
                    to anyone or post them on any social media platform;{" "}
                  </StyledListItem>
                  <StyledListItem>
                    shall not allow anyone else to redeem the benefits of your
                    MIRO membership;
                  </StyledListItem>
                  <StyledListItem>
                    shall ensure that all information provided to us is
                    truthful, complete and accurate;
                  </StyledListItem>
                  <StyledListItem>
                    shall keep your personal data submitted to us (via “My
                    Account” of Our Site) up to date and accurate, current and
                    complete;
                  </StyledListItem>
                  <StyledListItem>
                    shall not resell or otherwise distribute any item or
                    membership benefit redeemed, purchased or otherwise obtained
                    directly or indirectly through the MIRROR Official Fan Club
                    (including but not limited to tickets and merchandise);
                  </StyledListItem>
                  <StyledListItem>
                    shall not share the material on Our Site (including but not
                    limited to the MIRO Member exclusive resources), in any
                    format, medium, or channel, for usage by any other person
                    without prior written consent from us;
                  </StyledListItem>
                  <StyledListItem>
                    shall not use or otherwise exploit the material on Our Site
                    for any commercial purpose without prior written consent
                    from us; and
                  </StyledListItem>
                  <StyledListItem>
                    shall comply with all applicable laws and regulations in
                    relation to your use of Our Site and membership in the
                    MIRROR Official Fan Club.
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                You must strictly comply with the Agreement to maintain your
                MIRO membership.
              </StyledListItem>
              <StyledListItem>
                You represent and warrant that you are not designated on the
                Specially Designated Nationals and Blocked Persons List
                maintained by the Office of Foreign Assets Control of the United
                States Department of Treasury or any other sanctions list.
              </StyledListItem>

              <StyledSubTitle>Our Rights and Responsibilities</StyledSubTitle>
              <StyledListItem>
                <StyledP>
                  In addition to our rights stated under the Website Terms and
                  Conditions, we may:
                </StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    request any MIRO Member to present his/her identification document with a photograph
                    for identity verification purposes at any Member Event or at
                    the time of any benefit redemption. In the event that the
                    MIRO Member fails to complete the identity verification
                    process, we reserve the right to deny his/her access to any
                    such event or reject his/ her benefit redemption in our sole
                    discretion without any compensation or refund;
                  </StyledListItem>
                  <StyledListItem>
                    verify the identity of any MIRO Member (and/or his/her
                    parent or legal guardian, if applicable) by requesting
                    appropriate documentation. In the event that the identity of
                    the MIRO Member (and/or his/her parent or legal guardian, if
                    applicable) fails to meet our standards or criteria, we
                    reserve the right to suspend or terminate his/her membership
                    in our sole discretion without any compensation or refund;
                  </StyledListItem>
                  <StyledListItem>
                    terminate the MIRO membership program, or make changes to
                    any component of the MIRO membership program (including but
                    not limited to the Membership Fee, the Renewal Fee and
                    membership benefits) at any time as we may determine in our
                    sole discretion without any compensation or refund;
                  </StyledListItem>

                  <StyledListItem>
                    use agent(s) or subcontractor(s) to perform our
                    responsibilities; and
                  </StyledListItem>
                  <StyledListItem>
                    assign or transfer our rights and obligations hereunder to
                    any person or entity without your consent.
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                Unless otherwise indicated, all intellectual property rights
                subsisting in Our Site and the material on Our Site (including
                but not limited to the MIRO Member exclusive resources) are
                owned by us or are licensed to be used by us.
              </StyledListItem>

              <StyledSubTitle>
                Payment to the MIRROR Official Fan Club
              </StyledSubTitle>
              <StyledListItem>
                The Membership Fee and the Renewal Fee(s) are non-refundable
                under any circumstances.
              </StyledListItem>
              <StyledListItem>
                For online payment(s) to the MIRROR Official Fan Club (including
                but not limited to the Membership Fee), you must provide your
                valid payment details to one of our authorised third party
                payment gateways.You warrant that the payment details you
                provide is correct and that you are fully authorised to use the
                applicable payment card, payment instrument and/or payment
                method.{" "}
              </StyledListItem>
              <StyledListItem>
                You are advised to read the terms and conditions of our
                third-party payment gateway before you provide any payment
                details or information to it. We shall not be responsible for
                any details or information provided to such payment gateway(s)
                and you agree that we will not be liable for any damages, losses
                and/or expenses that you may suffer by doing so.{" "}
              </StyledListItem>
              <StyledListItem>
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                gateway(s) whose use of your personal information is governed by
                their privacy policy.
              </StyledListItem>

              <StyledSubTitle>
                Suspension and Termination of Membership
              </StyledSubTitle>
              <StyledListItem>
                We reserve the right to suspend or terminate the MIRO membership
                of any member if we in our sole and absolute discretion
                determines that any of the following circumstances has occurred:
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    the MIRO Member has violated any applicable laws,
                    regulations, rules, guidelines and/or terms and conditions,
                    in all foregoing cases whether administered or imposed by
                    government authorities, enforcement bodies, MIRROR Official
                    Fan Club, or our affiliates, subcontractors or service
                    providers;{" "}
                  </StyledListItem>
                  <StyledListItem>
                    the MIRO Member has exhibited any unacceptable behavior
                    (including but not limited to any uncooperative, disruptive
                    or dangerous behavior) at any Member Event, or in connection
                    with any other MIRO Member related initiatives that the
                    MIRROR Official Fan Club organizes or partakes in;
                  </StyledListItem>
                  <StyledListItem>
                    the MIRO member has provided to us any untruthful,
                    incomplete or inaccurate personal information;
                  </StyledListItem>
                  <StyledListItem>
                    in the event of any suspected or actual breach or violation
                    of the Agreement;
                  </StyledListItem>
                  <StyledListItem>
                    in the event of any misuse or wrongdoing in connection with
                    the membership;
                  </StyledListItem>
                  <StyledListItem>
                    in the event of any suspected or actual abuse of the MIRO membership system, questionable account information, or provision of false information by MIRO member;
                  </StyledListItem>
                  <StyledListItem>
                    any action or inaction by the MIRO Member which is
                    reasonably expected to derogate, erode or tarnish any of the
                    “MIRROR”, “MIRROR Official Fan Club”, “MakerVille” or the
                    “PCCW” brand or the image of the “MIRROR” group or any of
                    its artistes;{" "}
                  </StyledListItem>
                  <StyledListItem>
                    the MIRO Member has or is suspected of having made any
                    untruthful or inaccurate assertions relating to the MIRROR
                    group, MIRROR Official Fan Club, MakerVille and/or PCCW; and
                  </StyledListItem>
                  <StyledListItem>
                    to safeguard or protect our interests.
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>
              <StyledListItem>
                In the event of termination, the MIRO membership of the member
                will be deactivated and any unused offers, privileges or
                benefits will be forfeited immediately without any compensation
                or refund.{" "}
              </StyledListItem>

              <StyledSubTitle>Indemnity</StyledSubTitle>
              <StyledListItem>
                <StyledP>
                  You agree to indemnify us, our affiliates, Third Party
                  Providers, service providers, sub-contractors, licensors and
                  agents against any and all action, liability, cost, claim,
                  loss, damage, proceeding and/or expense suffered or incurred
                  by each of them arising from or which is related to:
                </StyledP>
                <StyledOrderList type="a">
                  <StyledListItem>
                    your usage of the MIRO membership and/or MIRO membership
                    benefits; and/or
                  </StyledListItem>
                  <StyledListItem>
                    your breach of these Membership Terms.;
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>
              <StyledListItem>
                The indemnity clause shall survive the termination of the
                Agreement.
              </StyledListItem>

              <StyledSubTitle>Limitation of Liability</StyledSubTitle>
              <StyledListItem>
                Without prejudice to other provisions herein, we shall not be
                held liable or responsible in any form for any loss or damage
                suffered by any MIRO Member arising from:
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    the loss, theft or unauthorised use of his/her membership
                    card, login details and/or password;
                  </StyledListItem>
                  <StyledListItem>
                    any change, postponement or cancellation of any Member
                    Event;
                  </StyledListItem>

                  <StyledListItem>
                    any untrue, incomplete or inaccurate information provided to
                    us;
                  </StyledListItem>
                  <StyledListItem>
                    the suspension or termination of his/her MIRO membership in
                    accordance with the Membership Terms;{" "}
                  </StyledListItem>
                  <StyledListItem>
                    any acts or omissions of third parties;
                  </StyledListItem>
                  <StyledListItem>
                    any errors published in relation to any information or any
                    benefit description; and/or
                  </StyledListItem>
                  <StyledListItem>
                    any failure or inability to purchase or have access to any
                    good and/or service and/or membership benefit offered by us
                    arising from or in connection with technical issues with Our
                    Site or otherwise.
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                To the fullest extent permissible by law, we shall not in any
                way be liable for any indirect, special, exemplary, punitive,
                incidental or consequential loss, damage or expense of any kind
                suffered by you (whether based on contract, tort or otherwise)
                which arise out of or are in connection with the Membership
                Terms, the MIRO membership, products or services offered by us
                or otherwise.{" "}
              </StyledListItem>
              <StyledListItem>
                To the fullest extent permissible by law, our aggregate
                liability to you (arising out of or in connection with the
                Membership Terms, the MIRO membership, products or services
                offered by us or otherwise) shall not exceed the amount of the
                Membership Fee (or the Renewal Fee, if your membership has been
                renewed) paid by you for the calendar year of membership at the
                time of the event giving rise to the claim.
              </StyledListItem>

              <StyledSubTitle>Miscellaneous</StyledSubTitle>
              <StyledListItem>
                If any provisions of the Membership Terms shall be construed to
                be illegal, invalid or unenforceable for any reason, such
                provisions shall not affect the legality, validity or
                enforceability of the other provisions of the Membership Terms.
                The illegal, invalid or unenforceable provision shall be amended
                to the extent necessary to make them legal, valid or enforceable
                or, if not possible to do so, deleted from the Membership Terms,
                but all other provisions of the Membership Terms shall continue
                in full force and effect.
              </StyledListItem>
              <StyledListItem>
                In case of any disputes pertaining to the Membership Terms, our
                decision shall be final and conclusive.
              </StyledListItem>
              <StyledListItem>
                The Membership Terms are governed by the laws of Hong Kong SAR
                and you agree to submit to the exclusive jurisdiction of the
                Hong Kong SAR courts.
              </StyledListItem>
              <StyledListItem>
                Should there be any inconsistency or conflict between the
                English and Chinese versions of these Membership Terms, the
                English version shall prevail.
              </StyledListItem>
              <StyledListItem>
                In the event of any conflicts between the Website Terms and
                Conditions and the Membership Terms, the Membership Terms shall
                prevail.
              </StyledListItem>
            </StyledOrderList>
          </StyledDiv>
        </div>
      ) : (
        // ----------------------CHINESE VERSION----------------------
        <div style={{ textAlign: "justify" }}>
          <StyledTitle>MIRROR OFFICIAL FAN CLUB 會籍之條款及條件</StyledTitle>

          <StyledSubTitle>關於我們</StyledSubTitle>

          <StyledDiv>
            <StyledP>
              MIRROR Official Fan Club是於2022年成立的唯一官方 MIRROR
              歌迷會。我們旨在為MIRROR歌迷打造及維持一個一站式網上平台讓MIRROR歌迷跟MIRROR連繫及支持其作品。
            </StyledP>
            <StyledP>
              MIRROR Official Fan Club的會員均稱為“MIRO”，意即
              「MIRROR的英雄」。
            </StyledP>
          </StyledDiv>

          <StyledDiv>
            <StyledP>重要通知：</StyledP>
            <StyledOrderList type="a">
              <StyledListItem>
                請細閱以下條款及條件（「<Bold>會籍條款</Bold>
                」）。申請成為MIRO會員即表示閣下（下稱「<Bold>MIRO 會員</Bold>{" "}
                」、「<Bold>閣下</Bold>」或「<Bold>您</Bold>
                」）同意受不時更新的會籍條款及網站條款及條件（可於
                <a href="https://www.mirrorweare.com">
                  https://www.mirrorweare.com
                </a>
                （「<Bold>本會網站</Bold>
                」）查閱）約束。會籍條款和網站條款及條件共同構成我們與閣下達成的協議（「
                <Bold>協議</Bold>」）。
              </StyledListItem>
              <StyledListItem>
                MIRROR Official Fan Club由電訊盈科媒體集團旗下成員MakerVille
                Company Limited（下稱「MIRROR Official Fan
                Club」、「本會」或「我們」）營運。
              </StyledListItem>
              <StyledListItem>
                我們保留權利不時更新會籍條款。會籍條款的更新於本會網站公佈後即時生效，閣下有責任查閱有關更新內容。閣下，作為MIRO會員同意受有關修訂的約束（無論閣下有沒有查閱），惟倘適用法律規定，我們將通知及/或以其他方式獲得閣下同意有關修訂內容。
              </StyledListItem>
            </StyledOrderList>
          </StyledDiv>

          <StyledDiv>
            <StyledSubTitle>MIRO 會籍資格</StyledSubTitle>
            <StyledOrderList type="1">
              <StyledListItem>
                註冊MIRO會籍的申請人須年滿11歲或以上。{" "}
              </StyledListItem>
              <StyledListItem>
                如果申請人為18歲以下，其父母或合法監護人必須於申請時完成及提交一份網上同意書。
              </StyledListItem>

              <StyledSubTitle>MIRO會籍申請</StyledSubTitle>
              <StyledListItem>
                成為MIRO會員，閣下必須於本會網站直接提交網上申請表一份及繳交相關會籍費用（可能會不時更改）（「會費」）。
              </StyledListItem>

              <StyledListItem>
                MIRO會籍提供各種專屬福利及優惠（詳見以下「會員福利」部分）。為努力確保我們的所有尊貴MIRO會員均有機會公平享有此等福利及禮遇，我們會收集個人資料以使我們能核實每人只申請一個MIRO會籍（但我們並不保證使用此方法可確保達到該目的）。因此，閣下的MIRO會籍申請必須包含以下個人資料：
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    閣下的全名（須與閣下香港身份證或護照上的名字相同）；
                  </StyledListItem>
                  <StyledListItem>閣下的出生日期;</StyledListItem>
                  <StyledListItem>閣下的電子郵件地址； </StyledListItem>
                  <StyledListItem>閣下的香港聯絡號碼；及 </StyledListItem>
                  <StyledListItem>閣下的香港郵寄地址。 </StyledListItem>
                </StyledOrderList>
                <StyledP>
                  有關我們如何處理閣下個人資料的政策及方法的內容，請參見
                  <a href="/privacy-terms">個人資料收集聲明</a>
                </StyledP>
              </StyledListItem>

              <StyledListItem>
                我們的系統將向您提供的電子郵件地址發送驗證碼以作核實電郵用途。閣下有責任保證提供的電郵地址正確。對於任何未能送達的電郵，恕我們概不負責。
              </StyledListItem>
              <StyledListItem>
                如果申請人的年齡在18歲以下，其父母或合法監護人必須於申請時完成及提交一份網上同意書。
              </StyledListItem>
              <StyledListItem>
                閣下成功繳付會費後，我們將發送電郵以確認閣下註冊成為MIRO會員。
              </StyledListItem>

              <StyledSubTitle>MIRO會籍的有效期及更新</StyledSubTitle>

              <StyledListItem>
                新會籍申請及會籍續期每年的1月1日開始生效，並將有效至該同一年度的12月31日。
              </StyledListItem>
              
              <StyledSubTitle>會員福利</StyledSubTitle>

              <StyledListItem>
                <StyledP>所有MIRO會員將有機會尊享以下MIRO會員福利：</StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    MIRO會員禮盒一份（可能會不時更改）
                  </StyledListItem>
                  <StyledListItem>預訂指定活動門票*^</StyledListItem>
                  <StyledListItem>
                    參加MIRO尊屬活動*^
                  </StyledListItem>
                  <StyledListItem>
                    參加《Hi MIRO! SEE U ZOOM 網上見面會》
                  </StyledListItem>
                  <StyledListItem>
                    尊享訂購MIRROR限定精選商品*
                  </StyledListItem>
                  <StyledListItem>
                    於MIRO專區瀏覽及下載MIRROR獨家影音
                  </StyledListItem>
                  <StyledFootnote>
                    *視乎供應，活動主辦方分配的數量及／或待活動確認
                  </StyledFootnote>
                  <StyledFootnote>^活動可能設有年齡限制</StyledFootnote>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                透過MIRROR Official Fan
                Club提供於MIRO會員購買的活動門票（統稱「
                <Bold>門票</Bold>
                」）的數量有限（視適用的主辦方分發給我們的數量）。我們就每位MIRO會員將可以購買的門票供應或數量不作任何保證。我們將以我們認為合適的機制向MIRO會員分發可以購買的門票。
              </StyledListItem>

              <StyledListItem>
                會員福利可能包括由第三方（「<Bold>第三方供應商</Bold>
                」）提供的商品或服務。您同意遵守我們及/或相關第三方供應商指定的適用條款及條件；您亦同意我們不對以下情況承擔責任或負責:
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    第三方供應商的行為、疏忽或不作為；
                  </StyledListItem>
                  <StyledListItem>
                    因您使用（或無法使用）第三方供應商提供的商品或服務而造成的任何傷害及/或損失；及
                  </StyledListItem>
                  <StyledListItem>
                    您與第三方供應商之間的任何交易或爭議。
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledSubTitle>MIRO會員禮盒</StyledSubTitle>
              <StyledListItem>
                我們致力在MIRO會員新會籍申請或續會後三個月內將MIRO會員禮盒發貨至MIRO會員的香港送貨地址或指定領取站自取。請務必須知，我們對上述送貨時間不作保證。
              </StyledListItem>
              <StyledListItem>
                請務必須知，MIRO會員禮盒實際發貨日期將視乎閣下所選擇之送貨方式、產品存貨情況、天氣情況、交通情況及其他因素影響而有所不同。MIRO會員禮盒預計出貨日期或會因應上述情況略作調整，詳情請密切留意我們的電郵通知或社交平台的公布。
              </StyledListItem>
              <StyledListItem>
                請注意我們的送貨服務僅限於香港的送貨地址。
                若送貨地址為偏遠地區（西貢、清水灣、石澳、赤柱、愉景灣、馬灣、長洲、南丫島及坪洲
                、大嶼南，包括但不限於：大澳、塘福、長沙、梅窩、貝澳等
                ），閣下需於發貨前支付額外送貨費用。
              </StyledListItem>
              <StyledListItem>
                有關寄送商品之所有事宜（包括但不限於需額外收取之寄送費用）須受我們的派送服務提供商的條款及條件約束。MIRO會員有責任提供準確及完整的資料（包括但不限於送貨地址及聯絡電話）。我們概不就寄送延遲造成的任何損失或損害承擔責任。
              </StyledListItem>

              <StyledSubTitle>MIRO會員專屬活動</StyledSubTitle>

              <StyledListItem>
                本會將不定期為MIRO會員舉辦專屬聚會及粉絲見面會活動（「
                <Bold>會員活動</Bold>」）。請注意某些會員活動將設有年齡限制。
              </StyledListItem>
              <StyledListItem>
                所有會員活動出席者必須尊重及遵守我們訂立的規則及規範。
              </StyledListItem>
              <StyledListItem>
                就有年齡限制的會員活動，我們有可能要求18歲以下的參加者在活動當天向我們的工作人員出示已簽署的家長/監護人授權聲明書正本（「
                <Bold>監護人授權書</Bold>
                」）。如前述參加者不能出示已簽署的監護人授權書正本，該參加者將會被拒絕參加會員活動，且不獲任何補償或退款。
              </StyledListItem>
              <StyledListItem>
                本會、場地提供者及其他參與之主辦單位（如適用）保留更改、延遲或取消任何會員活動之權利。如有任何爭議，本會、場地提供者及/或其他主辦單位（如適用）保留最終決定權。
              </StyledListItem>

              <StyledSubTitle>MIRO會員的責任</StyledSubTitle>
              <StyledListItem>
                <StyledP>閣下，作為MIRO會員：</StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>只可申請一個MIRO會籍；</StyledListItem>
                  <StyledListItem>
                    不得轉讓或借出您的MIRO會籍予其他人；
                  </StyledListItem>
                  <StyledListItem>
                    需自行負責保管閣下的MIRO登錄資料及密碼；
                  </StyledListItem>

                  <StyledListItem>
                    不得向任何其他人披露或洩露或在任何社交媒體平台上張貼您的MIRO會籍資料；
                  </StyledListItem>
                  <StyledListItem>
                    不得允許任何其他人換領您的MIRO會籍福利；
                  </StyledListItem>
                  <StyledListItem>
                    必須保證提供給我們的所有資料均為真實、完整及正確；
                  </StyledListItem>

                  <StyledListItem>
                    必須（經本會網站的「我的帳戶」）維持及及時更新提供給我們的所有個人資料，並保持該等資料的現行、準確及完整；
                  </StyledListItem>
                  <StyledListItem>
                    不得轉售或以其他方式分發透過MIRROR Official Fan
                    Club直接或間接換領、購買或獲得的物品或會員福利（包括但不限於門票及商品）；
                  </StyledListItem>
                  <StyledListItem>
                    未經我們的事先書面同意，不得以任何形式、媒介或渠道共享本會網站上的內容（包括但不限於MIRO會員專屬資源）；
                  </StyledListItem>

                  <StyledListItem>
                    未經我們的事先書面同意，不得使用或利用本會網站上的資料作任何商業用途；及
                  </StyledListItem>
                  <StyledListItem>
                    必須遵守所有於閣下使用本會網站及MIRROR Official Fan
                    Club會籍有關之適用法律及規則。
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                閣下必須嚴格遵守本協議以保持閣下的MIRO會籍有效。
              </StyledListItem>
              <StyledListItem>
                閣下聲明及保證閣下未列名美國外國資產控制辦公室(Office of Foreign
                Assets Control of the United States Department of Treasury)
                的特別指定國民及禁止人士清單 (Specially Designated Nationals And
                Blocked Persons List) 或任何其他制裁名單。
              </StyledListItem>

              <StyledSubTitle>我們的權利及責任</StyledSubTitle>
              <StyledListItem>
                <StyledP>
                  除網站條款及條件所載之我們的權利外，我們可以：
                </StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    要求任何一位MIRO會員在參加任何會員活動或兌換福利時出示其MIRO會員證及其身份證明文件（須含照片）以核實身份。如該MIRO會員未能完成該身份核實程序，我們全權保留拒絕其參加任何此等活動或拒絕其兌換福利之權利，且不設任何賠償或退款。
                  </StyledListItem>
                  <StyledListItem>
                    要求適當文件以驗證任何MIRO會員（及/或其父母或合法監護人，如適用）的身份。如MIRO會員（及/或其父母或合法監護人，如適用）之身份核實結果未能達到我們的標準或要求，我們全權保留暫停或終止其會籍的權利，且不作任何退款或賠償；
                  </StyledListItem>
                  <StyledListItem>
                    自行全權決定隨時終止MIRO會籍計劃或更改MIRO會籍計劃任何部分（包括但不限於會費、更新費用及會員福利），且不作任何退款或賠償。
                  </StyledListItem>
                  <StyledListItem>
                    可使用代理或分包商履行我們的職責；及
                  </StyledListItem>
                  <StyledListItem>
                    轉讓或轉移我們在本協議下的權利及義務給任何個人或實體，且無需閣下的同意。
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                除非另有說明，本會網站上的所有知識產權及本網站上的內容（包括但不限於MIRO會員專屬資源）由我們全權擁有或我們被許可使用。
              </StyledListItem>

              <StyledSubTitle>向MIRROR Official Fan Club付款</StyledSubTitle>
              <StyledListItem>
                會費及更新費用於任何情況下均不設退款。
              </StyledListItem>
              <StyledListItem>
                經網上付款形式向MIRROR Official Fan
                Club進行支付（包括但不限於會費），閣下必須向我們授權的第三方付款平台提供閣下的有效付款詳情。閣下保證閣下提供的付款資料均屬正確，及閣下已獲充分授權使用相關付款卡、付款工具及/或付款方式。
              </StyledListItem>
              <StyledListItem>
                我們建議閣下向我們的第三方付款平台提供任何付款詳情或資料前閱讀其條款及條件。我們就提供於此等付款平台的任何詳情或資料不承擔責任，而且閣下同意我們就閣下因此而遭受的任何損害、損失及/或費用不承擔責任。
              </StyledListItem>
              <StyledListItem>
                我們將不會儲存或收集閣下付款卡的資料。此等資料將直接提供於我們的第三方付款平台，該第三方付款平台對閣下個人資料的使用受其私隱政策的約束。
              </StyledListItem>

              <StyledSubTitle>暫停及終止會籍</StyledSubTitle>
              <StyledListItem>
                <StyledP>
                  在以下情況，我們保留暫停或終止任何會員的MIRO會籍（不設補償或退款）的所有權利（由我們全權酌情決定）：
                </StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    MIRO會員違反任何適用法律、法規、規範及/或條款及條件，無論該等法律、法規、規範及/或條款及條件是由政府機構、執行團體、MIRROR
                    Official Fan
                    Club或我們的聯屬公司、分銷商或服務提供商執行或訂立；
                  </StyledListItem>
                  <StyledListItem>
                    MIRO會員於任何會員活動或MIRROR Official Fan
                    Club舉辦或參與的任何其他MIRO會籍相關活動上進行了不可接受的行為（包括但不限於任何不合作、破壞性或危險性行為）；
                  </StyledListItem>
                  <StyledListItem>
                    MIRO 會員向我們提供任何不真實、不完整或不準確的個人資料；
                  </StyledListItem>
                  <StyledListItem>
                    任何涉嫌或實際違反協議的行為；
                  </StyledListItem>
                  <StyledListItem>
                    與會籍有關的任何不正當使用或不當行為；
                  </StyledListItem>
                  <StyledListItem>
                    任何 MIRO 會員被懷疑或實際濫用系統、其帳戶資料存疑或提供虛假資料；
                  </StyledListItem>
                  <StyledListItem>
                    MIRO會員的任何行為或不行為，基於合理理由被認定會減損、削弱或有損
                    “MIRROR＂、“MIRROR Official Fan Club＂, “MakerVille＂ 或
                    “電訊盈科＂、或MIRROR組合或任何其成員之品牌或形象；
                  </StyledListItem>
                  <StyledListItem>
                    MIRO會員被懷疑或實際作出有關MIRROR組合或任何其成員、MIRROR
                    Official Fan
                    Club、MakerVille及/或電訊盈科的任何不實或不準確言論；及
                  </StyledListItem>
                  <StyledListItem>維護或保障我們的利益。</StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                會籍一旦終止，會員的MIRO會籍將被停用，而任何未使用的優惠、禮遇或福利將被立即沒收，並不會發放任何補償或退款。
              </StyledListItem>

              <StyledSubTitle>彌償</StyledSubTitle>
              <StyledListItem>
                您同意彌償我們、我們的聯屬公司、第三方供應商、服務供應商、分包商、許可方及代理人各自因或有關以下而遭受或招致的任何及所有行動、責任、支出、索賠、損失、損害、法律程序及/或支出：
                <StyledOrderList type="1" style={{ marginTop: 20 }}>
                  <StyledListItem>
                    您使用MIRO會籍及/或MIRO會員福利；及/或
                  </StyledListItem>
                  <StyledListItem>您違反本會籍條款。</StyledListItem>
                </StyledOrderList>
              </StyledListItem>
              <StyledListItem>
                此彌償條款將於協議終止後繼續有效。
              </StyledListItem>

              <StyledSubTitle>責任限制</StyledSubTitle>
              <StyledListItem>
                <StyledP>
                  在不損害本會籍條款的其他條款情況下，我們就任何MIRO會員因以下情況而遭受的任何損失或損害不承擔任何形式的責任：
                </StyledP>
                <StyledOrderList type="1">
                  <StyledListItem>
                    其會員證、登錄資料及/或密碼遺失、被盜用或未經授權使用；
                  </StyledListItem>
                  <StyledListItem>
                    任何會員活動的變更、延遲或取消；
                  </StyledListItem>
                  <StyledListItem>
                    向我們提供任何不真實、不完整或不準確資料；
                  </StyledListItem>
                  <StyledListItem>
                    依據會籍條款暫停或終止其MIRO 會籍；
                  </StyledListItem>
                  <StyledListItem>第三方的任何作為或不作為；</StyledListItem>
                  <StyledListItem>
                    任何信息或福利表述的錯誤;及/或
                  </StyledListItem>
                  <StyledListItem>
                    因或有關本會網站的技術問題或其他原因而未能或無法購買或取得我們提供的任何商品及/或服務及/或MIRO會員福利。
                  </StyledListItem>
                </StyledOrderList>
              </StyledListItem>

              <StyledListItem>
                在法律允許的最大範圍內，我們將不以任何方式對您因或有關會籍條款、MIRO會籍、我們提供的產品或服務或其他遭受的任何間接、特殊、懲戒性、懲罰性、偶然或後果性損失、損害或費用承擔責任（不論是基於合同、侵權或其他基準主張）。
              </StyledListItem>
              <StyledListItem>
                在法律允許的最大範圍內，我們對閣下承擔的責任總額（因會籍條款、MIRO會籍、我們提供的產品或服務、或其他原因引起或相關）不會超過閣下於引起索賠發生時為閣下會籍支付的年度會費（或更新費用，如果閣下的會籍被更新）。
              </StyledListItem>

              <StyledSubTitle>其他事項</StyledSubTitle>
              <StyledListItem>
                如會籍條款中的任何條款因任何原因被解釋為非法、無效或無法執行，該條款將不影響會籍條款中其他條款的合法性、有效性或可執行性。非法、無效或不可執行的條文應在必要的範圍內予以修訂以使其合法、有效或可執行，否則應從會籍條款中刪除，而會籍條款的所有其他條文應繼續具十足效力及作用。
              </StyledListItem>
              <StyledListItem>
                如就會籍條款有任何爭議，MIRROR Official Fan Club保留最終決定權。
              </StyledListItem>
              <StyledListItem>
                會籍條款受香港特別行政區法律管轄，您同意接受香港法院的專屬管轄。
              </StyledListItem>
              <StyledListItem>
                如這些會籍條款的中英文版本存在任何不一致或衝突，一概以英文版本為準。
              </StyledListItem>
              <StyledListItem>
                如網站條款及條件與會籍條款的內容存在任何衝突，一概以會籍條款為準。
              </StyledListItem>
            </StyledOrderList>
          </StyledDiv>
        </div>
      )}
    </div>
  );
}

const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
`;

const StyledSubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: justify;
  padding-left: 0;
`;

const StyledDiv = styled.div`
  margin-bottom: 20px;
`;

const StyledP = styled.div`
  display: inline-block;
  margin-bottom: 20px;
`;

const StyledOrderList = styled.ol`
  padding-left: 50px;
  counter-reset: item;
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
  display: block;
  :before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    margin-right: 15px;
  }
`;

const StyledFootnote = styled.div`
  text-align: right;
  margin-bottom: 10px;
  font-style: italic;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  border: 1px solid #333;
  margin-bottom: 10px;
`;

const StyledTr = styled.tr`
  border-collapse: collapse;
  border: 1px solid #333;
`;

const StyledTd = styled.td`
  border-collapse: collapse;
  border: 1px solid #333;
  padding: 15px;
`;

const Bold = styled.div`
  font-weight: bold;
  display: inline-block;
`;
