import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TitleGroup from "../../components/common/typographies/TitleGroup";
import CommonBottomBar from "../../components/common/navbar/CommonBottomBar";
import colors from "../../utils/colors";
import CommonNavBar from "../../components/common/navbar/CommonNavBar";
import MemberCarousel from "../../components/memberProfile/MemberCarousel";
import { useNavigate } from "react-router-dom";
import { breakpoint, ResponsiveUI, size } from "../../utils/responsive";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import SelectMemberCarousel from "../../components/memberProfile/SelectMemberCarousel";
import useMediaQuery from "@mui/material/useMediaQuery";

export const WindowSize = () => {
  const matches = useMediaQuery(`(min-width: ${size.desktop}px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);
  const match1200 = useMediaQuery(`(min-width: 1200px)`);
  const match1024 = useMediaQuery(`(min-width: 1024px)`);

  if (matchLarge) {
    return "largeDesktop";
  } else if (match1200) {
    return "1200";
  } else if (match1024) {
    return "1024";
  } else if (matches) {
    return "desktop";
  } else {
    return "mobile";
  }
};

function ListMember({ data }) {
  const { i18n } = useTranslation();
  const isMobile = WindowSize() == "mobile";
  const navigate = useNavigate();
  const deviceType = WindowSize();

  const handleClick = (memberId) => {
    navigate(`./${memberId}`);
  };

  const _renderLargeDesktop = () => {
    return (
      <MemberContainer>
        <Slider>
          {data &&
            data.results.map((member, idx) => {
              return (
                <Slide onClick={() => handleClick(member.slug)}>
                  <NickName>
                    <CommonText
                      fontSize="50px"
                      fontWeight="600"
                      color="#F27845"
                      uppercase
                    >
                      {member?.memberNameEn?.split(" ")[0]}
                    </CommonText>
                  </NickName>
                  <SureName>
                    <CommonText
                      fontSize="50px"
                      fontWeight="600"
                      color="#F27845"
                      uppercase
                    >
                      {member?.memberNameEn?.split(" ")[1]}
                    </CommonText>
                  </SureName>
                  <SlideImage src={member.coverImage} />
                </Slide>
              );
            })}
        </Slider>
      </MemberContainer>
    );
  };

  const _renderDesktop = () => {
    return (
      <div>
        <MemberContainer>
          <Slider>
            {data &&
              data.results.map((member, idx) => {
                if (idx < 6)
                  return (
                    <Slide onClick={() => handleClick(member.slug)}>
                      <NickName>
                        <CommonText
                          fontSize="42px"
                          largeFontSize="50px"
                          fontWeight="600"
                          color="#F27845"
                          uppercase
                        >
                          {member?.memberNameEn?.split(" ")[0]}
                        </CommonText>
                      </NickName>
                      <SureName>
                        <CommonText
                          fontSize="42px"
                          largeFontSize="50px"
                          fontWeight="600"
                          color="#F27845"
                          uppercase
                        >
                          {member?.memberNameEn?.split(" ")[1]}
                        </CommonText>
                      </SureName>
                      <SlideImage src={member.coverImage} />
                    </Slide>
                  );
              })}
          </Slider>
        </MemberContainer>
        <div style={{ height: 10 }} />
        <MemberContainer>
          <Slider>
            {data &&
              data.results.map((member, idx) => {
                if (idx > 5)
                  return (
                    <Slide onClick={() => handleClick(member.slug)}>
                      <NickName>
                        <CommonText
                          fontSize="42px"
                          largeFontSize="50px"
                          fontWeight="600"
                          color="#F27845"
                          uppercase
                        >
                          {member?.memberNameEn?.split(" ")[0]}
                        </CommonText>
                      </NickName>
                      <SureName>
                        <CommonText
                          fontSize="42px"
                          largeFontSize="50px"
                          fontWeight="600"
                          color="#F27845"
                          uppercase
                        >
                          {member?.memberNameEn?.split(" ")[1]}
                        </CommonText>
                      </SureName>
                      <SlideImage src={member.coverImage} />
                    </Slide>
                  );
              })}
          </Slider>
        </MemberContainer>
      </div>
    );
  };

  const _renderMobile = () => {
    return (
      <StyledMobileMainDiv>
        {data &&
          data.results.map((member, idx) => {
            return (
              <StyledMemberCard onClick={() => handleClick(member.slug)}>
                <MemberMobileImage src={member.coverImage} />
                <CommonText
                  color={colors.white}
                  fontSize="14px"
                  fontWeight="600"
                >
                  {i18n.language === "zh"
                    ? member.memberNameZh
                    : member.memberNameEn}
                </CommonText>
              </StyledMemberCard>
            );
          })}
      </StyledMobileMainDiv>
    );
  };

  return (
    <StyledMainDiv>
      <CommonNavBar theme="white" />
      <div>
        <TitleGroup
          zhTitle="個人"
          enTitle="Member Profile"
          color={colors.white}
        />
      </div>

      <ResponsiveUI
        mobile={_renderMobile()}
        desktop={_renderDesktop()}
        largeDesktop={_renderLargeDesktop()}
      />

      <CommonBottomBar theme="white" bgColor="darkPurple" />
    </StyledMainDiv>
  );
}

const StyledMobileMainDiv = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-around;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
`;

const StyledMemberCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px 15px 10px;
  width: 100%;
  height: auto;
`;

const MemberMobileImage = styled.img`
  user-drag: none;
  width: 42vw;
  height: 42vw;
  display: block;
  object-fit: cover;
  object-position: center center;
  margin-bottom: 10px;
`;

const StyledMainDiv = styled.div`
  background-color: ${(props) =>
    props.isSelected ? "#908681" : colors.purple};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;

  @media ${breakpoint.mobile} {
    height: auto;
  }

  @media ${breakpoint.desktop} {
    height: 100vh;
  }
`;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0px 40px;
`;

const Slider = styled.div`
  width: 100%;
  display: flex;

  @media ${breakpoint.desktop} {
    height: calc((100vh - 250px - 160px) / 2);
    max-height: 220px;
    min-height: 140px;
  }

  @media ${breakpoint.largeDesktop} {
    height: calc(100vh - 250px - 160px);
    max-height: 400px;
    min-height: 140px;
  }
`;

const SureName = styled.div`
  position: absolute;
  bottom: -20px;
  right: -40px;
  z-index: 10;
  display: none;
  animation: showSure 1s forwards ease-in-out;

  @keyframes showSure {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const NickName = styled.div`
  position: absolute;
  bottom: 20px;
  left: -40px;
  z-index: 10;
  display: none;
  animation: showNick 1s forwards ease-in-out;

  @keyframes showNick {
    0% {
      bottom: 20px;
      opacity: 0;
    }
    100% {
      bottom: 50px;
      opacity: 1;
    }
  }
`;

const Slide = styled.div`
  position: relative;
  flex: 1 0 auto;
  overflow: visible;
  margin: 8px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  opacity: 0.3;
  @media ${breakpoint.desktop} {
    width: 0;
    max-width: calc((100vw - 150px) / 6);
    &:hover {
      max-width: min(
        391px,
        max(249px, calc((((100vh - 250px) - 160px) / 2) * 16 / 9))
      );
      width: min(
        391px,
        max(249px, calc((((100vh - 250px) - 160px) / 2) * 16 / 9))
      );
      opacity: 1;
      ${NickName} {
        display: block;
      }
      ${SureName} {
        display: block;
        bottom: 0px;
      }
    }
  }

  @media ${breakpoint.largeDesktop} {
    max-width: none;
    width: auto;
    &:hover {
      flex: 8;
      max-width: none;
      width: auto;
      opacity: 1;
      ${NickName} {
        display: block;
      }
      ${SureName} {
        display: block;
      }
    }
  }
`;

const SlideImage = styled.img`
  position: absolute;
  width: 100%;
  max-height: 400px;
  min-height: 140px;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  user-drag: none;

  @media ${breakpoint.desktop} {
    height: calc((100vh - 250px - 160px) / 2);
    max-height: 220px;
    min-height: 140px;
  }

  @media ${breakpoint.largeDesktop} {
    height: calc(100vh - 250px - 160px);
    max-height: 400px;
    min-height: 140px;
  }
`;

const MemberContainer2 = styled.div`
  object-fit: cover;
  height: auto;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
  width: ${(props) =>
    props.showMember === 1 ? "100%" : `calc(100% / ${props.showMember})`};
  flex-shrink: 0;
  flex-grow: 1;

  animation-name: ${(props) => (props.selectedImage ? "cropandfull" : " ")};
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes cropandfull {
    0% {
      transform: scaleX(0.2);
    }
    100% {
      transform: scaleX(1);
    }
  }
`;

const MemberImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 400px;
  user-drag: none;
`;

const StyledMemberDiv = styled.div`
  margin-top: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

export default ListMember;
