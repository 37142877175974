import styled from "styled-components";
const Container = styled.div`
    margin: 0%;
`
const Circle = styled.div`
    position: relative; width: 100%; padding-bottom: 100%; overflow: hidden;
`
const CircleText = styled.text`
    font-family: 'Helvetica Neue', Arial; font-size: 16px; font-weight: bold;
`
const CircleSVG = styled.svg`
position: absolute; left: 0; top: 0; width: 160px; height: 160px;
-webkit-animation-name: rotate;
   -moz-animation-name: rotate;
    -ms-animation-name: rotate;
     -o-animation-name: rotate;
        animation-name: rotate;
-webkit-animation-duration: 5s;
   -moz-animation-duration: 5s;
    -ms-animation-duration: 5s;
     -o-animation-duration: 5s;
        animation-duration: 5s;
-webkit-animation-iteration-count: infinite;
   -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
     -o-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
-webkit-animation-timing-function: linear;
   -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
     -o-animation-timing-function: linear;
        animation-timing-function: linear;

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}
`
export default function CircleAnimatedText({ CX, CY, R, text }) {
    return (
        <Container>
            <Circle>
                <CircleSVG
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    // width={`${CX*2}px`}
                    // height={`${CY*2}px`}
                    viewBox={`0 0 ${CX*2} ${CY*2}`}
                    enable-background={`new 0 0 ${CX*2} ${CY*2}`}
                    xmlSpace="preserve">
                    <defs>
                        <path id="circlePath" d={`
                        M ${CX}, ${CY} m -${R}, 0 
                        a ${R},${R} 0 0,1 ${R*2},0 
                        a ${R},${R} 0 0,1 -${R*2},0 `} />
                    </defs>
                    {/* <circle cx="150" cy="100" r="75" fill="none" /> */}
                    <g>
                        <use xlinkHref="#circlePath" fill="none" />
                        <CircleText>
                            <textPath xlinkHref="#circlePath">{text}</textPath>
                        </CircleText>
                    </g>
                </CircleSVG>
            </Circle>
        </Container>
    )
}