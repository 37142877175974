import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../AppContext';
import CommonButton from '../components/common/buttons/CommonButton';
import UpgradeButton from '../components/common/buttons/UpgradeButton';
import CommonDivider from '../components/common/divider/CommonDivider';
import CommonLabel from '../components/common/labels/CommonLabel';
import ProfileListItem from '../components/common/listItem/ProfileListItem';
import Space from '../components/common/Space';
import CommonText from '../components/common/typographies/CommonText';
import MemberCard from '../pageComponents/memberProfile/MemberCard';
import colors from '../utils/colors';
import { logout } from '../utils/logout';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import MIRODayCounter from '../pageComponents/profile/MIRODayCounter';
import MIRODayCounterDialog from '../pageComponents/profile/MIRODayCounterDialog';
import { Stack } from '@mui/material';
import ReactGA from 'react-ga4';

export const ProfileMenuItem = {
  PROFILE_INFO: 'profile/info',
  PROFILE_ORDER: 'profile/order',
  PROFILE_ORDER_DETAIL: 'profile/order/:orderId',
  PROFILE_MEMBERSHIP: 'profile/membership',
};

export default function ProfileMenu(props) {
  const { session, setSession, disableMiroReg, memberType } =
    useContext(AppContext);
  let navigate = useNavigate();
  const { t, i18n } = useTranslation('profile');
  const { selectedMenu, onClickMenu } = props;
  const { user } = session;
  const [showMIROCounterDialog, setShowMIROCounterDialog] = useState(false);

  function onClickLogout(e) {
    e.preventDefault();
    logout({ navigate, navigateTo: '/', setSession });
  }

  const expiryMessage = useMemo(() => {
    let expiryMessage = '';
    if (user?.memberType === 'MIRO') {
      let now = moment();
      if (user.memberStartAt) {
        // renewed
        if (
          user.lastMemberExpiresAt &&
          moment(user.lastMemberExpiresAt).isAfter(now)
        ) {
          //sepcial case
          expiryMessage =
            t('profile:menu.member_last_expiry', {
              date: moment(user?.lastMemberExpiresAt)
                .locale(i18n.language === 'en' ? 'en' : 'zh-hk')
                .format(t('profile:membership.date_format')),
            }) +
            '\n' +
            t('profile:menu.member_activate');
        } else {
          // new miro / expired miro rejoin
          if (moment(user?.memberStartAt).isBefore(now))
            expiryMessage = t('profile:menu.member_expiry', {
              date: moment(user?.memberExpiresAt)
                .locale(i18n.language === 'en' ? 'en' : 'zh-hk')
                .format(t('profile:membership.date_format')),
            });
          else expiryMessage = t('profile:menu.member_activate');
        }
      } else {
        // existing miro not renewed
        expiryMessage = t('profile:menu.member_expiry', {
          date: moment(user?.memberExpiresAt)
            .locale(i18n.language === 'en' ? 'en' : 'zh-hk')
            .format(t('profile:membership.date_format')),
        });
      }
    }
    return expiryMessage;
  }, [user, i18n.language]);
  const _renderContent = (isMobile) => {
    const isMemberNumberReady = user?.memberNo && user?.memberNo !== 'PENDING';
    return (
      <StyledMainDiv isMobile={isMobile} {...props}>
        <CommonText
          fontSize="30px"
          color={colors.black}
          {...(isMobile
            ? {}
            : { style: { width: '100%', textAlign: 'start' } })}
        >
          {/* If memberType is MIRO, show full name, else show username  */}
          {user?.memberType === 'MIRO'
            ? `${user?.firstName ? `${user?.firstName}` : ''} ${user?.lastName}`
            : user?.username}
        </CommonText>

        {user?.memberType === 'MIRO' && (
          <CommonLabel
            style={
              isMobile
                ? { width: '100%', fontSize: '14px', whiteSpace: 'pre-wrap' }
                : {
                    width: '100%',
                    fontSize: '14px',
                    textAlign: 'start',
                    whiteSpace: 'pre-wrap',
                  }
            }
          >
            {expiryMessage}
          </CommonLabel>
        )}

        {/* Menu upgrade */}
        <StyledMemberDiv isMobile={isMobile}>
          <Stack style={{ width: '100%' }} spacing={3}>
            {user?.renewPrice >= 0 && (
              <UpgradeButtonContainer isMobile={isMobile}>
                {!disableMiroReg && <UpgradeButton />}
              </UpgradeButtonContainer>
            )}
            {user?.memberType === 'MIRO' && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <MemberCard
                  {...(isMemberNumberReady ? {} : { idFontSize: '14px' })}
                  id={
                    expiryMessage === t('profile:menu.member_activate')
                      ? ''
                      : isMemberNumberReady
                      ? user?.memberNo
                      : t('profile:menu.member_number_preparing')
                  }
                  name={`${user?.firstName} ${user?.lastName}`}
                />
                {memberType === 'MIRO' && (
                  <MIRODayCounter
                    count={user.miroDayCount}
                    onClick={() => {
                      ReactGA.event('button_click', {
                        button_text: 'Day counter button',
                      });
                      setShowMIROCounterDialog(true);
                    }}
                  />
                )}
              </div>
            )}
          </Stack>
        </StyledMemberDiv>

        {isMobile && (
          <>
            <Space size="30px" />
            <CommonDivider />
          </>
        )}

        {/* 管理個人資料 */}
        <ProfileListItem
          onClick={() => {
            onClickMenu(ProfileMenuItem.PROFILE_INFO);
          }}
          highlighted={
            !isMobile && selectedMenu === ProfileMenuItem.PROFILE_INFO
          }
        >
          {t('profile:menu.mange_personal_info')}
        </ProfileListItem>

        {isMobile && <CommonDivider />}
        {/* 我的訂單 */}
        {user?.memberType !== 'TMP' && (
          <ProfileListItem
            onClick={() => {
              onClickMenu(ProfileMenuItem.PROFILE_ORDER);
            }}
            highlighted={
              !isMobile &&
              (selectedMenu === ProfileMenuItem.PROFILE_ORDER ||
                selectedMenu === ProfileMenuItem.PROFILE_ORDER_DETAIL)
            }
          >
            {t('profile:menu.my_order')}
          </ProfileListItem>
        )}

        {isMobile && <CommonDivider />}
        {/* 我的會籍 */}
        {user?.memberExpiresAt && (
          <ProfileListItem
            onClick={() => {
              onClickMenu(ProfileMenuItem.PROFILE_MEMBERSHIP);
            }}
            highlighted={
              !isMobile && selectedMenu === ProfileMenuItem.PROFILE_MEMBERSHIP
            }
          >
            {t('profile:menu.my_membership')}
          </ProfileListItem>
        )}
        {isMobile ? (
          <>
            <CommonDivider />
            <Space size="100px" />
          </>
        ) : (
          <Space size="40px" />
        )}
        {showMIROCounterDialog && (
          <MIRODayCounterDialog
            plus={false}
            count={user.miroDayCount}
            since={user.miroSince}
            onClose={() => setShowMIROCounterDialog(false)}
          />
        )}
        <LogoutContainer>
          <LogoutContainerMargin isMobile={isMobile}>
            <CommonButton
              normalCase={true}
              onClick={onClickLogout}
              type="outlined"
              style={{ width: '100%' }}
            >
              {t('profile:button.logout')}
            </CommonButton>
          </LogoutContainerMargin>
        </LogoutContainer>
      </StyledMainDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${breakpoint.desktop} {
    width: 340px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 438px;
  }
`;

const StyledMemberDiv = styled.div`
  display: flex;
  margin: 20px 0 20px 0;
  width: 100%;
  ${(props) => (props.isMobile ? 'justify-content: center;' : '')}

  @media ${breakpoint.mobile} {
    padding: 0 20px;
  }
`;

const LogoutContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LogoutContainerMargin = styled.div`
  width: 100%;
  ${(props) => (props.isMobile ? 'margin: 20px;' : '')}
`;

const UpgradeButtonContainer = styled.div`
  width: 100%;
`;
