import { useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4'
import styled from "styled-components";
import { AppContext } from "../../AppContext";
import { ReactComponent as CloseIcon } from "../../assets/nav_cross.svg";
import LoginRegisterButton from "../../components/common/buttons/LoginRegisterButton";
import { logout } from "../../utils/logout";
import { breakpoint } from "../../utils/responsive";

function JoinNavBar(props) {
  const { t } = useTranslation(["common", "profile", "menu"]);
  const { session, setSession, disableMiroReg } = useContext(AppContext);
  let navigate = useNavigate();
  const isDesktop = useMediaQuery(breakpoint.desktop);
  let { theme, handleClose, whiteMenu } = props;
  const login =
    session?.user?.memberType == "MIRO" || session?.user?.memberType == "WEB";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const username = session?.user?.username;

  function handleLogout() {
    setAnchorEl(null);
    logout({ navigate, setSession });
  }

  return (
    <div style={{ width: "100%", height: isDesktop ? 100 : 60 }}>
      <StyledMainDiv>

        <StyledRightDivWrapper>
          <StyledRightDiv>
            {!login && (
              <Link to={"/signin"} style={{ textDecoration: "none" }}>
                <LoginRegisterButton whiteMenu={whiteMenu} onClick={(e, { loginType }) => {
                  ReactGA.event('button_click', { param: loginType })
                }}></LoginRegisterButton>
              </Link>
            )}
            <CloseIcon
              style={{ cursor: "pointer", marginLeft: 24 }}
              onClick={() => navigate("/", { replace: true })}
            />
          </StyledRightDiv>
        </StyledRightDivWrapper>
      </StyledMainDiv>
    </div>
  );
}

const StyledMainDiv = styled.div`
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  z-index: 100;

  @media ${breakpoint.mobile} {
    min-height: 60px;
    padding-top: 10px;
    margin-bottom: 20px;
  }

  @media ${breakpoint.desktop} {
    min-height: 100px;
  }

  @media ${breakpoint.largeDesktop} {
    min-height: 130px;
  }
`;

const StyledRightDivWrapper = styled.div`

  display: flex;
  justify-content: flex-end;
  flex:1;
@media ${breakpoint.desktop} {
    margin-right: 20px;
  }

`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  transform: translateY(-50%);
  z-index: 9999;
  

`

const StyledLeftDiv = styled.div`
  color: #fff;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 12px */
  letter-spacing: 1.2px;


  @media ${breakpoint.desktop} {
    padding-left: 20px;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
`;

export default JoinNavBar;
