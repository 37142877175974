import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function WebTnc() {
    const { i18n } = useTranslation('');

    return (
        <div>
            {i18n.language === "en" ?
            // ----------------------ENGLISH VERSION----------------------
            <div style={{ textAlign: 'justify' }}>
                <StyledTitle>Website Terms and Conditions</StyledTitle>

                <StyledDiv>
                    <StyledP>This document contains terms and conditions (“Website Terms and Conditions”) that are applicable to your use of the website of the MIRROR Official Fan Club at <a href="https://www.mirrorweare.com">https://www.mirrorweare.com</a> (the “Site”). Please read these Website Terms and Conditions carefully before using the Site. Your usage of the Site or any part of it constitutes your acceptance of the Website Terms and Conditions (as amended from time to time). Please leave the Site immediately if you do not agree to be bound by the Website Terms and Conditions.</StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>1. Important Notice</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>The Site is operated by MakerVille Company Limited (“we”, “us” and “our” shall be construed accordingly), a member of the PCCW Media Group.
                        </StyledListItem>
                        <StyledListItem>We may update these Website Terms and Conditions from time to time. It is your responsibility to check for updates to these Website Terms and Conditions as they take effect upon posting on the Site because, unless otherwise required by Applicable Law, your continued use of the Site following such variations or amendments of these Website Terms and Conditions, whether or not perused by you, shall constitute your acceptance to be bound by such variations or amendments. “Applicable Law” in relation to any person, entity, action or thing means the following in relation to that person, action or thing: (i) any law, rule or regulation of any country (or political sub-division of a country); (ii) any obligation under any license in any country (or political sub-division of a country); and (iii) any lawful and binding determination, decision or direction of a regulator in any country (or political sub-division of a country).
                        </StyledListItem>
                        <StyledListItem>The Site and/or the products and services offered on the Site may be modified, updated, interrupted, suspended or discontinued at any time without notice, for any or no reason and without any liability to us.
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>2. Disclaimer </StyledSubTitle>

                    <StyledP>To the greatest extent permitted under law, we disclaim any and all responsibility and liability as follows:
                    </StyledP>

                    <StyledOrderList type="a">
                        <StyledListItem> 	Products and services offered on the Site are intended for users within Hong Kong only. Please note that accordingly we are not able to service requests specific to users outside of Hong Kong, including without limitation, the payment of goods in local currencies or the delivery of any goods to addresses outside of Hong Kong. "Hong Kong" means the Hong Kong Special Administrative Region of the People's Republic of China.
                        </StyledListItem>
                        <StyledListItem>  	Information and promotional offers in relation to any goods and/or services may be updated on the Site from time to time without prior notice to you. All information and promotional offers displayed on the site are based on the Hong Kong time zone, in accordance with the timer on our server.
                        </StyledListItem>
                        <StyledListItem>  	The information contained on the Site is for reference only. We do not warrant the accuracy, completeness, reliability or timeliness of the information in the Site for any reason whatsoever. We shall not be liable to you or any user for any costs, claims, liabilities, losses or damages whatsoever arising directly or indirectly from the use of or reliance on any such information.
                        </StyledListItem>
                        <StyledListItem>  	Use of the Site requires compatible devices and mobile data or Wi-Fi access. Your internet service provider may charge you for using mobile data or Wi-Fi. High-speed mobile data or Wi-Fi access is strongly recommended.
                        </StyledListItem>
                        <StyledListItem> 	We provide no warranty regarding any disconnection, delay, technical issue or loss of transmitted data. Further, we provide no warranty regarding non-infringement, security, fitness for purpose or freedom from computer viruses in connection with any information, photos, pictures or drawings on the Site or any other websites linked to or accessed via the Site, we accept no liability for any loss or damage howsoever arising from any use or access of the Site or any linked website or any reliance upon any Content of the Site or any linked website.
                        </StyledListItem>
                        <StyledListItem>  	To the extent permitted by the Applicable Law, we and/or any other party involved in creating, producing or delivering the Site on our behalf shall have no liability or any responsibility whatsoever for any direct, incidental, consequential, indirect, special or punitive claims, damages, costs, losses or liabilities whatsoever and howsoever arising out of or in connection with: (i) your access to, use, inability to use the Site or any other website you access through a link from the Site, (ii) change in any Content made available on or through the Site, or (iii) any actions we take or fail to take. "Content" includes things that you may see, read, hear, download or access on or via the Site (including but not limited to programming, dramas, movies, series, shows, user interface, features, functionalities, layout, messages, files, data, object codes, software, images, photographs, illustrations, text and other materials).
                        </StyledListItem>
                        <StyledListItem> 	We shall have no liability or any responsibility whatsoever for any loss suffered caused by viruses or other harmful component that may infect your computer equipment or other property by reason of your use of, access to or downloading of any material from the Site. If you choose to download material from the Site you do so at your own risk.
                        </StyledListItem>
                        <StyledListItem>  	There may be some Content which is not suitable for viewing by minors. Parents or guardians, as applicable, are responsible for checking the identity and/or age of any person who wishes or intends to view any such Content before you permit that person to view such Content. Users (and parents and guardians of users) of the Site acknowledge that they are fully aware of the potential risks that could arise under the foregoing circumstances, as (i) minors are permitted to register for an account or membership, and (ii) the Site is not enabled with any parental lock functions. 
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>


                <StyledDiv>
                    <StyledSubTitle>3. Usage of the Site</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>You must not:
                            <StyledOrderList type="i">
                                <StyledListItem>disrupt or interfere with the Site, or servers or network or other software, hardware or equipment connected to or via the Site;</StyledListItem>
                                <StyledListItem>restrict or inhibit any other persons from using the Site; including, without limitation, by means of “hacking” or defacing any portion of the Site;</StyledListItem>
                                <StyledListItem>modify, adapt, sub-license, reproduce, distribute, copy, exploit, translate, sell, reverse engineer, decompile or disassemble any portion of the Site and/or Content;</StyledListItem>
                                <StyledListItem>remove any copyright, trademark, or other proprietary rights notices contained in the Site;</StyledListItem>
                                <StyledListItem>copy and/or frame any part of the Site and/or Content without our prior written authorization;</StyledListItem>
                                <StyledListItem>use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, “data mine,” or in any way reproduce, disrupt or circumvent the navigational structure or presentation of the Site and/or the Content;</StyledListItem>
                                <StyledListItem>hack, break into, or attempt to hack or break into, in any manner the Site and/or Content and/or any data areas on our server(s) or that of any third parties;</StyledListItem>
                                <StyledListItem>download, copy, store, rip, distribute, share or re-direct any Content from the Site in any way or through any media;</StyledListItem>
                                <StyledListItem>incorporate any software or other materials that contain any virus, worm, time bomb, trojan horse, or other harmful or disruptive component into the Site and/or any Content;</StyledListItem>
                                <StyledListItem>forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Site;</StyledListItem>
                                <StyledListItem>engage in advertising or any commercial behaviour on the Site. You further agree not to conduct in any form of defamation, untrue, illegal, indecent, threatening, obscene, obscene, attacking or infringing on our website or other’s text, image or other form of documents on the Site;</StyledListItem>
                                <StyledListItem>use the Site for commercial or unlawful purpose, or any purpose other than your personal use or enjoyment;</StyledListItem>
                                <StyledListItem>use our name and/or the name of others and/or MIRROR Official Fan Club to spread false information in any way which may harm others' reputation or privacy, publish false, untrue or criminal information, spread pornography, obscenity, computer viruses, engage in any illegal or commercial infringement;</StyledListItem>
                                <StyledListItem>violate any Applicable Law relating to your use of the Site; and/or</StyledListItem>
                                <StyledListItem>collect personal data of other users of the Site.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>By choosing to register for an account or membership with us, you acknowledge that some post-registration services may be subject to additional terms and conditions. Where this is the case, those additional terms and conditions:
                            <StyledOrderList type="i">
                                <StyledListItem>will apply to your use of such post-registration services in addition to these Website Terms and Conditions; and</StyledListItem>
                                <StyledListItem>will prevail over these Website Terms and Conditions to the extent of any inconsistency.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>


                <StyledDiv>
                    <StyledSubTitle>4. Copyright and Trademark</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>The trademarks, trade names and logos displayed on the Site are owned by MakerVille Company Limited or are licensed to be used by us. These may not be used without the written consent of the applicable entity. You are granted no right or license with respect to any of the aforesaid trademarks, trade names and logo.</StyledListItem>
                        <StyledListItem>The Site and any information available on it, including but not limited to, text, graphics, icons, images, software, source code and trademarks, are protected by copyright, design, trademark and other intellectual property rights owned or controlled by us and/or our licensors. You must not copy, reproduce, republish, upload, download, post, transmit, store in retrieval system, modify, alter or display in public or distribute in any way the Contents or any part of the Site for any purpose without our prior written permission except that you may download materials displayed in the Site for non-commercial, home/personal use only as long as you keep all copyright, trademark and other proprietary rights notices intact.</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>5. Non-Active Account</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>If you have previously registered for a website account or MIRO membership, but failed to apply/renew your MIRO membership within our specified time, your account will be designated as a “Non-Active Account”. If you wish to enjoy exclusive MIRO benefits, please upgrade to a MIRO membership.
                            <br/><br/>
                            Successful applicants will have the opportunity to enjoy the following MIRO benefits:
                            <StyledUnorderList>
                                <StyledListItem>2024 MIRO Gift Pack</StyledListItem>
                                <StyledListItem>Ticket reservation for designated events*^<br/>Including MIRROR concert tour, individual members’ concerts and MIRROR anniversary event</StyledListItem>
                                <StyledListItem>Participation in MIRO special events*^<br/>Including MIRROR and individual member fan meetings, priority screening events for movies, ballots for tickets of MIRROR and individual member related concerts or events</StyledListItem>
                                <StyledListItem>Participation in {"<<"}Hi MIRO! SEE U ZOOM Online Fan Meet{">>"}</StyledListItem>
                                <StyledListItem>Access to purchase limited edition merchandise of MIRROR*</StyledListItem>
                                <StyledListItem>Access and download MIRROR exclusive media content in our dedicated MIRO zone</StyledListItem>
                            </StyledUnorderList>  
                            * Subject to availability, quantity allocated by event organiser and/or confirmation of event
                            <br/><br/>
                            ^ Event may be subject to age restriction
                        </StyledListItem>
                        <StyledListItem>You:
                            <StyledOrderList type="i">
                                <StyledListItem>will be strictly and solely responsible for the use of your Non-Active Account and any use of the Site by any party who uses your login details to your Non-Active Account will be treated by us as usage by you;</StyledListItem>
                                <StyledListItem>shall safeguard your login details and password(s) and ensure that they are not disclosed or provided to any other person;</StyledListItem>
                                <StyledListItem>agree not to disclose your login details and password or permit other persons to use your login details and password to access your Non-Active Account and/or any Content;</StyledListItem>
                                <StyledListItem>authorize us to assume that any person using any Site with your username and password is either you or authorized by you to do so; and</StyledListItem>
                                <StyledListItem>You can apply/renew your MIRO membership within the specified time. If you wish to terminate your Non-Active Account, please notify us via email at cs@mirrorweare.com.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>We shall not be liable for any loss or damages suffered arising from the loss, theft or unauthorized use of your log-in details and/or password.</StyledListItem>
                        <StyledListItem>You accept that the use of the Non-Active Account is personal to you. You agree not to transfer your rights and obligations under your Non-Active Account as specified under these Website Terms and Conditions to anyone else, or to try to do so without our prior written consent. However, we may take instructions from a person who we think, with reasonable reason, is acting with your permission.</StyledListItem>
                        <StyledListItem>In addition to our rights stated under other provisions stated under these Website Terms and Conditions, we may limit or suspend your Non-Active Account with or without notice, where we are of the opinion that such action is appropriate as a result of your use of the Site or in the event that you breach any of terms stated under these Website Terms and Conditions and/or other terms applicable to your use of the Site.</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>6. Personal Data</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>We collect, process, disclose, retain and use your Personal Data (defined below) in accordance with the Personal Information Collection Statement (<a href="/privacy-terms">hyperlink</a>) and the PCCW Group Privacy Statement (<a href="http://www.pccw.com/privacy-statement/index.page?locale=en">http://www.pccw.com/privacy-statement/index.page?locale=en</a>) (collectively the “Privacy Terms”). Please refer to the Privacy Terms which state about how we use, share, store and process your Personal Data (as defined in the Personal Data (Privacy) Ordinance Cap 486 of Hong Kong).</StyledListItem>
                        <StyledListItem>By providing your Personal Data to us, you authorize us to collect, retain and use Personal Data for the purposes stated in the Privacy Terms and, more specifically, for the following purposes:
                            <StyledOrderList type="i">
                                <StyledListItem>operating, processing, maintaining and/or managing your Non-Active Account and/or MIRO membership, as applicable;</StyledListItem>
                                <StyledListItem>verifying your identity in connection with any products and/or services that we may provide you;</StyledListItem>
                                <StyledListItem>providing to you goods and services offered by MIRROR Official Fan Club, the Site and MIRROR Official Fan Club’s partners;</StyledListItem>
                                <StyledListItem>providing you with customer service and responding to your queries, requests, feedback or claims;</StyledListItem>
                                <StyledListItem>for payment purposes of the products or services on the Site; </StyledListItem>
                                <StyledListItem>using your Personal Data to compile aggregated data for performing research or statistical analysis in order to improve our products and services;</StyledListItem>
                                <StyledListItem>with your consent, for marketing and promoting product and services as stated in the <a href="/privacy-terms">Personal Information Collection Statement</a></StyledListItem>
                                <StyledListItem>establishing, maintaining and/or running any subsidiary or ancillary clubs/programs under MIRROR Official Fan Club (whether or not such clubs/programs may bear the words “MIRROR Official Fan Club” in their name); and</StyledListItem>
                                <StyledListItem>making such disclosures as may be required for any of the above purposes or as required by law or in respect of any feedback or claims made by you; and for any other purposes which you may from time to time agree.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>When necessary, we may also disclose and transfer (whether in Hong Kong or abroad) your Personal Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations for purposes specified in Clause 6(a) above.</StyledListItem>
                        <StyledListItem>We may only use your name, phone number and email address with your consent or with your indication of no objection to provide marketing materials to you relating to the Site and/or your Non-Active Account as well as goods and services which may be redeemed under the Site and/or your Non-Active Account, including goods and services provided by MIRROR Official Fan Club.</StyledListItem>
                        <StyledListItem>Any Personal Data which we receive about you will be retained by us and will be accessible by persons and parties stated in the Privacy Terms.</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>7. Cookies</StyledSubTitle>

                    <StyledP>Our website uses cookies or similar tracking tools on your machine or device in order for us to maintain your identity across multiple webpages and/or Internet sessions; we also use your Personal Data or data (for instance, via Google Analytics) to compile aggregated data for performing research or statistical analysis in order to improve our products and services. We do not otherwise collect your cookies or other online identifiers, whether for the purposes of personalising your user experience, monitor your behavior, predict your preferences or otherwise. Our website is initially set up to accept cookies. You can opt-out of or delete historical cookies by changing the settings on your web or device browsers; however, if you do so, you may find that certain features on our website are unable to function properly. With respect to Google Analytics, if you want to deactivate Google Analytics or opt-out, please download and install the add-on for your web browser provided by Google at http://tools.google.com/dlpage/gaoptout?hl=en; through such link, you may learn more about the opt-out and how to properly install the browser add-on, and also the Google Analytics Privacy Policy.
                    </StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>8. Security System</StyledSubTitle>

                    <StyledP>We take practically reasonable effort by employing a variety of protective measures, including encryption and verification tools to protect visitor information. For visitors' product orders, we use secure servers. All credit card information and payment-related information provided by visitors will be transmitted through Security Sockets Layer (SSL) technology and encrypted in our database.
                    </StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>9. Account Termination and Access Restriction</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>We can limit, suspend, end or restrict your access to your Non-Active Account (and/or your MIRO membership, if applicable), without giving you advance notice (provided that it is reasonably practicable to do so) if:</StyledListItem>
                        <StyledOrderList type="i">
                            <StyledListItem>the law requires us to do so;</StyledListItem>
                            <StyledListItem>there is an emergency that affects our ability to provide the Site and/or Content;</StyledListItem>
                            <StyledListItem>any Content is withdrawn from distribution through the Site for whatever reason;</StyledListItem>
                            <StyledListItem>we no longer hold the right to distribute the Site or any Content;</StyledListItem>
                            <StyledListItem>we fail to obtain, hold, retain or renew any relevant regulatory approvals or if any relevant regulatory approvals are revoked or if we are required by a relevant authority to cease operation of the Site or transmission of the  Content; or</StyledListItem>
                            <StyledListItem>the Site and/or Content including any advertising therein fails to comply with any regulatory standards, relevant laws or any requirements or orders issued from time to time by a relevant authority.</StyledListItem>
                        </StyledOrderList>
                        <StyledListItem>In addition to our rights under Clause 9(a):</StyledListItem>
                        <StyledOrderList type="i">
                            <StyledListItem>We reserve the right in our sole and absolute discretion to terminate your Non-Active Account (and/or your MIRO membership, if applicable) and delete relating rights and benefits, and restrict your use of all or any part of the Site for any or no reason, without notice, and without liability to you or anyone else. </StyledListItem>
                            <StyledListItem>We reserve the right in our sole and absolute discretion to block visitors from certain IP addresses and prevent their access to the Site, and we shall not be obliged to provide reasons thereof or notice.</StyledListItem>
                        </StyledOrderList>
                        <StyledListItem>Upon termination of your Non-Active Account and/or MIRO membership (if applicable), all licenses, rights and privileges granted to you shall cease. Any such termination shall not affect the applicability of these Website Terms and Conditions (which applies as long as you are using and/or accessing the Site) and all accrued rights or liabilities of each party hereto, nor shall termination affect the coming into force or the continuance in force of any provision contained in these Website Terms and Conditions which is expressly or by implication intended to come into or continue in force on or after such termination.</StyledListItem>
                        <StyledListItem>Even if your access is barred or that your Non-Active Account and/or MIRO membership (if applicable) is terminated, you irrevocably give us perpetual right to continue to host on the Site Content generated by you and uploaded on the Site. </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>10. Links to third party apps, websites or webpages</StyledSubTitle>

                    <StyledP>The Site may contain links to other apps, websites and/or webpages which are operated by third parties. We have no control over the Content of the linked apps, websites and/or webpages or the way in which the operators of those apps, websites and/or webpages deal with your personal data. We shall not be responsible for the Content or accuracy of the linked apps, websites and/or webpages operated by third parties. You should review the terms and conditions/privacy policy for those third party apps, websites and/or webpages to understand the ways in which your personal data may be used by those third parties and we disclaim all responsibility and liability for any loss or damage that may be suffered by you in connection with the linked apps, websites and/or webpages. The inclusion of any link does not imply that we endorse the linked site, and you use the links at your own risk.</StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>11. Limitation of Liability and Indemnity</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>
                            To the fullest extent permitted by Applicable Law, we will under no circumstances:
                            <StyledOrderList type="i">
                                <StyledListItem>accept any liability whatsoever for any incidental, consequential or indirect damages, loss or corruption of data, loss of profits, goodwill, bargain or opportunity or loss of anticipated savings or any other loss resulting in connection with the Site or its Content, or any goods or services supplied and/or provided by us, whether based on warranty, contract, tort, negligence or any other legal theory, and whether or not we know of the possibility of such damage; and</StyledListItem>

                                <StyledListItem>compensate more than Hong Kong One Hundred Dollars (HK$100) (even if we have been negligent) for any liabilities in connection with the Site or its Content, or any goods or services supplied and/or provided by us.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>

                        <StyledListItem>
                            You are obligated to indemnify us, our affiliates, agents, directors, officers, employees, Content providers, sub-contractors, licensors and/or agents against any actions, liability, costs, claims, losses, damages, proceedings and/or expense arising from, in connection with or which is related to:

                            <StyledOrderList type="i">
                                <StyledListItem>your use or others’ use of the Site and/or its Content through your devices and/or Non-Active Account; and</StyledListItem>
                                <StyledListItem>any breach or non-observance of any of these Website Terms and Conditions by you.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>12. 	Notices </StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>Subject to the Applicable Law, we will give you any necessary notices by posting them on the Site. You agree:
                            <StyledOrderList type="i">
                                <StyledListItem>to check the Site for notices; and</StyledListItem>
                                <StyledListItem>that you will be considered to have received a notice when it is posted on the Site.</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>13. 	General Provisions</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>If any provisions of these Website Terms and Conditions shall be construed to be illegal, invalid or unenforceable for any reason, such provisions shall not affect the legality, validity or enforceability of the other provisions of these Website Terms and Conditions. The illegal, invalid or unenforceable provision shall be amended to the extent necessary to make them legal, valid or enforceable or, if not possible to do so, deleted from these Website Terms and Conditions, but all other provisions of these Website Terms and Conditions shall continue in full force and effect.</StyledListItem>
                        <StyledListItem>The governing law of and exclusive forum for dispute resolution relating to these Website Terms and Conditions shall be Hong Kong law and Hong Kong courts. </StyledListItem>
                        <StyledListItem>Our failure to enforce any rights or provisions under these Website Terms and Conditions shall not constitute a waiver of such or any other provision.</StyledListItem>
                        <StyledListItem>We shall not be liable or responsible for any failure to fulfil any of our obligations due to causes beyond our reasonable control.</StyledListItem>
                        <StyledListItem>Should there be any inconsistencies between the English version and the Chinese version of these Website Terms and Conditions, the English version shall prevail.</StyledListItem>
                        <StyledListItem>Subject to Applicable Law, we can transfer our rights and obligations hereunder to any person or entity without your consent. We can also use an agent or subcontractor to perform our responsibilities.</StyledListItem>
                        <StyledListItem>A person or entity who is not a party to these Website Terms and Conditions shall have no right under the Contracts (Rights of Third Parties) Ordinance (Chapter 623 of the laws of Hong Kong) or any similar legislation in any jurisdiction to enforce any part of these Website Terms and Conditions.</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

            </div>

            :
            // ----------------------CHINESE VERSION----------------------
            <div style={{ textAlign: 'justify' }}>
                <StyledTitle>網站條款及條件</StyledTitle>

                <StyledDiv>
                    <StyledP>本文包含的條款及條件(「網站條款及條件」)適用於閣下使用MIRROR Official Fan Club的網站<a href="https://www.mirrorweare.com">https://www.mirrorweare.com</a> (「本網站」)。請於使用本網站前仔細閱讀這些網站條款及條件。閣下使用本網站或其任何一部分即代表閣下接受本網站條款及條件（可能會不時更改）。如閣下不同意受本網站條款及條件的約束，請立即離開本網站。</StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>1. 重要聲明</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>本網站是由MakerVille Company Limited ，為電訊盈科媒體集團旗下成員（下統稱「我們」）營運。
                        </StyledListItem>
                        <StyledListItem>我們會不時更新本網站條款及條件。本網站條款及條件的更新於本網站公佈後即時生效，閣下有責任查閱有關更新內容，因為閣下於有關條款及條件更改或修訂後繼續使用該等網站，無論閣下有否細閱有關更改或修訂，均構成閣下接受有關更改或修訂的約束，除非適用法律另有規定，則另當別論。「適用法律」指就任何人士、機構、行動或事宜而言，與該人士、行為或事情有關的以下各項：(i) 任何國家（或國家之政治分區）的任何法律、法則或法規；(ii) 任何國家（或國家之政治分區）的任何特許文件所規定的任何責任及義務；及(iii) 任何國家（或國家之政治分區）的監管機構的任何合法及具約束力的裁決、決定或指引。
                        </StyledListItem>
                        <StyledListItem>本網站及/或本網站上提供的產品及服務可能，基於任何原因或無需任何原因，隨時修改、更新、中斷、暫停或停止，我們恕不另行通知或負責。
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>2. 免責聲明 </StyledSubTitle>

                    <StyledP>於法律允許的最大範圍內，我們就下述不負擔任何及全部責任：
                    </StyledP>

                    <StyledOrderList type="a">
                        <StyledListItem>本網站提供的商品及服務僅限於香港的使用者。因此請注意我們不能為香港以外的使用者提出的要求提供服務， 包括但不限於以閣下當地貨幣為商品付款或向香港以外的地址寄送任何商品。 「香港」是指中華人民共和國香港特別行政區。
                        </StyledListItem>
                        <StyledListItem>本網站上有關任何產品及/或服務的資料及促銷優惠可能會不時更新，且不會另行提前通知閣下。本網站顯示的任何資料或促銷優惠的有效期以我們服務器的計時及香港時間為準。
                        </StyledListItem>
                        <StyledListItem>在本網站顯示的資料僅供參考用途，我們並不會基於任何原因保證網站上資料的準確性、完整性、可靠性或及時性。閣下或任何用戶因使用或依賴資料所引起直接或間接的費用、申索、責任、損失或損害等，我們一概不負責。
                        </StyledListItem>
                        <StyledListItem>使用本網站是需要相容設備及流動數據服務或Wi-Fi無線網絡，閣下的互聯網服務供應商可能因該使用流動數據服務或Wi-Fi無線網絡而收取閣下費用，我們建議閣下在應用本網站時使用速度較快的流動數據服務或Wi-Fi無線網絡。
                        </StyledListItem>
                        <StyledListItem>我們並不保證本網站能時常可使用並不會被中斷或不會有任何延誤、技術故障或丟失任何傳送中的訊息。我們亦對關於任何於本網站內或本網站連結的或經本網站訪問的任何其他網站的資料、照片、圖片或繪圖之不侵權、保安、於某用途的適用性或不受電腦病毒影響等不作出保証，如因使用或進入本網站或任何連結網站或依據本網站或任何連結網站的內容而招致任何損失或損害，我們概不負上任何責任。
                        </StyledListItem>
                        <StyledListItem>就 (i) 閣下登入、使用、未能使用本網站或經本網站中連結登入的任何其他網站、(ii)本網站內容的更改；或 (iii) 我們採取或未採取的任何行動而引致的任何直接、附帶、相應、間接、特別或懲罰性損害、成本、損失或責任，在法律允許的範圍內，我們及/或代表我們參與創建、製作或提供本網站的任何其他方在任何情況下均毋須承擔責任或負責。「內容」包括閣下在或透過本網站觀看、閱讀、收聽、下載或訪問的內容（包括但不限於節目、戲劇、電影、劇集、表演、用戶介面、特點、功能、設計、訊息、文件、數據、目標代碼、軟件、圖像、照片、插圖、文本及其他材料）。
                        </StyledListItem>
                        <StyledListItem>就因閣下於本網站使用、登入或下載資料而導致可能影響閣下電腦設備或其他財產的病毒而遭受的任何損失，我們不承擔任何責任或負責。如果閣下選擇於本網站下載資料，須自負風險。
                        </StyledListItem>
                        <StyledListItem>有些內容可能包含一些不適合未成年人觀看的內容。家長或監護人（如適用）有責任在允許任何希望或有意觀看任何該等內容的人士觀看該等有關內容之前，檢查其身份及╱或年齡。本網站的使用者（及使用者的家長及監護者）聲明其完全明白前述情況下可能引起的風險即使：(i)未成年人可於本網站註冊帳戶或會員身份，及(ii) 本網站未設任何家長鎖定功能。
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>


                <StyledDiv>
                    <StyledSubTitle>3. 本網站的使用</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>閣下不得：
                            <StyledOrderList type="i">
                                <StyledListItem>擾亂或干擾本網站或服務器或網絡或其他軟件、或連接至本網站或經過本網站連接的設備或硬件；</StyledListItem>
                                <StyledListItem>限制或阻止其他人士使用本網站，包括但不限於「非法入侵」或破壞本網站的任何部分；</StyledListItem>
                                <StyledListItem>對本網站及/或內容的任何部分進行修改、改編、再授權、複製及重現、分發、複製、利用、翻譯、銷售、逆向工程、解編或拆解；</StyledListItem>
                                <StyledListItem>删除本網站及/或內容所包含的任何版權、商標或其他所有權聲明；</StyledListItem>
                                <StyledListItem>在未經我們事先書面授權的情况下，複製及/或提取本網站及/或內容的任何部分；</StyledListItem>
                                <StyledListItem>使用任何機械人、蜘蛛程序、網站搜尋/檢索應用程序，或其他手動或自動裝置或程序檢索、編制索引、「挖掘數據」，或以任何方式複製及重現、擾亂或規避本網站及/或內容的導航結構或呈列方式；</StyledListItem>
                                <StyledListItem>以任何方式非法入侵、闖入或企圖以任何方式非法入侵或闖入本網站、內容及/或我們或任何第三方服務器上的任何數據區；</StyledListItem>
                                <StyledListItem>以任何方式或經任何媒介，下載、複製、儲存、翻錄、分發、分享或再指引本網站的任何內容；</StyledListItem>
                                <StyledListItem>在本網站及/或任何內容加入含有任何病毒、蠕蟲、計時炸彈、特洛伊木馬或其他有害或具破壞性組件的任何軟件或其他物料；</StyledListItem>
                                <StyledListItem>僞造標題或以其他方式操控標識符以掩飾透過本網站傳送的任何內容之來源；</StyledListItem>
                                <StyledListItem>於本網站從事廣告或任何商業性質之行為，及進一步同意不會對本網站或本網站上他人之文字、圖像或其他形式的檔案上以任何形式進行譭謗、不實、不法、不雅、威脅、猥褻、具攻擊性或侵害。</StyledListItem>
                                <StyledListItem>爲著商業或不法目的、或其他非爲閣下個人使用或享用之目的，使用本網站；</StyledListItem>
                                <StyledListItem>冒用我們, MIRROR Official Fan Club 及/或其他人士之名義，以任何途徑散播可能會妨害他人名譽或私隱的不實消息、發表虛假、不實或犯罪的資訊，傳播色情、猥褻、電腦病毒、從事任何非法或商業侵權行為；</StyledListItem>
                                <StyledListItem>違反任何有關閣下使用本網站的適用法律；及/或</StyledListItem>
                                <StyledListItem>收集本網站其他用戶的個人資料。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>如果閣下選擇於本網站註冊帳戶或會員身份，閣下確認閣下使用一些註冊帳戶或會員身份後才能使用的功能時，可能受到附加條款及條件規管。在此情況下，這些額外條款及條件：
                            <StyledOrderList type="i">
                                <StyledListItem>將適用於閣下（除了本網站條款及條件外）就該等註冊帳戶或會員身份後才能使用的功能的使用；及</StyledListItem>
                                <StyledListItem>倘若與本網站條款及條件不一致，將以該等附加條款及條件為準。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>


                <StyledDiv>
                    <StyledSubTitle>4. 版權與商標</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>本網站所顯示的商標、商號和標誌均由MakerVille Company Limited擁有（或我們被許可使用）。未經相關機構的書面同意，不可使用前述商標、商號和標誌。就任何前述商標、商號及標誌，閣下並未被授予任何權利或許可。</StyledListItem>
                        <StyledListItem>本網站及其所載的任何資料，包括但不限於文字、圖像、圖標、影像、軟件、原始代碼及商標等均受我們及/或其特許人擁有或控制的版權、外觀設計、商標及其他知識產權保護。未經我們事先書面同意，閣下不得將本網站的內容或其任何部份，為任何目的，複製、重製、再版、上載、下載、刊登、傳送、儲存於某一檔案復原系統、更改、改變或公開展示或以任何方式分發。閣下可為非商業、住家或個人用途而下載本網站展示的資料，但閣下必須保持所有版權、商標及其他所有權之聲明完整。</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>5. 非活躍帳戶</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>若閣下曾經註冊網站帳戶或 MIRO 會籍，但沒有在指定的時間內申請 / 續期 MIRO 會籍，則閣下的帳戶將被設為「非活躍帳戶」。如果閣下希望獲取MIRO專屬禮遇，請於指定的時間內申請/或續期至MIRO會籍。
                            <br/><br/>
                            成功申請者有機會尊享以下MIRO會員福利:
                            <StyledUnorderList>
                                <StyledListItem>2024 MIRO會員禮盒一份</StyledListItem>
                                <StyledListItem>預訂指定活動門票*^<br/>包括：MIRROR 巡迴演唱會、個別成員演唱會、MIRROR周年活動等</StyledListItem>
                                <StyledListItem>參加MIRO尊屬活動*^<br/>包括：MIRROR見面會、MIRROR個別成員見面會、電影優先場、MIRROR及個別成員相關音樂會或活動門票抽籤等</StyledListItem>
                                <StyledListItem>參加《Hi MIRO! SEE U ZOOM 網上見面會》</StyledListItem>
                                <StyledListItem>尊享訂購MIRROR限定精選商品*</StyledListItem>
                                <StyledListItem>於MIRO專區瀏覽及下載MIRROR獨家影音</StyledListItem>
                            </StyledUnorderList>  
                            * 視乎供應，活動主辦方分配的數量及/或待活動確認
                            <br/><br/>
                            ^ 活動可能設有年齡限制
                        </StyledListItem>
                        <StyledListItem>閣下：
                            <StyledOrderList type="i">
                                <StyledListItem>將嚴格和完全負責閣下非活躍帳戶的使用，任何人士使用閣下的登入資料登入閣下的非活躍帳戶使用本網站將被我們視為閣下使用本網站；</StyledListItem>
                                <StyledListItem>應妥善保管閣下的登入資料及密碼，並確保不會披露或提供予任何其他人士；</StyledListItem>
                                <StyledListItem>同意不披露閣下的登入資料及密碼，或允許其他人士使用閣下的登入資料及密碼訪問閣下非活躍帳戶及／或任何內容；及</StyledListItem>
                                <StyledListItem>授權我們假設任何以閣下的用戶名稱及密碼使用任何本網站的人士是閣下或已獲得閣下授權如此行事。</StyledListItem>
                                <StyledListItem>可於指定的時間內申請/或續期MIRO會籍。若閣下希望終止非活躍帳戶，閣下可透過電郵cs@mirrorweare.com通知我們。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>我們就因閣下登入資料及/或密碼的遺失、被竊或未經許可的使用遭受的任何損失或損害不承擔任何責任。</StyledListItem>
                        <StyledListItem>閣下接受，非活躍帳戶的使用屬於閣下本人事務。閣下同意不會在未經我們事先書面同意的情況下將閣下於本網站條款及條件所列及非活躍帳戶下的權利及義務轉讓予任何其他人士，或試圖如此行事。然而，我們可按我們有合理理由認為該等人士的行為已獲得閣下許可而依其指示行事。</StyledListItem>
                        <StyledListItem>除本網站條款及條件所述的我們的權利外，我們還可在給予或不給予通知的情況下限制或暫停閣下的非活躍帳戶（倘我們認為因閣下使用本網站而限制或暫停閣下的非活躍帳戶乃屬適當或倘閣下違反本網站條款及條件及/或其他適用於閣下使用本網站的條款及條件）。</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>6. 個人資料</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>我們按照<a href="/privacy-terms">個人資料收集聲明</a>及電訊盈科集團私隱聲明（<a href="http://www.pccw.com/privacy-statement/index.page?locale=zh">http://www.pccw.com/privacy-statement/index.page?locale=zh</a>）（統稱「私隱條款」）收集、處理、披露、保留及使用閣下的個人資料（定義見下文）。請參見私隱條款，當中載有有關我們如何使用、分享、儲存及處理閣下的個人資料（定義見香港法例第486章《個人資料（私隱）條例》）的資訊。</StyledListItem>
                        <StyledListItem>當閣下提供閣下的個人資料予我們，即視作閣下允許我們收集、保留及使用閣下之個人資料用於私隱條款中所載之目的，及具體下列目的：
                            <StyledOrderList type="i">
                                <StyledListItem>營運、處理、維持及/或管理閣下的非活躍帳戶及/或MIRO帳戶，如適用；</StyledListItem>
                                <StyledListItem>就有關我們可能向閣下提供的任何產品及/或服務核實閣下的身份</StyledListItem>
                                <StyledListItem>向閣下提供由我們、本網站及我們之合作商提供的產品及服務；</StyledListItem>
                                <StyledListItem>提供客戶服務予閣下及回覆閣下的查詢、要求、意見或索賠；</StyledListItem>
                                <StyledListItem>用於本網站上産品或服務的付款目的；</StyledListItem>
                                <StyledListItem>為改進我們產品及服務，使用閣下個人資料進行編輯統計數據以進行研究或統計分析；</StyledListItem>
                                <StyledListItem>經閣下同意，用於個人資料收集聲明（<a href="/privacy-terms">連結</a>）中所載之營銷及推廣商品及服務；</StyledListItem>
                                <StyledListItem>建立、維持及/或營運任何我們的附屬或輔助會所或活動（無論該等會所或活動是否帶有「MIRROR Official Fan Club」字樣）；及</StyledListItem>
                                <StyledListItem>基於上述目的的要求或法律的要求或閣下的任何反饋或索賠，及閣下可能不時同意的其他任何目的所做的該等披露。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                        <StyledListItem>如有必要、我們可能會向我們的專業顧問、執法機關、保險公司、政府及監管機構及其他組織基於上述第6條(a)中所列的相關目的披露或提供（無論是香港境內或境外）閣下的個人資料。</StyledListItem>
                        <StyledListItem>經閣下同意或閣下未表示反對，我們可能僅使用閣下的姓名、電話號碼及電郵地址以向閣下提供有關本網站及/或閣下非活躍帳戶，及本網站及/或閣下非活躍帳戶中換領的商品及服務（包括由MIRROR Official Fan Club提供的商品及服務）。</StyledListItem>
                        <StyledListItem>我們收到有關閣下的任何個人資料將由我們保留及將會被私隱條款中所載之個人及各方使用。</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>7. 曲奇</StyledSubTitle>

                    <StyledP>我們的網站會在閣下的電腦或裝置中使用 cookies (小型文字檔案)或類似的追蹤工具，以便我們在多個網頁和/或互聯網段落中保留閣下的足跡；我們亦會使用閣下的個人資料或資料來彙集（例如通過Google Analytics）群組數據以進行研究或統計分析，以改進我們的產品和服務。我們不會以其他方式收集閣下的 cookies 或其他線上識別碼（無論是用於個性化閣下的用戶體驗、監控閣下的行為、預測閣下的偏好或其他目的）。我們的網站在最初會設定為接受 cookies。閣下可以透過更改閣下網頁或裝置瀏覽器的設定選擇拒絕或刪除 cookies 的歷史記錄；但是，閣下在更改有關設定後，閣下可能會發現本網站的某些功能會不能正常運作。有關Google Analytics， 如果閣下想停用或拒絕 Google Analytics，請於閣下的網頁瀏覽器下載及安裝Google在  <a href="http://tools.google.com/dlpage/gaoptout?hl=en"> http://tools.google.com/dlpage/gaoptout?hl=en </a>上提供的附加元件(add-on)。透過該連結，閣下可以了解更多有關拒絕及如何適當安裝瀏覽器附加元件，及Google Analytics私隱政策。
                    </StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>8. 安全系統</StyledSubTitle>

                    <StyledP>我們致力於使用實際合理的措施，即通過採取多種保護方法(包括加密和驗證工具)來保護訪客的信息。對於訪客的產品訂單，我們使用安全的伺服器。所有訪客提供的信用卡信息和與付款相關的信息，將被通過安全通訊端層（SSL）技術傳輸並在我們的資料庫中加密。
                    </StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>9. 終止帳戶及登入限制</StyledSubTitle>

                    <StyledOrderList type="a">
                        <StyledListItem>倘屬以下情況，倘屬合理可行，我們可在不給予閣下事先通知的情況下限制、暫停、終止或約束閣下訪問閣下的非活躍帳戶（及/或閣下的MIRO會籍，如適用）：</StyledListItem>
                        <StyledOrderList type="i">
                            <StyledListItem>法律要求我們如此行事；</StyledListItem>
                            <StyledListItem>發生緊急情況影響我們提供本網站及／或內容的能力；</StyledListItem>
                            <StyledListItem>任何內容因任何原因而被撤銷透過本網站發行；</StyledListItem>
                            <StyledListItem>我們不再擁有發行本網站或任何內容的權利；</StyledListItem>
                            <StyledListItem>我們未能獲得、持有、保留或重續任何相關監管批准，或倘任何相關監管批准被撤銷，或倘相關當局要求我們停止營運本網站、傳輸內容；或</StyledListItem>
                            <StyledListItem>本網站及／或內容（包括當中的任何廣告）未能遵守任何監管標準、相關法律或相關當局不時發佈的任何規定或命令。</StyledListItem>
                        </StyledOrderList>
                        <StyledListItem>除第9條(a)中規定的我們的權利之外：</StyledListItem>
                        <StyledOrderList type="i">
                            <StyledListItem>我們全權保留終止閣下的非活躍帳戶（及/或閣下的MIRO會籍（如適用））及刪除相關權利及禮遇、及限制閣下使用本網站的所有或任何部分的權利，並且無需給予任何理由及通知，及不對閣下或任何人承擔任何形式的責任。</StyledListItem>
                            <StyledListItem>我們全權保留阻止來自特定的IP位址的訪問者及阻止其登陸本網站的權利，及我們將無義務為此提供理由及通知。</StyledListItem>
                        </StyledOrderList>
                        <StyledListItem>於閣下的非活躍帳戶及/或MIRO會籍（如適用）終止後，授予閣下的所有許可、權利及特權亦告終止。任何有關終止概不影響本網站條款及條件的適用性（只要閣下使用及／或訪問本網站）及各有關方的既有權利或責任，亦不影響本網站條款及條件中明示或暗示於有關終止之時或之後擬生效或繼續生效的任何條款的生效或繼續生效。</StyledListItem>
                        <StyledListItem>即使閣下被禁止訪問本網站或閣下的非活躍帳戶及/或MIRO會籍（如適用）被終止，閣下給於我們（不可撤銷）永久的繼續管理閣下於本網站產生或上傳的內容。</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>10. 第三方網站連結程式、網站或網頁</StyledSubTitle>

                    <StyledP>本網站可能載有通往其他由第三方所運作的應用程式、網站及/或網頁的連結。我們沒有權控制該等連結應用程式、網站及/或網頁的內容或該等連結應用程式、網站及/或網頁的營運者處理閣下個人資料的方法。我們就第三方運營的、連結的應用程序、網站及/或網頁上的內容或其準確性不承擔任何責任。閣下應檢閱該等第三方應用程式,網站及/或網頁之私隱政策及/或條款及條件，以瞭解該等第三方可能如何使用閣下之個人資料。我們就閣下因該等連結應用程式、網站及/或網頁而遭受的任何損失或損害不承擔責任及負責。我們提供的任何連結並不代表我們推薦該等網站，閣下使用此等連結需自負風險。</StyledP>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>11. 責任限制及彌償</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>
                            在適用法律允許的最大範圍內，我們在任何情況下概不會：
                            <StyledOrderList type="i">
                                <StyledListItem>就有關本網站或其內容或我們所供應及╱或提供的任何產品或服務下而引起的任何附帶引起、相應或間接的損害、數據損失或損壞，利潤、商譽、優惠買賣或機會的損失，或預期節省的損失，或任何其他損失而承擔任何責任，不論是否基於保證、合約、侵權、疏忽或任何其他法律理論，也不論我們是否獲悉該等損害的可能性；及</StyledListItem>

                                <StyledListItem>就有關本網站或其內容或我們所供應及╱或提供的任何產品或服務下的任何責任補償超過港幣一百元（港幣100元）（即使我們有所疏忽）。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>

                        <StyledListItem>
                            閣下有責任就因以下各項所產生、與之相關或有關的任何訴訟、法律責任、費用、賠償、損失、損害、法律程序及╱或開支向我們、我們的聯屬公司、代理、董事、高級職員、僱員、內容供應商、分包商、許可人及╱或代理作出彌償：

                            <StyledOrderList type="i">
                                <StyledListItem>閣下或其他人透過閣下的設備及╱或帳戶使用本網站及╱或內容；及</StyledListItem>
                                <StyledListItem>閣下違反或不遵守本網站條款及條件的任何條款。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>12. 通知 </StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>在適用法律的規限下，我們將透過在本網站公佈通知的方式向您發送任何必要的通知。閣下同意：
                            <StyledOrderList type="i">
                                <StyledListItem>查看本網站上的通知；及</StyledListItem>
                                <StyledListItem>當有關通知公佈於本網站時，即視為閣下已收到通知。</StyledListItem>
                            </StyledOrderList>
                        </StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

                <StyledDiv>
                    <StyledSubTitle>13. 一般條款</StyledSubTitle>
                    <StyledOrderList type="a">
                        <StyledListItem>倘本網站條款及條件的任何條文因任何原因被認為屬非法、無效或不可執行，該等條文不影響本網站條款及條件中其他條文的合法性、有效性或可執行性。非法、無效或不可執行的條文應在必要的範圍內予以修訂以使其合法、有效或可執行，否則應從本網站條款及條件中刪除，而本網站條款及條件的所有其他條文應繼續具十足效力及作用。</StyledListItem>
                        <StyledListItem>與本網站條款及條件有關的糾紛應受香港法律約束及受香港法院的專屬管轄。</StyledListItem>
                        <StyledListItem>我們未能執行本網站條款及條件項下的任何權利或條文並不構成豁免該等或任何其他條文。</StyledListItem>
                        <StyledListItem>對於因超出我們合理控制範圍的原因而導致我們無法履行任何義務的情況，我們慨不承擔或負上責任。</StyledListItem>
                        <StyledListItem>倘本網站條款及條件的英文本與任何其他語言版本（如有）有任何不一致，概以英文本為準。</StyledListItem>
                        <StyledListItem>在適用法律的規限下，我們可轉讓我們於本網站條款及條件下的權利及義務予任何人士或機構，毋須經閣下的同意。我們亦可委聘代理或分包商履行我們的責任。</StyledListItem>
                        <StyledListItem>任何非本網站條款與條件的當事人或實體，在香港法例第623章《合約（第三者權利）條例》或其他司法管轄區的相似立法下，均無權強制執行本網站條款與條件的任何部分。</StyledListItem>
                    </StyledOrderList>
                </StyledDiv>

            </div>
        }
        </div>
    )
}


const StyledTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-decoration: underline;
`;

const StyledSubTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: justify;
`;

const StyledDiv = styled.div`
    margin-bottom: 20px;
`;

const StyledP = styled.p`
    margin-bottom: 20px;
`;

const StyledOrderList = styled.ol`
    padding-left: 50px;
`;

const StyledUnorderList = styled.ul`
    padding-left: 50px;
`;

const StyledListItem = styled.li`
    padding-left: 20px;
    margin-bottom: 10px;
`;

const Bold = styled.div`
    font-weight: bold;
    display:inline-block;
`
