import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { checkoutStream, keepaliveStream } from '../apis/index.js';
// import ReactJWPlayer from "react-jw-player";
import jwplayerjs from '../assets/js/jwplayer-8.25.1/jwplayer.js';
import base64DecodeUint8Array from '../utils/base64DecodeUint8Array.js';
import base64EncodeUint8Array from '../utils/base64EncodeUint8Array.js';
// import ExclamationRoundOrange from "./icons/ExclamationRoundOrange";
import CastingView from './CastingView.js';
import CastingIcon from '../assets/CastingIcon.svg';
import ViewIcon from '../assets/view.svg';
// import { TitleIconButtonDialog } from "./common/dialogs/TitleIconButtonDialog";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { AppContext } from '../AppContext.js';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import CommonButton from './common/buttons/CommonButton.js';
import CommonText from './common/typographies/CommonText';
import Space from './common/Space.js';
import FailIcon from '../assets/payment_fail.svg';
import FullscreenIcon from '../assets/fullscreen_icon.svg';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { breakpoint, WindowSize } from '../utils/responsive';
import Lottie from 'react-lottie';
import * as animationData from '../assets/animation/player_loading.json';
import ReactGA from 'react-ga4';

export default function Player({
  width,
  height,
  title,
  contentId,
  isMultiCDN = true,
  activityId,
  onError,
}) {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { session } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ['checkoutStream', session?.accessToken, contentId, activityId],
    () => checkoutStream(session?.accessToken, contentId, activityId)
  );
  const [viewCount, setViewCount] = useState(null);
  const [casting, setCasting] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [checkoutError, setCheckoutError] = useState(false);
  const { t, i18n } = useTranslation(['activity', 'common']);
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const isMobile = WindowSize() == 'mobile';
  if (window.jwplayer)
    window.jwplayer.key = 'nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA==';
  const keepalive = async () => {
    console.log('keepalive start');
    let result = await keepaliveStream(
      session?.accessToken,
      contentId,
      data.session
    );
    // result = { status: "fail" }
    if (result) {
      console.log('keepalive success', result);
      window.keepaliveRetry = 0;
      if (result.status == 'fail') {
        // kick user out
        console.log('keepalive kick user out1');
        setSessionError(true);
      }
      if (result.watch) setViewCount(result.watch);
    } else {
      // call api fail
      let retry = window.keepaliveRetry || 0;
      console.log('keepalive fail', retry, result);
      if (retry < 2) {
        window.keepaliveRetry = window.keepaliveRetry
          ? window.keepaliveRetry + 1
          : 1;
      } else {
        // kick user out
        console.log('keepalive kick user out2');
        window.keepaliveRetry = 0;
        setCheckoutError(true);
      }
    }
  };

  useEffect(() => {
    window.cjs.on('statechange', () => {
      if (window.cjs.state == 'connected') {
        setCasting(true);
      } else if (window.cjs.state == 'disconnected') {
        setCasting(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log(data);
    let keepaliveTimer;
    let gaTimer;
    if (data) {
      if (data.status == 'success') {
        const player = window.jwplayer('liveplayer');
        if (sessionError) {
          if (casting) {
            window.cjs.disconnect();
          } else {
            player.remove();
          }
        } else {
          if (casting) {
            if (player) player.remove();
          } else {
            player.setup({
              playlist: [
                {
                  sources: [
                    {
                      default: false,
                      file: data.streampath,
                      type: 'mpd',
                      label: 1,
                      drm: {
                        widevine: {
                          url: 'https://license-global.pallycon.com/ri/licenseManager.do',
                          headers: [
                            {
                              name: 'pallycon-customdata-v2',
                              value: data.drmtoken,
                            },
                          ],
                        },
                        playready: {
                          url: 'https://license-global.pallycon.com/ri/licenseManager.do',
                          headers: [
                            {
                              name: 'pallycon-customdata-v2',
                              value: data.drmtoken,
                            },
                          ],
                        },
                      },
                    },
                    {
                      default: false,
                      file: data.streampath,
                      type: 'hls',
                      label: 1,
                      drm: {
                        fairplay: {
                          certificateUrl: data.fairplayCertUrl, // uses .der file version fps cert URL
                          extractContentId: function (initDataUri) {
                            const contentId = initDataUri.substring(
                              initDataUri.indexOf('skd://') + 6
                            );
                            console.log('content id : ', contentId);
                            return contentId;
                          },
                          processSpcUrl:
                            'https://license-global.pallycon.com/ri/licenseManager.do',
                          licenseResponseType: 'text',
                          licenseRequestHeaders: [
                            {
                              name: 'Content-type',
                              value: 'application/x-www-form-urlencoded',
                            },
                            {
                              name: 'pallycon-customdata-v2',
                              value: data.drmtoken,
                            },
                          ],
                          licenseRequestMessage: function (message, session) {
                            var payload =
                              'spc=' + base64EncodeUint8Array(message);
                            return payload;
                          },
                          extractKey: function (response) {
                            console.log('license Response : ', response);
                            return base64DecodeUint8Array(response);
                          },
                        },
                      },
                    },
                  ],
                },
              ],
              width: width || '100%',
              height: height || '100%',
              autostart: true,
              cast: {},
              mute: false,
              volume: 75,
              allowFullscreen: isMobile,
              ...(isMultiCDN
                ? {
                    dnaConfig: {
                      compassProperty: contentId,
                    },
                  }
                : {}),
            });
            if (!isMobile)
              player.addButton(
                FullscreenIcon,
                'Fullscreen',
                () => {
                  if (window.isFullscreen) {
                    window.isFullscreen = false;
                    handle.exit();
                  } else {
                    window.isFullscreen = true;
                    handle.enter();
                  }
                },
                'fullscreen-button'
              );
            if (window.cjs && window.cjs.available) {
              // add cast button
              setTimeout(() => {
                let streamUrl = data.streampath;
                let drmToken = data.drmtoken;
                const player = window.jwplayer('liveplayer');
                console.log('add button', player, streamUrl, drmToken);
                try {
                  player.addButton(
                    CastingIcon,
                    'Cast',
                    () => {
                      console.log('cast button clicked', streamUrl, drmToken);
                      window.cjs.cast(
                        streamUrl,
                        {},
                        {
                          licenseUrl:
                            `https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=` +
                            drmToken,
                          contentId: contentId,
                          multiCDNEnabled: isMultiCDN,
                        }
                      );
                    },
                    'cast-button'
                  );
                } catch (e) {
                  console.log(e);
                }
              }, 3000);
            }
          }
          if (data.session) {
            keepaliveTimer = setInterval(keepalive, 300000);
          }
          if (data.watch) setViewCount(data.watch);
          gaTimer = setInterval(async () => {
            console.log('keepalive start');
            ReactGA.event('video_heartbeat', {
              video_title: title,
              video_url: data.streampath,
              video_provider: 'MakeALive',
            });
          }, 900000);
        }
      } else {
        // show error
        setCheckoutError(true);
      }
    }
    return () => {
      const player = window.jwplayer('liveplayer');
      if (player) player.remove();
      if (keepaliveTimer) {
        console.log('keepalive clear timeout');
        clearInterval(keepaliveTimer);
      }
      if (gaTimer) {
        console.log('ga clear timeout');
        clearInterval(gaTimer);
      }
    };
  }, [data, casting, sessionError]);

  return (
    <Container>
      {window.jwplayer && isMultiCDN && (
        <Helmet>
          <script src="https://cdn.streamroot.io/jwplayer-dash-dna-plugin/1/stable/jwplayer-dash-dna-plugin.js?srKey=8f53cb68-872a-4234-b6b2-80bc082fbe6d"></script>
          <script src="//tools.streamroot.io/usage-graphs/latest/graphs.js"></script>
        </Helmet>
      )}
      <FullScreenContainer handle={handle}>
        <div id="liveplayer">
          <div
            style={{
              width: '100%',
              aspectRatio: '16/9',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loading>
              <Lottie options={lottieOptions} />
            </Loading>
          </div>
        </div>
        {viewCount && (
          <ViewCount>
            <img style={{ marginRight: 6 }} src={ViewIcon} />
            {viewCount}
          </ViewCount>
        )}
      </FullScreenContainer>
      {casting && (
        <CastingView streamUrl={data.streampath} drmToken={data.drmtoken} />
      )}
      {sessionError && (
        <Dialog
          PaperProps={{
            style: { margin: 20 },
          }}
          maxWidth="false"
          onClose={() => navigate('/activity')}
          open={sessionError}
        >
          <div
            style={{
              padding: '40px 20px 32px',
              width: 680,
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CommonText
              whiteSpace="pre-line"
              fontWeight={600}
              fontSize="30px"
              mFontSize="24px"
              lineHeight="120%"
            >
              {t('activity:session_error_title')}
            </CommonText>
            <Space size="20px" />
            <CommonText
              whiteSpace="pre-line"
              fontWeight={400}
              fontSize="20px"
              mFontSize="16px"
              lineHeight="140%"
            >
              {t('activity:session_error_desc')}
            </CommonText>
            <Space size="25px" />
            <img src={FailIcon} alt="" />
            <Space size="25px" />
            <CommonButton
              size="large"
              type={'primary'}
              onClick={() => navigate('/activity')}
            >
              {t('common:ok')}
            </CommonButton>
          </div>
        </Dialog>
      )}
      {checkoutError && (
        <Dialog
          PaperProps={{
            style: { margin: 20 },
          }}
          maxWidth="false"
          open={checkoutError}
          onClose={() => setCheckoutError(false)}
        >
          <div
            style={{
              padding: '40px 20px 32px',
              width: 680,
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CommonText
              whiteSpace="pre-line"
              fontWeight={600}
              fontSize="30px"
              mFontSize="24px"
              lineHeight="120%"
            >
              {t('activity:chceckout_error_title')}
            </CommonText>
            <Space size="20px" />
            <CommonText
              whiteSpace="pre-line"
              fontWeight={400}
              fontSize="20px"
              mFontSize="16px"
              lineHeight="140%"
            >
              {t('activity:chceckout_error_desc')}
            </CommonText>
            <Space size="25px" />
            <img src={FailIcon} alt="" />
            <Space size="25px" />
            <CommonButton
              size="large"
              type={'primary'}
              onClick={() => setCheckoutError(false)}
            >
              {t('common:ok')}
            </CommonButton>
          </div>
        </Dialog>
      )}
      {/* {isMultiCDN && type == "dash" && <div id="streamroot-graphs"></div>} */}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FullScreenContainer = styled(FullScreen)`
  width: 100%;
  height: 100%;
  position: relative;
`;
const ViewCount = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 8px;
  color: white;
`;
const Loading = styled.div`
  width: 100px;
  @media ${breakpoint.mobile} {
    width: 50px;
  }
`;
