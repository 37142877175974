import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CommonBottomBar from "../../components/common/navbar/CommonBottomBar";
import CommonText from "../../components/common/typographies/CommonText";
import colors from "../../utils/colors.js";
import ImgTeamBg from "../../assets/profile_teamBg.jpg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const ViewMember = () => {
  const { t, i18n } = useTranslation("teamProfile");

  const _renderMobile = () => {
    return (
      <div style={{ background: "#030205" }}>
        <StyledMobileMainDiv>
          <StyledBgImage src={ImgTeamBg} />
          <Link to={"/member-profile"} style={{ textDecoration: "none" }}>
            <StyledMobileTextDiv>
              {i18n.language == "zh" && (
                <CommonText
                  fontSize="30px"
                  mFontSize="16px"
                  color={colors.white}
                >
                  觀看成員
                </CommonText>
              )}
              <CommonText
                fontSize="135px"
                mFontSize="30px"
                fontWeight="600"
                color={colors.white}
                uppercase
                outlined
                style={{ whiteSpace: "nowrap" }}
              >
                {t("button.viewMember")}
              </CommonText>
            </StyledMobileTextDiv>
          </Link>
        </StyledMobileMainDiv>

        <StyledBottomBarDiv>
          <CommonBottomBar theme="white" bgColor="black"/>
        </StyledBottomBarDiv>
      </div>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledMainDiv>
        <div></div>
        <Link to={"/member-profile"} style={{ textDecoration: "none" }}>
          <StyledTextDiv>
            {i18n.language == "zh" && (
              <CommonText fontSize="30px" mFontSize="16px" color={colors.white}>
                觀看成員
              </CommonText>
            )}
            <CommonText
              fontSize="135px"
              mFontSize="30px"
              fontWeight="600"
              color={colors.white}
              uppercase
              outlined
              style={{ whiteSpace: "nowrap" }}
            >
              {t("button.viewMember")}
            </CommonText>
          </StyledTextDiv>
        </Link>

        <CommonBottomBar theme="white" bgColor="black" />
      </StyledMainDiv>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
};

const StyledBgImage = styled.img`
  position: relative;
  width: 100%;
  user-drag: none;
`;

const StyledMobileMainDiv = styled.div`
  position: relative;
  width: 100%;
`;

const StyledMobileTextDiv = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const StyledBottomBarDiv = styled.div`
  margin-top: 60px;
  width: 100%;
  height: auto;
`;

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${ImgTeamBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  height: 100vh;
`;

const StyledTextDiv = styled.div`
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export default ViewMember;
