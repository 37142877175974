import { useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Background from '../../../assets/join_benefit_background.png';
import BackgroundBackdrop from '../../../assets/join_benefit_background_backdrop.jpg';
import { ReactComponent as JoinMirrorLogo } from '../../../assets/join_mirror_logo.svg';
import TempAvailableDatePic from '../../../assets/join_renew_bottom_section.png';
import CommonText from "../../../components/common/typographies/CommonText";
import { ResponsiveUI, breakpoint } from "../../../utils/responsive";
import { Trans, useTranslation } from "react-i18next";



const AvailableDate = ({ data }) => {
  const isMobile = useMediaQuery(breakpoint.mobile)
  const isLargeDesktop = useMediaQuery(breakpoint.largeDesktop)
  const { t, i18n } = useTranslation("signin")

  return <>
    <div >

      <TitleWithBG style={{ position: 'relative' }}>
        <StyledNotebook src={Background} alt="avaliable_date_background" />

        <StyledDateContainer>
          <ResponsiveUI
            desktop={<JoinMirrorLogo width={96} height={100} />}
            mobile={<JoinMirrorLogo width={67} height={70} />}
          />
          <AvailableDateContainer>
            {/* <CommonText mFontSize='16px' fontSize='20px' fontWeight='600'>{t('join2024.renewalMember.availableDateTitle')}</CommonText>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

              <CommonText mFontSize='28px' fontSize='36px' fontWeight='600' lineHeight="120%" whiteSpace="nowrap" style={{ display: 'inline', marginRight: 10 }}>{t('join2024.renewalMember.availableDateFrom')}</CommonText>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
                <CommonText mFontSize='28px' fontSize='36px' fontWeight='600' lineHeight="120%" whiteSpace="nowrap">
                  <span style={{ fontSize: isMobile ? 20 : 28, fontWeight: 600, display: 'inline', color: 'rgba(35, 35, 35, 0.50)', marginRight: 10 }}>{t('join2024.renewalMember.availableDateSeparator')}</span>{t('join2024.renewalMember.availableDateTo')}</CommonText>
              </div>
            </div> */}
            <CommonText mFontSize='16px' fontSize='20px' fontWeight='600'>{t('join2024.renewalMember.availableDateTitle')} </CommonText>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <CommonText mFontSize='28px' fontSize='36px' fontWeight='600' lineHeight="120%" whiteSpace="nowrap" style={{ display: 'inline', marginRight: 10 }}>
                <Trans t={t} i18nKey='join2024.renewalMember.availableDateFrom' components={[
                  <span style={{ fontSize: isMobile ? 20 : 28, fontWeight: 600, display: 'inline', color: 'rgba(35, 35, 35, 0.50)', marginRight: 10 }}></span>
                ]} />
              </CommonText>

              <CommonText mFontSize='28px' fontSize='36px' fontWeight='600' lineHeight="120%" whiteSpace="nowrap" style={{ display: 'inline' }}>
                <Trans t={t} i18nKey='join2024.renewalMember.availableDateTo' components={[
                  <span style={{ fontSize: isMobile ? 20 : 28, fontWeight: 600, display: 'inline', color: 'rgba(35, 35, 35, 0.50)', marginRight: 10 }}></span>
                ]} />
              </CommonText>
            </div>

          </AvailableDateContainer>
          {(isLargeDesktop) && <StyledRotateMirror alt="mirror" src={TempAvailableDatePic} />}
        </StyledDateContainer>
      </TitleWithBG>
    </div>

    {!isLargeDesktop && <StyledRotateMirror alt="mirror" src={TempAvailableDatePic} />}
    <StyledBackgroundBackdrop />
  </>




};


const StyledBackgroundBackdrop = styled.div`
  position: absolute;
  width:500px;
  transform: rotate(10deg) translateX(-50%);
  @media ${breakpoint.mobile}{
    left: 50%;
    top: 100px;
  }
  @media ${breakpoint.desktop}{
    left: 100%;
    top: 200px;
  }

  @media ${breakpoint.largeDesktop}{
 /*    left: 100vw;
    transform: translateX(-500px); */
  }

  height: 280px;
  object-fit: cover;
  z-index: 0;
  background: url(${BackgroundBackdrop}), lightgray 50% / cover no-repeat;
`

const TitleWithBG = styled.div`
  position: relative;
  z-index: 1;
  margin: auto;
  margin-top: 70px;
  max-width: 1000px;
  min-height: 180px;

@media ${breakpoint.mobile}{
    width: 550px;
    min-height: 215px;
    margin-bottom: 80px;
  }

 @media ${breakpoint.smallTablet}{
    width: 350px;
    min-height: 249px;
  }

  @media ${breakpoint.desktop}{
    margin-bottom: 200px;
  }
  
`

const StyledRotateMirror = styled.img`
  position: absolute;
  z-index: 2;
  right: 0;
  transform: rotate(-9deg);
  width: 400px;
  object-fit: cover;
  @media ${breakpoint.mobile}{
    width: 200px;
  }

  @media ${breakpoint.mobile}{
    top: 250px;
  }

 @media ${breakpoint.smallTablet}{
    top: 270px;
  }

  @media ${breakpoint.desktop}{
    top: 200px;
    transform: rotate(-9deg) translateX(50px);
  }

  @media ${breakpoint.largeDesktop}{
    top: 120px;
    right: -170px;
  }

  

`
const StyledNotebook = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  object-fit: cover;
 
  @media ${breakpoint.mobile}{
    width: 550px;
    object-fit: cover;
    object-position: top left;
  } @media ${breakpoint.smallTablet}{
    width: 350px;
  }

`

const StyledDateContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  padding: 30px 50px;

  @media ${breakpoint.desktop}{
    padding: 40px 100px;
    align-items: center;
  }

  // TODO: find a way without using flex gap
  gap: 24px;
  display: flex;
  @media ${breakpoint.mobile}{
    flex-direction: column;
  }


`

const AvailableDateContainer = styled.div`
  text-align: start;
  flex: 1;
`

const TimeAndFee = styled.div``
const RenewalBenefitContainer = styled.div``






export default AvailableDate;
