import { updateTmpUser, updateUser } from "../../apis";
import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import FormTextField from "../../components/common/textfields/FormTextField";
import PhoneTextField from "../../components/common/textfields/PhoneTextField";
import EmailTextField from "../../components/common/textfields/EmailTextField";
import { useState, useRef, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import CommonText from "../../components/common/typographies/CommonText";
import FormSelect from "../../components/common/selects/FormSelect";
import { Trans, useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import { AppContext } from "../../AppContext";
import constants from "../../constants";
import CheckBox from "../../components/common/checkboxs/Checkbox";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import checkAdult from "../../utils/checkAdult";
import check11 from "../../utils/check11";
import Dialog from "@mui/material/Dialog";
import FormHelperText from "@mui/material/FormHelperText";
import FailIcon from "../../assets/payment_fail.svg";
import CloseButton from "../../components/common/buttons/CloseButton";

export default function Step2({ handlePageChange, setSessionExpired }) {
  const { t } = useTranslation(["common", "signin"]);
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState(session?.user?.phone);
  const [gender, setGender] = useState(session?.user?.gender);
  const [under11, setUnder11] = useState(false);
  const [needParent, setNeedParent] = useState(session?.user?.gender);
  const [parentTNC, setParentTNC] = useState(false);
  const [parentAgree, setParentAgree] = useState(false);
  const birthdayDate = session?.user?.birthday
    ? new Date(session?.user?.birthday)
    : null;
  const [birthday, setBirthday] = useState({
    day: birthdayDate?.getDate(),
    month: birthdayDate?.getMonth() + 1,
    year: birthdayDate?.getFullYear(),
  });
  const englishFirstNameRef = useRef();
  const englishLastNameRef = useRef();
  const chineseNameRef = useRef();
  const parentFirstNameRef = useRef();
  const parentLastNameRef = useRef();
  const parentEmailRef = useRef();
  const parentEmailOTPRef = useRef();
  useEffect(() => {
    // check needParent
    if (birthday.year && birthday.month && birthday.day) {
      let birthdayDate = new Date(
        birthday.year,
        birthday.month - 1,
        birthday.day
      );
      if (
        birthdayDate.getDate() != birthday.day ||
        birthdayDate.getMonth() != birthday.month - 1
      ) {
        // wrong date
        setBirthday({
          day: birthdayDate.getDate(),
          month: birthdayDate.getMonth() + 1,
          year: birthdayDate.getFullYear(),
        });
      } else {
        const isAdult = checkAdult(birthdayDate);
        if (!isAdult) {
          if (!check11(birthdayDate)) {
            setUnder11(true);
            setNeedParent(false);
          } else setNeedParent(true);
        } else {
          setNeedParent(false);
        }
      }
    } else {
      setNeedParent(false);
    }
  }, [birthday, session?.user?.birthday]);
  const getYearOption = () => {
    let years = [];
    for (let i = 2022; i >= 1930; i--) {
      years.push(i + "");
    }
    return years;
  };
  const renderParentView = () => {
    return [
      <Space size="20px" />,
      <ParentContainer>
        <CommonText>{t("signin:parent.title")}</CommonText>
        <Space size="20px" />
        <CommonText fontWeight={700}>{t("signin:parent.info")}</CommonText>
        <Space size="20px" />
        <FormTextField
          defaultValue={session?.user?.parentFirstName}
          errorText={error?.parentFirstName}
          inputRef={parentFirstNameRef}
          label={t("signin:register.english_firstname")}
          helperText={t("signin:register.english_firstname_match_id")}
        />
        <Space size="20px" />
        <FormTextField
          defaultValue={session?.user?.parentLastName}
          errorText={error?.parentLastName}
          inputRef={parentLastNameRef}
          label={t("signin:register.english_lastname")}
          helperText={t("signin:register.english_lastname_match_id")}
        />
        <Space size="20px" />
        <EmailTextField
          username={session?.user?.username}
          purpose="parent"
          defaultValue={session?.user?.parentEmail}
          errorText={error?.parentEmail}
          inputRef={parentEmailRef}
          label={t("signin:register.parent_email")}
          requestOTP
        />
        <Space size="20px" />
        <FormTextField
          inputRef={parentEmailOTPRef}
          errorText={error?.parentEmailOTP}
          label={t("signin:register.email_otp")}
        />
        <Space size="20px" />
        <CheckBox
          onClick={() => setParentTNC(!parentTNC)}
          isChecked={parentTNC}
        >
          <CommonText>{t("signin:parent.tnc")}</CommonText>
        </CheckBox>
        <Space size="20px" />
        <Grid container>
          <CheckBox
            onClick={() => setParentAgree(!parentAgree)}
            isChecked={parentAgree}
          >
            <CommonText fontSize="16px" lingHeight="20px">
              <Trans
                t={t}
                i18nKey="signin:parent.tnc2"
                components={[
                  <Link
                    style={{
                      color: "#FF5C00",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    key={0}
                    target="_blank"
                    to="/tnc?id=web"
                  />,
                  <Link
                    style={{
                      color: "#FF5C00",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    key={1}
                    target="_blank"
                    to="/privacy-terms"
                  />,
                  <Link
                    style={{
                      color: "#FF5C00",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    key={2}
                    target="_blank"
                    to="/tnc?id=membership"
                  />,
                  <Link
                    style={{
                      color: "#FF5C00",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    key={3}
                    target="_blank"
                    to="/privacy-terms"
                  />,
                ]}
              />
            </CommonText>
          </CheckBox>
        </Grid>
      </ParentContainer>,
    ];
  };
  const handleChange = (event) => {
    if (event.target.name == "gender") {
      setGender(event.target.value);
    } else if (event.target.name == "year") {
      setBirthday({ ...birthday, year: event.target.value });
    } else if (event.target.name == "month") {
      setBirthday({ ...birthday, month: event.target.value });
    } else if (event.target.name == "day") {
      setBirthday({ ...birthday, day: event.target.value });
    }
  };
  const goBack = () => {
    if (session?.user?.memberType == "TMP") handlePageChange("step1");
    else handlePageChange("introduction");
  };

  const validate = () => {
    let error = {};
    const validFirstName = new RegExp(constants.FIRSTNAME_REGEX);
    if (!validFirstName.test(englishFirstNameRef.current.value))
      error.firstName = t("signin:register.invalid_firstname");
    const validLastName = new RegExp(constants.LASTNAME_REGEX);
    if (!validLastName.test(englishLastNameRef.current.value))
      error.lastName = t("signin:register.invalid_lastname");
    if (chineseNameRef.current.value.length > 20)
      error.chineseName = t("signin:register.invalid_chinesename");
    const validPhone = new RegExp(constants.PHONE_REGEX);
    if (!(phone && validPhone.test(phone)))
      error.phone = t("signin:register.invalid_phone");
    if (!(birthday.year && birthday.month && birthday.day))
      error.birthday = t("signin:register.invalid_birthday");
    else {
      let birthdayDate = new Date(
        birthday.year,
        birthday.month - 1,
        birthday.day
      );
      if (!check11(birthdayDate)) {
        setUnder11(true);
        return false;
      }
    }
    if (needParent) {
      // check parent fields
      if (!validFirstName.test(parentFirstNameRef.current.value))
        error.parentFirstName = t("signin:register.invalid_firstname");
      if (!validLastName.test(parentLastNameRef.current.value))
        error.parentLastName = t("signin:register.invalid_lastname");
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(parentEmailRef.current.value))
        error.parentEmail = t("signin:register.invalid_email");
      if (parentEmailOTPRef.current.value.length != 6)
        error.parentEmailOTP = t("signin:register.invalid_email_otp");
      if (!parentTNC) error.parentTNC = t("signin:parent.tnc_error");
    }
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleNext = async (back = false) => {
    if (!loading && (back || validate())) {
      setLoading(true);
      let birthdayDate = null;
      if (birthday.year && birthday.month && birthday.day) {
        birthdayDate = new Date(
          birthday.year,
          birthday.month - 1,
          birthday.day
        ).toISOString();
      }
      const body = {
        firstName: englishFirstNameRef.current.value,
        lastName: englishLastNameRef.current.value,
        chineseName: chineseNameRef.current.value,
        phone,
        gender,
        birthday: birthdayDate,
      };
      if (needParent) {
        body.parentFirstName = parentFirstNameRef.current.value;
        body.parentLastName = parentLastNameRef.current.value;
        body.parentEmail = parentEmailRef.current.value;
        body.parentEmailOTP = parentEmailOTPRef.current.value;
      }
      console.log(body);
      let result = null;
      if (session?.user?.memberType == "TMP")
        result = await updateTmpUser(session.accessToken, body);
      else result = await updateUser(session.accessToken, body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem("session", JSON.stringify(newSession));
        setSession(newSession);
        if (back) goBack();
        else handlePageChange("step3");
      } else {
        // set api error
        if (back) goBack();
        else {
          // set api error
          if (result.error?.code == "E0002") {
            setSessionExpired(true);
          } else if (result.error?.code == "C0003") {
            setError({
              parentEmailOTP: t("signin:register.invalid_email_otp"),
            });
          }
        }
      }
      setLoading(false);
    }
  };
  console.log("user", session?.user);
  console.log("birthday", birthday);
  return (
    <Container>
      <CommonText lineHeight="140%">{t("signin:register.step") + " 2/6"}</CommonText>
      <Space size="20px" />
      <FormTextField
        defaultValue={session?.user?.firstName}
        errorText={error?.firstName}
        inputRef={englishFirstNameRef}
        label={t("signin:register.english_firstname")}
        helperText={t("signin:register.english_firstname_match_id")}
      />
      <Space size="20px" />
      <FormTextField
        defaultValue={session?.user?.lastName}
        errorText={error?.lastName}
        inputRef={englishLastNameRef}
        label={t("signin:register.english_lastname")}
        helperText={t("signin:register.english_lastname_match_id")}
      />
      <Space size="20px" />
      <FormTextField
        defaultValue={session?.user?.chineseName}
        errorText={error?.chineseName}
        inputRef={chineseNameRef}
        label={t("signin:register.chinese_name")}
        helperText={t("signin:register.chinese_name_match_id")}
      />
      <Space size="20px" />
      <PhoneTextField
        onChange={(value) => setPhone(value)}
        errorText={error?.phone}
        value={phone}
        label={t("signin:register.phone")}
      />
      <Space size="20px" />
      <FormSelect
        label={t("signin:register.gender")}
        name="gender"
        onChange={handleChange}
        value={gender}
      >
        <MenuItem value="male">{t("common:gender.male")}</MenuItem>
        <MenuItem value="female">{t("common:gender.female")}</MenuItem>
        <MenuItem value="others">{t("common:gender.others")}</MenuItem>
      </FormSelect>
      <Space size="20px" />
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={3}>
          <FormSelect
            label={t("signin:register.birthday")}
            name="day"
            onChange={handleChange}
            value={birthday.day}
            options={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name="month"
            onChange={handleChange}
            value={birthday.month}
            options={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name="year"
            onChange={handleChange}
            value={birthday.year}
            options={getYearOption()}
          />
        </Grid>
      </Grid>
      <StyledErrorText>{error?.birthday || ""}</StyledErrorText>
      {needParent ? renderParentView() : null}
      <Space size="20px" />
      <ButtonContainer>
        <CommonButton
          style={{ width: "50%", maxWidth: 440 }}
          onClick={() => handleNext(true)}
        >
          {t("signin:register.previous")}
        </CommonButton>
        <CommonButton
          style={{ width: "50%", maxWidth: 440, marginLeft: 30 }}
          disabled={needParent && (!parentTNC || !parentAgree)}
          type={"primary"}
          onClick={() => handleNext(false)}
        >
          {t("signin:register.next")}
        </CommonButton>
      </ButtonContainer>
      <Dialog
        onClose={() => {
          setUnder11(false);
        }}
        open={under11}
      >
        <div
          style={{
            padding: 80,
            width: 960,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CommonText
            whiteSpace="pre-line"
            fontWeight={600}
            fontSize="30px"
            lineHeight="42px"
          >
            {t("signin:register.under11")}
          </CommonText>
          <Space size="25px" />
          <img src={FailIcon} alt="" />
          <Space size="25px" />
          <CommonButton
            size="large"
            type={"primary"}
            onClick={() => {
              setUnder11(false);
            }}
          >
            {t("common:ok")}
          </CommonButton>
          <CloseButton
            onClick={() => {
              setUnder11(false);
            }}
          />
        </div>
      </Dialog>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  padding: 20px 16px 20px 16px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;
const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff5c00;
    textalign: left;
    width: 100%;
  }
`;
