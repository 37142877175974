import styled from 'styled-components';
import License from '../footers/License';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';
import colors from '../../../utils/colors.js';
import CommonText from '../typographies/CommonText';
import BlackIgIcon from '../../../assets/footer_igBlack.svg';
import BlackFbIcon from '../../../assets/footer_fbBlack.svg';
import BlackYoutubeIcon from '../../../assets/footer_youtubeBlack.svg';
import BlackTwitterIcon from '../../../assets/footer_twitterBlack.svg';
import WhiteIgIcon from '../../../assets/footer_igWhite.svg';
import WhiteFbIcon from '../../../assets/footer_fbWhite.svg';
import WhiteYoutubeIcon from '../../../assets/footer_youtubeWhite.svg';
import WhiteTwitterIcon from '../../../assets/footer_twitterWhite.svg';
import Space from '../Space';
import CommonDivider from '../divider/CommonDivider';
import ReactGA from 'react-ga4';

const links = {
  fanclub_instagram: 'https://www.instagram.com/miro.weallare/',
  fanclub_facebook: 'https://www.facebook.com/MIRO.weallare',
  offical_instagram: 'https://www.instagram.com/mirror.weare/',
  offical_facebook: 'https://www.facebook.com/MIRROR.WeAre/',
  offical_youtube: 'https://www.youtube.com/c/MIRRORweare',
  offical_twitter: 'https://twitter.com/MIRROR_weare',
};

const colorList = {
  yellow: '#AB8672;',
  grey: '#C4C6C2',
  greyBlue: '#B7C2CD',
  purple: '#C3A8FF',
  orange: '#EC9022',
  black: '#000000',
  darkPurple: '#4F3FB2',
};

const CommonBottomBar = ({ theme, bgColor }) => {
  const handleClick = (link, linkType) => {
    ReactGA.event('outbound_click_from_home', {
      param: link,
    });
    window.open(link, '_blank');
  };

  return (
    <Container bgColor={bgColor ? colorList[bgColor] : null}>
      <LeftContainer>
        <SocialMedia>
          <CommonText
            largeFontSize="16px"
            fontSize="14px"
            style={{ color: theme === 'black' ? colors.black : colors.white }}
          >
            MIRROR OFFICIAL FAN CLUB |
          </CommonText>
          <IconButton
            src={theme === 'black' ? BlackIgIcon : WhiteIgIcon}
            onClick={() => handleClick(links['fanclub_instagram'], 'instagram')}
          />
          <IconButton
            src={theme === 'black' ? BlackFbIcon : WhiteFbIcon}
            onClick={() => handleClick(links['fanclub_facebook'], 'facebook')}
          />
        </SocialMedia>

        <ResponsiveUI
          desktop={<Space horizontal size="20px" />}
          mobile={<div />}
        />

        <SocialMedia>
          <CommonText
            largeFontSize="16px"
            fontSize="14px"
            style={{ color: theme === 'black' ? colors.black : colors.white }}
          >
            MIRROR |
          </CommonText>
          <IconButton
            src={theme === 'black' ? BlackIgIcon : WhiteIgIcon}
            onClick={() => handleClick(links['offical_instagram'], 'instagram')}
          />
          <IconButton
            src={theme === 'black' ? BlackFbIcon : WhiteFbIcon}
            onClick={() => handleClick(links['offical_facebook'], 'facebook')}
          />
          <IconButton
            src={theme === 'black' ? BlackYoutubeIcon : WhiteYoutubeIcon}
            onClick={() => handleClick(links['offical_youtube'], 'youtube')}
          />
          <IconButton
            src={theme === 'black' ? BlackTwitterIcon : WhiteTwitterIcon}
            onClick={() => handleClick(links['offical_twitter'])}
          />
        </SocialMedia>
      </LeftContainer>

      <ResponsiveUI
        mobile={
          <CommonDivider
            style={{
              borderColor: theme === 'black' ? colors.black : colors.white,
            }}
          />
        }
        desktop={<div />}
      />

      <License
        disableDivider
        color={theme === 'black' ? colors.black : colors.white}
      ></License>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgb(249,249,249)'};
  height: auto;
  width: 100%;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    padding: 0px;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
    padding: 6px 50px;
  }

  @media ${breakpoint.largeDesktop} {
    flex-direction: row;
    padding: 10px 50px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px 16px 12px;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.img`
  cursor: pointer;
  width: 32px;
`;

export default CommonBottomBar;
