import ImgEshopL1 from "../../../assets/home_eshopIconL1.png";
import ImgEshopL2 from "../../../assets/home_eshopIconL2.png";
import ImgEshopL3 from "../../../assets/home_eshopIconL3.png";
import ImgEshopMid from "../../../assets/home_eshopIconMid.png";
import ImgEshopR1 from "../../../assets/home_eshopIconR1.png";
import ImgEshopR2 from "../../../assets/home_eshopIconR2.png";
import styled from "styled-components";
import colors from "../../../utils/colors";
import React, { useRef, useState, useEffect } from "react";
import TitleGroup from "../../../components/common/typographies/TitleGroup";
import ImgStickerStar from "../../../assets/home_eshopStarSticker.svg";
import CommonBottomBar from "../../../components/common/navbar/CommonBottomBar";
import EshopInfinieTextLoop from "../../../components/home/EshopInfiniteTextLoop";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import useMediaQuery from "@mui/material/useMediaQuery";
import CommonButton from "../../../components/common/buttons/CommonButton";
import { useTranslation } from "react-i18next";

const EshopSession = () => {
  const { t, i18n } = useTranslation("home");
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false,
  });
  const [percentShown, setPercentShow] = useState({
    itemOne: 0,
    itemTwo: 0,
    itemThree: 0,
  });
  const animaRef = useRef(null);
  const isMobile = useMediaQuery(`(max-width: 860px)`);

  useEffect(() => {
    const appContainer = document.getElementById("appContainer");

    const onScroll = () => {
      const getHeight = (element) => element.offsetHeight;
      const divHeight = getHeight(animaRef.current);

      const topPos = (element) => element.getBoundingClientRect().top;
      const divPos = topPos(animaRef.current);

      const scrollPos = appContainer.scrollTop + appContainer.clientHeight;

      if (divPos < scrollPos) {
        // Element scrolled to
        doShow((state) => ({ ...state, itemThree: true }));

        let deviceWidth = isMobile ? 450 : 200;
        let itemThreePercent =
          ((scrollPos - (appContainer.scrollHeight - divHeight)) / divHeight) *
          100;
        if (itemThreePercent > 100) itemThreePercent = 100;
        if (itemThreePercent < 0) itemThreePercent = 0;

        setPercentShow((prevState) => ({
          ...prevState,
          itemThree: itemThreePercent,
        }));
      } else if (divPos > scrollPos) {
        // Element scrolled away (up)
        doShow((state) => ({ ...state, itemThree: false }));
      }
    };
    appContainer.addEventListener("scroll", onScroll);
    return () => appContainer.removeEventListener("scroll", onScroll);
  }, [isMobile]);

  return (
    <Container ref={animaRef}>
      <Title>
        <TitleGroup zhTitle="MIRROR 商店" enTitle="mirror shop" />
        <StickerStar src={ImgStickerStar} />
        <CommonButton type="primary">
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://shop.mirrorweare.com"
            target="_blank"
          >
            {t("button.visit_store")}
          </a>
        </CommonButton>
      </Title>
      <Banner>
        <PurpleRectangle />
        <EshopInfinieTextLoop text="Discover more" />
        <LeftOneImg
          src={ImgEshopL1}
          tranlateX={getInterpolation(-800, percentShown.itemThree)}
          tranlateY={getInterpolation(-200, percentShown.itemThree)}
          rotateDeg={getInterpolation(-45, percentShown.itemThree)}
        />
        <LeftTwoImg
          src={ImgEshopL2}
          tranlateX={getInterpolation(-800, percentShown.itemThree)}
          rotateDeg={getInterpolation(-45, percentShown.itemThree)}
        />
        <LeftThreeImg
          src={ImgEshopL3}
          tranlateY={getInterpolation(400, percentShown.itemThree)}
          rotateDeg={getInterpolation(-45, percentShown.itemThree)}
        />
        <MidImg
          src={ImgEshopMid}
          tranlateX={getInterpolation(800, percentShown.itemThree)}
          tranlateY={getInterpolation(200, percentShown.itemThree)}
          rotateDeg={getInterpolation(45, percentShown.itemThree)}
        />
        <RightOneImg
          src={ImgEshopR1}
          tranlateX={getInterpolation(1200, percentShown.itemThree)}
          tranlateY={getInterpolation(-200, percentShown.itemThree)}
          rotateDeg={getInterpolation(45, percentShown.itemThree)}
        />
        <RightTwoImg
          src={ImgEshopR2}
          tranlateX={getInterpolation(600, percentShown.itemThree)}
          tranlateY={getInterpolation(200, percentShown.itemThree)}
          rotateDeg={getInterpolation(45, percentShown.itemThree)}
        />
      </Banner>
      <CommonBottomBar theme="black" />
    </Container>
  );
};

const getInterpolation = (value, percent) => {
  return (value * (100 - percent)) / 100;
};

const Container = styled.div`
  background-color: ${colors.white};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @media ${breakpoint.mobile} {
    min-height: 480px;
  }
  @media ${breakpoint.desktop} {
    min-height: 700px;
  }
`;

const Title = styled.div`
  position: relative;
  margin-top: 40px;
  @media ${breakpoint.largeDesktop} {
    margin-top: 65px;
  }
`;

const StickerStar = styled.img`
  user-drag: none;
  position: absolute;
  bottom: 80px;
  left: 10%;

  @media ${breakpoint.desktop} {
    left: 80px;
  }
`;

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: #0f0;
  margin-top: -150px;
`;

const PurpleRectangle = styled.div`
  background-color: #e427ff;
  width: 250px;
  height: 30px;
  transform: rotate(-9deg) scale(1.1);
  position: absolute;
  right: 0px;
  top: 35px;

  @media (min-width: 750px) {
    top: 5px;
  }

  @media ${breakpoint.desktop} {
    width: 700px;
    height: 100px;
    top: -50px;
  }
`;

const LeftOneImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    translateY(${({ tranlateY }) => (tranlateY ? `${tranlateY}px` : "0")})
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 10;

  @media ${breakpoint.onlyMobile} {
    left: 10%;
    top: -50px;
    height: 92px;
    width: 100px;
  }

  @media ${breakpoint.tablet} {
    left: 22%;
    top: -150px;
    height: 137px;
    width: 148px;
  }

  @media ${breakpoint.desktop} {
    left: 29vw;
    top: -150px;
    height: 137px;
    width: 148px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 177px;
    width: 192px;
    left: 33%;
    top: -100px;
  }
`;

const LeftTwoImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 15;

  @media ${breakpoint.onlyMobile} {
    left: 7%;
    top: 20px;
    height: 100px;
    width: 100px;
  }

  @media ${breakpoint.tablet} {
    left: 20%;
    top: -30px;
    height: 150px;
    width: 150px;
  }

  @media ${breakpoint.desktop} {
    left: 29vw;
    top: -20px;
    height: 150px;
    width: 150px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 180px;
    width: 180px;
    left: 30%;
    top: 20px;
  }
`;

const LeftThreeImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    translateY(${({ tranlateY }) => (tranlateY ? `${tranlateY}px` : "0")})
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 20;

  @media ${breakpoint.mobile} {
    left: 15%;
    top: 120px;
    height: 80px;
    width: 80px;
  }

  @media ${breakpoint.tablet} {
    left: 30%;
    top: 120px;
    height: 123px;
    width: 123px;
  }

  @media ${breakpoint.desktop} {
    left: 34vw;
    top: 120px;
    height: 123px;
    width: 123px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 150px;
    width: 150px;
    left: 35%;
    top: 170px;
  }
`;

const MidImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    translateY(${({ tranlateY }) => (tranlateY ? `${tranlateY}px` : "0")})
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 5;

  @media ${breakpoint.onlyMobile} {
    top: -30px;
    left: 30%;
    height: 170px;
    width: 170px;
  }

  @media ${breakpoint.tablet} {
    top: -110px;
    left: 35%;
    width: 270px;
    height: 270px;
  }

  @media ${breakpoint.desktop} {
    top: -110px;
    left: 39%;
    width: 270px;
    height: 270px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 300px;
    height: 300px;
    left: 40%;
    top: -70px;
  }
`;

const RightOneImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    translateY(${({ tranlateY }) => (tranlateY ? `${tranlateY}px` : "0")})
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 1;

  @media ${breakpoint.onlyMobile} {
    left: 63%;
    top: -70px;
    height: 80px;
    width: 80px;
  }

  @media ${breakpoint.tablet} {
    left: 57%;
    top: -180px;
    width: 123px;
    height: 123px;
  }

  @media ${breakpoint.desktop} {
    width: 123px;
    height: 123px;
    left: 54vw;
    top: -150px;
  }

  @media ${breakpoint.largeDesktop} {
    width: 150px;
    height: 150px;
    left: 52%;
    top: -150px;
  }
`;

const RightTwoImg = styled.img`
  transform: translateX(
      ${({ tranlateX }) => (tranlateX ? `${tranlateX}px` : "0")}
    )
    translateY(${({ tranlateY }) => (tranlateY ? `${tranlateY}px` : "0")})
    rotate(${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : "0deg")});
  transition: transform 1s;
  position: absolute;
  z-index: 5;

  @media ${breakpoint.onlyMobile} {
    left: 63%;
    top: 90px;
    height: 120px;
    width: 120px;
  }

  @media ${breakpoint.tablet} {
    left: 60%;
    top: 70px;
    height: 172px;
    width: 172px;
  }

  @media ${breakpoint.desktop} {
    height: 172px;
    width: 172px;
    left: 56vw;
    top: 80px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 210px;
    width: 210px;
    left: 54%;
    top: 100px;
  }
`;

export default EshopSession;
