import './App.css';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Link,
  useLocation,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Home from './pages/Home';
import Profile, {
  ProfilePageInfo,
  ProfilePageMembership,
  ProfilePageOrder,
  ProfilePageOrderDetail,
} from './pages/Profile';
import TeamProfile from './pages/TeamProfile';
import MemberProfile from './pages/MemberProfile';
import MemberProfileDetail from './pages/MemberProfileDetail';
import Discography from './pages/Discography';
import DiscographyDetail from './pages/DiscographyDetail';
import Activity from './pages/Activity';
import ActivityDetail from './pages/ActivityDetail';
import Join from './pages/Join2024';
import Signin from './pages/Signin';
import styled from 'styled-components';
import { AppContext } from './AppContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useContext, useState } from 'react';
import WebRegister from './pages/WebRegister';
import MiroRegister from './pages/MiroRegister';
import TNC from './pages/TNC';
import FAQ from './pages/FAQ';
import ContactUs from './pages/ContactUs';
import ResetPassword from './pages/ResetPassword';
import ConsentSnackbar from './pageComponents/ConsentSnackbar';
import PrivatePolicy from './pages/PrivatePolicy';
import ExternalLink from './pages/ExternalLink';
import MetaTag from './utils/MetaTag';
import Loading from './components/common/loading';
import ScrollIndicator from './components/common/scrollIndicator';
import Renew from './pages/Renew';
import PaymentComplete from './pages/PaymentComplete';
import MediaSeries from './pages/MediaSeries';
import MediaSeriesDetail from './pages/MediaSeriesDetail';
import MiroOnly from './pages/MiroOnly';
import WelcomeMIRODialog from './components/WelcomeMIRODialog';
import { getPopup } from './apis';
import MIRODayCounterDialog from './pageComponents/profile/MIRODayCounterDialog';

const teamProfilePath = "/team-profile";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5C00',
    },
  },
  // components: {
  //   MuiToggleButton: {
  //     styleOverrides: {
  //       valueLabel: ({ownerState, theme }) => consol({
  //       })
  //       root: {
  //         backgroundColor: '#FF5C00',
  //       }
  //     }
  //   }
  // }
});
function calculateSCrollPercentage(target) {
  return (target.scrollTop / (target.scrollHeight - target.offsetHeight)) * 100;
}

function App() {
  const { init, memberType, session } = useContext(AppContext);
  const appRef = useRef(null);
  const location = useLocation();
  const [scrollProgress, setScollProgress] = useState(null);
  const [scrollable, setScollable] = useState(false);
  const [showWelcome, setShowWelcome] = useState(null);
  const [showMiroCount, setShowMiroCount] = useState(null);

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      if (location.pathname === teamProfilePath){
        //As team profile will handling the scrolling different from other page. ScrollIndicator will draw in team profile page
        setScollable(false)
      }
      else if (entries[0]?.target.scrollHeight > entries[0]?.target.offsetHeight)
        setScollable(true);
      else setScollable(false);
      setScollProgress({
        scrollTop: entries[0]?.target.scrollTop,
        percentage: calculateSCrollPercentage(entries[0]?.target),
      });
    })
  );
  useEffect(() => {
    if (appRef.current) resizeObserver.current.observe(appRef.current);
  }, [init, appRef]);
  useEffect(() => {
    if (appRef.current) {
      if (location.pathname === teamProfilePath){
        //As team profile will handling the scrolling different from other page. ScrollIndicator will draw in team profile page
        setScollable(false)
      }
      else if (appRef.current.scrollHeight > appRef.current.offsetHeight)
        setScollable(true);
      else setScollable(false);
      setScollProgress({
        scrollTop: appRef.current.scrollTop,
        percentage: calculateSCrollPercentage(appRef.current),
      });
    }
  }, [location]);

  useEffect(() => {
    if (memberType == 'MIRO') {
      const query = async () => {
        const result = await getPopup(session?.accessToken);
        console.log('kazaf getPopup result', result);
        if (result.data.welcome) {
          setShowWelcome(result.data.welcome);
        }
        if (result.data.miro_count) {
          setShowMiroCount(result.data.miro_count);
        }
      };
      query();
    }
  }, [memberType]);

  const handleScroll = (event) => {
    setScollProgress({
      scrollTop: event.currentTarget.scrollTop,
      percentage:
        (event.currentTarget.scrollTop /
          (event.currentTarget.scrollHeight -
            event.currentTarget.offsetHeight)) *
        100,
    });
  };
  if (!init) {
    return <Loading />;
  } else if (memberType === 'MIRO') {
    // MIRO user
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppContainer id="appContainer" ref={appRef} onScroll={handleScroll} location={location}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/signin"
                element={<Navigate replace to="/join" />}
              />
              <Route exact path={teamProfilePath} element={<TeamProfile location={location}/>} />
              <Route exact path="/member-profile" element={<MemberProfile />} />
              <Route
                exact
                path="/member-profile/:id"
                element={<MemberProfileDetail />}
              />
              <Route exact path="/discography" element={<Discography />} />
              <Route
                exact
                path="/discography/:id"
                element={<DiscographyDetail />}
              />
              <Route exact path="/activity" element={<Activity />} />
              <Route exact path="/activity/:id" element={<ActivityDetail />} />
              <Route
                exact
                path="/payment-complete"
                element={<PaymentComplete />}
              />
              <Route exact path="/privacy-terms" element={<PrivatePolicy />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/profile/info" element={<ProfilePageInfo />} />
              <Route
                exact
                path="/profile/order"
                element={<ProfilePageOrder />}
              />
              <Route
                exact
                path="/profile/order/:orderId"
                element={<ProfilePageOrderDetail />}
              />
              <Route
                exact
                path="/profile/membership"
                element={<ProfilePageMembership />}
              />
              <Route exact path="/tnc" element={<TNC />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/external-link" element={<ExternalLink />} />
              <Route exact path="/media-series" element={<MediaSeries />} />
              <Route
                exact
                path="/media-series/:id"
                element={<MediaSeriesDetail />}
              />
              <Route exact path="/join" element={<Join />} />
              <Route exact path="/renew" element={<Renew />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <MetaTag page="default" />
            <ConsentSnackbar />
            {scrollable && (
              <ScrollIndicator
                onArrowClick={() => {
                  appRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
                value={scrollProgress}
              />
            )}
          </AppContainer>
          {showMiroCount && (
            <MIRODayCounterDialog
              plus={true}
              count={showMiroCount.count}
              since={showMiroCount.since}
              onClose={() => setShowMiroCount(null)}
            />
          )}
          {showWelcome && (
            <WelcomeMIRODialog
              isNew={showWelcome.isNew}
              onClose={() => setShowWelcome(null)}
            />
          )}
        </QueryClientProvider>
      </ThemeProvider>
    );
  } else if (memberType === 'WEB') {
    // WEB user
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppContainer ref={appRef} onScroll={handleScroll} location={location}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/signin"
                element={<Navigate replace to="/" />}
              />
              <Route
                exact
                path="/web-register"
                element={<Navigate replace to="/" />}
              />
              <Route
                exact
                path="/payment-complete"
                element={<PaymentComplete />}
              />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/profile/info" element={<ProfilePageInfo />} />
              <Route
                exact
                path="/profile/order"
                element={<ProfilePageOrder />}
              />
              <Route
                exact
                path="/profile/order/:orderId"
                element={<ProfilePageOrderDetail />}
              />
              <Route
                exact
                path="/profile/membership"
                element={<ProfilePageMembership />}
              />
              <Route exact path="/privacy-terms" element={<PrivatePolicy />} />
              <Route exact path="/tnc" element={<TNC />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/external-link" element={<ExternalLink />} />
              <Route exact path="/miro-only" element={<MiroOnly />} />
              <Route exact path="/miro-register" element={<MiroRegister />} />
              <Route exact path={teamProfilePath} element={<TeamProfile location={location}/>} />
              <Route exact path="/member-profile" element={<MemberProfile />} />
              <Route
                exact
                path="/member-profile/:id"
                element={<MemberProfileDetail />}
              />
              <Route exact path="/discography" element={<Discography />} />
              <Route
                exact
                path="/discography/:id"
                element={<DiscographyDetail />}
              />
              <Route exact path="/join" element={<Join />} />
              <Route exact path="/renew" element={<Renew />} />
              <Route path="*" element={<Navigate replace to="/miro-only" />} />
            </Routes>
            <MetaTag page="default" />
            <ConsentSnackbar />
            {scrollable && (
              <ScrollIndicator
                onArrowClick={() => {
                  appRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
                value={scrollProgress}
              />
            )}
          </AppContainer>
        </QueryClientProvider>
      </ThemeProvider>
    );
  } else {
    // annonymous users
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppContainer id="app" ref={appRef} onScroll={handleScroll} location={location}>
            <Routes>
              <Route
                exact
                path="/"
                element={<Home scrollProgress={scrollProgress} />}
              />
              <Route exact path="/join" element={<Join />} />
              <Route exact path="/signin" element={<Signin />} />
              <Route
                exact
                path="/web-register"
                element={<Navigate replace to="/" />}
              />
              <Route exact path="/miro-register" element={<MiroRegister />} />
              <Route
                exact
                path="/payment-complete"
                element={<PaymentComplete />}
              />
              <Route exact path="/privacy-terms" element={<PrivatePolicy />} />
              <Route exact path="/tnc" element={<TNC />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/external-link" element={<ExternalLink />} />
              <Route exact path={teamProfilePath} element={<TeamProfile location={location}/>} />
              <Route exact path="/member-profile" element={<MemberProfile />} />
              <Route exact path="/miro-only" element={<MiroOnly />} />
              <Route
                exact
                path="/member-profile/:id"
                element={<MemberProfileDetail />}
              />
              <Route exact path="/discography" element={<Discography />} />
              <Route
                exact
                path="/discography/:id"
                element={<DiscographyDetail />}
              />
              <Route path="*" element={<Navigate replace to="/signin" />} />
            </Routes>
            <MetaTag page="default" />
            <ConsentSnackbar />
            {scrollable && (
              <ScrollIndicator
                appRef={appRef}
                onArrowClick={() => {
                  appRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
                value={scrollProgress}
              />
            )}
          </AppContainer>
        </QueryClientProvider>
      </ThemeProvider>
    );
  }
}
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow-x: ${props => props.location?.pathname == teamProfilePath ? 'visible' : 'hidden'};
  overflow-y: ${props => props.location?.pathname == teamProfilePath ? 'visible' : 'auto'};
  text-align: center;
`;

export default App;
