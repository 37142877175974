import styled from "styled-components";

import CommonNavBar from "../../../components/common/navbar/CommonNavBar";
import CommonBottomBar from "../../../components/common/navbar/CommonBottomBar";
import CommonText from "../../../components/common/typographies/CommonText";
import colors from "../../../utils/colors.js";
import { breakpoint } from "../../../utils/responsive";

import ImgMemberBg from "../../../assets/home_memberBg.jpg";
import ImgMemberMobileBg from "../../../assets/home_memberMobileBg.jpg";

const welcome = () => {
  return (
    <StyledDiv>
      <CommonNavBar theme="white" whiteMenu={true} />
      <StyledTextDiv>
        <CommonText
          fontSize="12vw"
          mFontSize="100px"
          largeFontSize="180px"
          color={colors.white}
          type="secondary"
          style={{ whiteSpace: "nowrap" }}
        >
          Hi Miro
        </CommonText>
      </StyledTextDiv>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${ImgMemberMobileBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  object-fit: cover;
  height: 100svh;
  width: 100%;

  @media ${breakpoint.desktop} {
    background-image: url(${ImgMemberBg});
    background-position: center;
  }
`;

const StyledTextDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.55));

  @media ${breakpoint.mobile} {
    align-items: flex-start;
    top: -20px;
  }

  @media ${breakpoint.desktop} {
    align-items: center;
    top: 12%;
  }

  @media ${breakpoint.largeDesktop} {
    top: 12%;
  }
`;

export default welcome;
