import styled from "styled-components";
import { breakpoint } from "../../../utils/responsive";
import WithPageLoadAnimation from "./WithPageLoadAnimation";

const StyledVoteContainerWithDelay = styled(WithPageLoadAnimation)`
  max-width: 740px;
  padding: 0 20px;
  @media ${breakpoint.desktop}{
    padding: 0 19px;
  }

  @media ${breakpoint.mobile} {
    max-width: 600px;
  }

  @media ${breakpoint.smallTablet} {
    max-width: 440px;
  }
`;

export default StyledVoteContainerWithDelay
