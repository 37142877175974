import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

// req: name
// Sample Usage:
// <ProfileButton name="kris"></ProfileButton>

function ProfileButton(props) {
  let navigate = useNavigate()
  let { name, onClick, blackTheme, handleClose } = props;

  function handleNavigateToProfile() {
    navigate('/profile')
  }

  const _renderContent = (isMobile) => (
    <StyledDiv onClick={isMobile ? () => { handleNavigateToProfile(); handleClose(); }: onClick} blackTheme={blackTheme}>
      <StyledLetterDiv blackTheme={blackTheme}>
        {name ? name[0] : ""}
      </StyledLetterDiv>
    </StyledDiv>
  );

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  )
}

const StyledDiv = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: ${(props) =>
    props.blackTheme ? "rgba(0,0,0,0.1)" : "rgba(249,249,249,0.1)"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media ${breakpoint.desktop} {
    height: 60px;
    width: 60px;
  }
`;

const StyledLetterDiv = styled.div`
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => (props.blackTheme ? "#111111" : "#F9F9F9")};
  text-transform: uppercase;
  @media ${breakpoint.desktop} {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
`;

export default ProfileButton;
