import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TitleGroup from "../../../components/common/typographies/TitleGroup";
import CommonButton from "../../../components/common/buttons/CommonButton";
import CommonText from "../../../components/common/typographies/CommonText";
import ImgStickerMusic from "../../../assets/home_musicSticker.svg";
import colors from "../../../utils/colors";
import DiscographyCarousel from "../../../components/home/DiscographyCarousel";
import { breakpoint, ResponsiveUI, WindowSize } from "../../../utils/responsive";
import Space from "../../../components/common/Space";

function DiscographySession({ data }) {
  const { t, i18n } = useTranslation("home");
  const navigate = useNavigate();
  const imageList = data ? data?.map(({ mvImage }) => mvImage) : [];
  const isMobile = WindowSize() == "mobile";

  const handleClick = (currentSlide, discographyId) => {
    if (currentSlide != null) {
      const id = data[currentSlide]._id;
      navigate(`/discography/${id}`);
    } else {
      navigate(`/discography/${discographyId}`);
    }
  };

  const _renderMobile = () => {
    return (
      <div style={{ marginBottom: 70 }}>
        {data &&
          data.map((item, index) => {
            return (
              <DiscographyItem onClick={() => handleClick(null, item._id)}>
                <StyledImage src={item.mvImage} />
                <DiscographyText>
                  <CommonText color={colors.white} fontSize="32px" fontWeight="600">
                    {i18n.language == "zh" ? item.nameZh : item.nameEn}
                  </CommonText>
                  <CommonText
                    color={colors.white}
                    fontSize="24px"
                    fontWeight="500"
                  >
                    {i18n.language == "zh"
                      ? item.performersZh
                      : item.performersEn}
                  </CommonText>

                  {/* <CommonText
                    color={colors.white}
                    fontSize="14px"
                    fontWeight="600"
                    style={{ marginTop: 20, cursor: "pointer"}}
                  >
                    {t("button.viewNow")}
                  </CommonText> */}
                </DiscographyText>

              </DiscographyItem>
            );
          })}
      </div>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledCardsDiv>
        <DiscographyCarousel
          images={imageList}
          data={data}
          handleClick={handleClick}
        />
      </StyledCardsDiv>
    );
  };

  return (
    <StyledMainDiv>
      <StyledTitleMainDiv>
        <StyledTitleText>
          <TitleGroup
            zhTitle="音樂作品"
            enTitle="discography"
            color={colors.white}
          />
        </StyledTitleText>
        <StickerMusic src={ImgStickerMusic} />
      </StyledTitleMainDiv>

      <Link to={`/discography`} style={{ textDecoration: "none" }}>
        <StyledButtonDiv>
          <CommonButton
            type="outlined"
            whiteTheme
            hoverFontColor="#ec9022"
            normalCase
            style={{width: isMobile ? '152px' : "267px"}}
          >
            {t("button.allDiscography")}
          </CommonButton>
        </StyledButtonDiv>
      </Link>

      <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  background-color: #ec9022;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  @media ${breakpoint.largeDesktop} {
    min-height: 100vh;
  }
`;

const StyledTitleMainDiv = styled.div`
  position: relative;
  margin-top: 30px;
`;

const StyledTitleText = styled.div`
  position: relative;
  z-index: 100;

  @media ${breakpoint.largeDesktop} {
    padding-top: 40px;
  }
`;

const StickerMusic = styled.img`
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 1;
`;

const StyledButtonDiv = styled.div`
  @media ${breakpoint.largeDesktop} {
    margin-top: 10px;
  }
`;

const StyledImage = styled.img`
  width: 100vw;
  height: 55.5vw;
  object-fit: cover;
`;

const DiscographyItem = styled.div`
  padding-top: 100px;
  position: relative;
  height: auto;
  width: 100%;
`;

const DiscographyText = styled.div`
  text-align: left;
  padding-left: 20px;
`;

const StyledCardsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 40px;
  @media ${breakpoint.largeDesktop} {
    margin-top: 50px;
  }
`;

export default DiscographySession;
