import { refreshToken } from '../apis';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useContext, useMemo } from 'react';
import CommonButton from '../components/common/buttons/CommonButton';
import CloseButton from '../components/common/buttons/CloseButton';
import CommonText from '../components/common/typographies/CommonText';
import { useNavigate, useLocation } from 'react-router-dom';
import Space from '../components/common/Space';
import { AppContext } from '../AppContext';
import Loading from '../components/common/loading';
import { Box, useMediaQuery } from '@mui/material';
import ImgCrown from '../assets/home_crownLineSticker.svg';
import ImgRegSuccessBelow from '../assets/miro_register_success_stickers.png';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import TitleGroup from '../components/common/typographies/TitleGroup';
import moment from 'moment';
import Lottie from 'react-lottie';
import * as animationDataDesktop from '../assets/animation/miro_register_success_desktop.json';
import * as animationDataMobile from '../assets/animation/miro_register_success_mobile.json';
import 'moment-timezone';

const TitleWithValue = ({ title, value, ...props }) => {
  return (
    <ResponsiveUI
      mobile={
        <Box
          style={{ width: '100%', marginBottom: 12 }}
          display={'flex'}
          flexDirection="row"
          {...props}
        >
          <CommonText
            type="common"
            textAlign="left"
            fontSize="14px"
            color="rgba(0,0,0,0.3)"
          >
            {title}
          </CommonText>
          <CommonText
            style={{ marginLeft: 10 }}
            type="common"
            textAlign="left"
            fontSize="14px"
          >
            {value}
          </CommonText>
        </Box>
      }
      desktop={
        <Box display={'flex'} flexDirection="column" {...props}>
          <CommonText
            type="common"
            textAlign="left"
            fontSize={14}
            color="rgba(0,0,0,0.3)"
          >
            {title}
          </CommonText>
          <CommonText type="common" textAlign="left" fontSize={14}>
            {value}
          </CommonText>
        </Box>
      }
    />
  );
};
export default function MiroRegisterSuccess({ session, memberType, payment }) {
  const { t, i18n } = useTranslation('signin');
  const isDesktop = useMediaQuery(breakpoint.desktop);
  const navigate = useNavigate();
  const reference_number = payment?._id;
  const amount = payment?.item?.amount;
  const date = payment?.updatedAt
    ? moment(payment.updatedAt)
        .tz('Asia/Hong_Kong')
        .locale('en')
        .format('YYYY.MM.DD hh:mma')
    : '';
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataDesktop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsMobile = {
    loop: true,
    autoplay: true,
    animationData: animationDataMobile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const isRenew = memberType == 'MIRO';
  return (
    <Container>
      {/* <CommonText fontSize="30px" lineHeight="45px">{t('register.miro_register_success')}</CommonText> */}
      <FlexContainer>
        <div>
          <StickerCrown src={ImgCrown} />
          {/* <CommonText 
                        type="primary" 
                        fontSize="60px" 
                        lineHeight="65px"
                        style={{ position: 'relative', zIndex: 99999 }}
                    >
                        {t('register.miro_register_congratulation')}
                    </CommonText> */}
          <TitleGroup
            zhTitle={
              isRenew
                ? t('register.miro_renew_success')
                : t('register.miro_register_success')
            }
            enTitle={t('register.miro_register_congratulation')}
          />
          {isRenew && i18n.language === 'en' && (
            <CommonText largeFontSize="24px" fontSize="20px" mFontSize="16px">
              you have successfully renewed your membership
            </CommonText>
          )}
        </div>
        <Space size="40px" />

        <ResponsiveUI
          mobile={
            <Box
              style={{ width: '100%', wordBreak: 'break-word' }}
              display={'flex'}
              flexDirection="column"
            >
              <TitleWithValue
                title={t('register.miro_register_amount')}
                value={`HK$${amount}`}
                mr={2}
              />
              <TitleWithValue
                title={t('register.miro_register_date')}
                value={date}
                mr={2}
              />
              {amount > 0 && (
                <TitleWithValue
                  title={t('register.miro_register_ref_number')}
                  value={reference_number}
                />
              )}
            </Box>
          }
          desktop={
            <Box display={'flex'} flexDirection="row">
              <TitleWithValue
                title={t('register.miro_register_amount')}
                value={`HK$${amount}`}
                mr={2.5}
              />
              <TitleWithValue
                title={t('register.miro_register_date')}
                value={date}
                mr={2.5}
              />
              {amount > 0 && (
                <TitleWithValue
                  title={t('register.miro_register_ref_number')}
                  value={reference_number}
                />
              )}
            </Box>
          }
        />

        <Space size="23px" />
        <ResponsiveUI
          mobile={
            <CommonText
              style={{ whiteSpace: 'pre-line' }}
              frontWeight={600}
              fontSize="16px"
              lineHeight="150%"
            >
              {isRenew
                ? t('register.miro_renew_message', {
                    deliveryDate: moment(
                      payment?.order?.deliveryDate || '2024-3-31',
                      'YYYY-M-D'
                    )
                      .locale('en')
                      .format(
                        i18n.language == 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
                      ),
                  })
                : t('register.miro_register_message', {
                    deliveryDate: moment(
                      payment?.order?.deliveryDate || '2024-3-31',
                      'YYYY-M-D'
                    )
                      .locale('en')
                      .format(
                        i18n.language == 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
                      ),
                  })}
            </CommonText>
          }
          desktop={
            <CommonText
              style={{ maxWidth: 785, whiteSpace: 'pre-line' }}
              frontWeight={600}
              fontSize="18px"
              lineHeight="150%"
            >
              {isRenew
                ? t('register.miro_renew_message', {
                    deliveryDate: moment(
                      payment?.order?.deliveryDate || '2024-3-31',
                      'YYYY-M-D'
                    )
                      .locale('en')
                      .format(
                        i18n.language == 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
                      ),
                  })
                : t('register.miro_register_message', {
                    deliveryDate: moment(
                      payment?.order?.deliveryDate || '2024-3-31',
                      'YYYY-M-D'
                    )
                      .locale('en')
                      .format(
                        i18n.language == 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
                      ),
                  })}
            </CommonText>
          }
        />
        <LottieContainer>
          <Lottie options={isDesktop ? defaultOptions : defaultOptionsMobile} />
        </LottieContainer>
        <CommonText style={{ marginBottom: 24 }}>
          {t('register.miro_2024_activity')}{' '}
        </CommonText>
        <CommonButton
          size={'large'}
          type={'primary'}
          onClick={() => {
            navigate(`/`);
          }}
        >
          {t('register.go_to_home')}
        </CommonButton>
        <CloseButton onClick={() => navigate('/', { replace: true })} />
      </FlexContainer>
    </Container>
  );
}

const FlexContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    max-width: 540px;
  }
  @media ${breakpoint.desktop} {
    max-width: 780px;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #ffaf0f;

  overflow: hidden;
  position: relative;
  padding: 100px 40px;
  @media ${breakpoint.mobile} {
    padding: 100px 20px;
    margin: auto;
  }
`;

const StickerCrown = styled.img`
  position: absolute;
  transform: translate(40%, -40%);
  width: 172px;
  z-index: 1;
`;

const LottieContainer = styled.div`
  max-width: 780px;
  margin: 24px 0;
  @media ${breakpoint.mobile} {
    max-width: 360px;
  }
`;
const ImgRegisterSuccess = styled.img`
  width: 100%;
  max-width: 500px;
`;
