import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import FormReadOnlyLabel from "../../../components/common/labels/FormReadOnlyLabel";
import FormTextfield from "../../../components/common/textfields/FormTextField";
import PhoneTextfield from "../../../components/common/textfields/PhoneTextField";
import CommonText from "../../../components/common/typographies/CommonText";
import TickIcon from "../../../components/common/icons/TickIcon";
import LanguageToggleButton from "../../../components/common/buttons/LanguageToggleButton";
import CommonButton from "../../../components/common/buttons/CommonButton";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckBox from "../../../components/common/checkboxs/Checkbox";
import CommonLabel from "../../../components/common/labels/CommonLabel";
import { AppContext } from "../../../AppContext";
import { updateProfileWithSession } from "../../../apis";
import moment from "moment";
import MirrorSelectGrid from "../../../components/common/selects/MirrorSelectGrid";
import CommonCloseDialog, {
  CommonDialog,
} from "../../../components/common/dialogs/CommonCloseDialog";
import MirrorMemberSelect from "./MirrorMemberSelect";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import Space from "../../../components/common/Space";
import ContactEmailForm from "./ContactEmailForm";
import { Grid } from "@mui/material";

const members_en = {
  王智德: "Alton",
  "江𤒹生": "Anson Kong",
  盧瀚霆: "Anson Lo",
  呂爵安: "Edan",
  陳瑞輝: "Frankie",
  陳卓賢: "Ian",
  柳應廷: "Jer",
  李駿傑: "Jeremy",
  姜濤: "Keung To",
  楊樂文: "Lokman",
  邱士縉: "Stanley",
  邱傲然: "Tiger",
};
function PersonalForm() {
  const { session, setSession } = useContext(AppContext);
  const { t, i18n } = useTranslation("profile");
  const { user } = session;

  const [changeMirrorMemberDialog, setChangeMirrorMemberDialog] =
    useState(false);
  const [saveSuccessDialog, setSaveSuccessDialog] = useState(false);
  const [addContactEmailDialog, setAddContactEmailDialog] = useState(null);

  // Form
  const [username, setUsername] = useState(null);
  const [phoneInput, setPhoneInput] = useState(null);
  const [promotionLanguage, setPromotionLanguage] = useState(null);
  const [promotionChecked, setPromotionChecked] = useState(null);
  const [promotionOtherChecked, setPromotionOtherChecked] = useState(null);

  function isSaveEnabled() {
    return (
      (username !== null && username !== user?.username) ||
      (phoneInput !== null && phoneInput !== user?.phone) ||
      (promotionLanguage !== null && promotionLanguage !== user?.language) ||
      (promotionChecked !== null && promotionChecked !== user?.promotion) ||
      (promotionOtherChecked !== null &&
        promotionOtherChecked !== user?.promotion_other)
    );
  }

  const handleUpdateMirrorMember = async (selectedMemberNames) => {
    const body = {
      supportingMembers: selectedMemberNames,
    };
    const result = await updateProfileWithSession(session.accessToken, body, {
      session,
      setSession,
    });
    if (result.success && result.data) {
      setChangeMirrorMemberDialog(false);
    }
  };

  const handleUpdateProfile = async () => {
    // update username
    const usernameBody = username !== null ? { username } : {};

    // update phone
    const phoneBody = phoneInput !== null ? { phone: phoneInput } : {};

    // update promotion
    const promotionBody =
      promotionChecked !== null ? { promotion: promotionChecked } : {};

    // update promotion other
    const promotionOtherBody =
      promotionOtherChecked !== null
        ? { promotion_other: promotionOtherChecked }
        : {};

    // update promotion language
    const promotionLanguageBody =
      promotionLanguage !== null ? { language: promotionLanguage } : {};

    const body = {
      ...usernameBody,
      ...phoneBody,
      ...promotionBody,
      ...promotionOtherBody,
      ...promotionLanguageBody,
    };
    await updateProfileWithSession(session.accessToken, body, {
      session,
      setSession,
    });
  };

  const userPromotionTick =
    promotionChecked === null ? user?.promotion : promotionChecked;
  const userPromotionOtherTick =
    promotionOtherChecked === null
      ? user?.promotion_other
      : promotionOtherChecked;
  const userPromotionLanguage =
    promotionLanguage === null ? user?.language : promotionLanguage;

  const _renderContent = (isMobile) => {
    return (
      <StyledMainDiv>
        <Row>
          {/* username */}
          <FormTextfield
            onChange={(e) => setUsername(e.target.value)}
            defaultValue={user?.username}
            label={t("profile:info.username")}
          />
        </Row>

        {
          /* Chinese name */
          user?.chineseName && (
            <Row>
              <FormReadOnlyLabel label={t("profile:info.chinese_name")}>
                {user?.chineseName}
              </FormReadOnlyLabel>
            </Row>
          )
        }

        {
          //  Eng Name
          (user?.firstName || user?.lastName) && (
            <Row>
              <FormReadOnlyLabel label={t("profile:info.eng_name")}>
                {`${user?.firstName} ${user?.lastName}`}
              </FormReadOnlyLabel>
            </Row>
          )
        }

        {
          // Birthday
          user?.birthday && (
            <Row>
              <FormReadOnlyLabel label={t("profile:info.birthday")}>
                {moment(user?.birthday).format("DD/MM/YYYY")}
              </FormReadOnlyLabel>
            </Row>
          )
        }

        {
          // Gender
          (user?.gender === "male" || user?.gender === "female") && (
            <Row>
              <FormReadOnlyLabel label={t("profile:info.gender")}>
                {user?.gender === "male" ? t("info.male") : t("info.female")}
              </FormReadOnlyLabel>
            </Row>
          )
        }

        {
          // 選擇您支持的MIRROR成員
          user?.memberType === "MIRO" && (
            <>
              <Row>
                <FormReadOnlyLabel
                  label={t("profile:info.select_mirror_member")}
                >
                  {user?.supportingMembers?.length > 0
                    ? user?.supportingMembers.map((member, idx) => {
                        return (
                          <MemberLabel>
                            {i18n.language == "zh"
                              ? member
                              : members_en[member]}
                            {user?.supportingMembers?.length !== idx + 1 && ","}
                          </MemberLabel>
                        );
                      })
                    : "--"}
                </FormReadOnlyLabel>
              </Row>

              <Row>
                {/* Update mirror member button */}
                <CommonButton
                  width={isMobile ? "100%" : "440px"}
                  type="outlined"
                  normalCase={true}
                  onClick={() => setChangeMirrorMemberDialog(true)}
                >
                  {t("profile:button.change_mirror_member")}
                </CommonButton>
              </Row>
            </>
          )
        }

        {
          // Phone
          user?.phone && (
            <Row>
              <PhoneTextfield
                onChange={(value) => setPhoneInput(value)}
                value={user?.phone}
                label={t("profile:info.phone")}
              />
            </Row>
          )
        }

        {
          // Email
          user?.email && (
            <Row>
              <FormReadOnlyLabel
                style={{ textTransform: "none" }}
                label={t("profile:info.email")}
              >
                {user?.email}
              </FormReadOnlyLabel>
            </Row>
          )
        }
        {
          // Email
          user?.contactEmail ? (
            <>
              <Row>
                <FormReadOnlyLabel
                  style={{ textTransform: "none" }}
                  label={t("profile:info.contact_email")}
                >
                  {user?.contactEmail}
                </FormReadOnlyLabel>
              </Row>
              <Row>
                {/* change contact email button */}
                <CommonButton
                  width={isMobile ? "100%" : "440px"}
                  type="outlined"
                  normalCase={true}
                  onClick={() => setAddContactEmailDialog("change")}
                >
                  {t("profile:button.change_contact_email")}
                </CommonButton>
              </Row>
            </>
          ) : (
            <Row>
              {/* add contact email button */}
              <CommonButton
                width={isMobile ? "100%" : "440px"}
                type="outlined"
                normalCase={true}
                onClick={() => setAddContactEmailDialog("add")}
              >
                {t("profile:button.add_contact_email")}
              </CommonButton>
            </Row>
          )
        }

        <Row>
          {/* 本人願意接收 mirrorweare.com 的活動推廣資訊。 */}
          <CheckBox
            onClick={() => setPromotionChecked(!userPromotionTick)}
            isChecked={userPromotionTick}
            mFontSize="14px"
          >
            <Trans t={t} i18nKey="profile:info.receive_promote" />
          </CheckBox>
        </Row>
        <Row>
          {/* 本人願意接收 mirrorweare.com 的活動推廣資訊。 */}
          <CheckBox
            onClick={() => setPromotionOtherChecked(!userPromotionOtherTick)}
            isChecked={userPromotionOtherTick}
            mFontSize="14px"
          >
            <Trans t={t} i18nKey="profile:info.receive_promote_other" />
          </CheckBox>
        </Row>
        <Row>
          {/* 本人希望取得活動推廣資訊的語言版本。 */}
          <CommonLabel style={{ textAlign: "start", textTransform: "unset" }}>
            {t("profile:info.receive_promote_language")}
          </CommonLabel>
          <LanguageToggleButton
            value={userPromotionLanguage}
            textTransform={"capitalize"}
            onChange={(event) => setPromotionLanguage(event.target.value)}
          />
        </Row>
        <Space size="4px" />
        <Row>
          <CommonButton
            width={isMobile ? "100%" : "440px"}
            normalCase={true}
            type={"primary"}
            onClick={handleUpdateProfile}
            disabled={!isSaveEnabled()}
          >
            {t("button.save_changes")}
          </CommonButton>
        </Row>

        <CommonCloseDialog
          fullScreen={isMobile}
          onClose={() => setChangeMirrorMemberDialog(false)}
          visible={changeMirrorMemberDialog}
        >
          <MirrorMemberSelect
            isMobile={isMobile}
            selectedMirrorMember={user?.supportingMembers}
            onSubmit={handleUpdateMirrorMember}
          />
        </CommonCloseDialog>
        <CommonCloseDialog
          fullScreen={isMobile}
          onClose={() => setAddContactEmailDialog(null)}
          visible={addContactEmailDialog != null}
        >
          <ContactEmailForm
            add={addContactEmailDialog == "add"}
            onClose={() => {
              setSaveSuccessDialog(true);
              setAddContactEmailDialog(null);
            }}
          />
        </CommonCloseDialog>
        <CommonCloseDialog
          showCloseButton={false}
          fullScreen={isMobile}
          onClose={() => setSaveSuccessDialog(false)}
          visible={saveSuccessDialog}
        >
          <Grid
            container
            style={{ width: "100%", height: "100%" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CommonText
              style={{ textAlign: "center" }}
              mFontSize="24px"
              fontSize="30px"
              fontWeight={600}
            >
              {t("info.save_success")}
            </CommonText>
            <Space size="10px" />
            <CommonButton
              desktopWidth={"100%"}
              size="large"
              type={"primary"}
              normalCase={true}
              onClick={() => setSaveSuccessDialog(false)}
            >
              {t("button.confirm")}
            </CommonButton>
          </Grid>
        </CommonCloseDialog>
      </StyledMainDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
`;

const MemberLabel = styled.span`
  white-space: no-wrap;
  display: inline-block;
  margin-right: 5px;
`;

export default PersonalForm;
