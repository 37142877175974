import styled from "styled-components";

const WithPageLoadAnimation = styled.div` 
  opacity: 0;
  transform: translateY(0px);
  animation: slidein 1s;
  animation-delay: ${(props) => `${props.delay}ms`};
  animation-fill-mode: forwards;

  @keyframes slidein {
    0% {
        opacity: 0;
       transform: translateY(0px);
    }
    1% {
        opacity: 0;
       transform: translateY(100px);
    }

    50% {
      opacity: 1;
      transform: translateY(0px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

`



export default WithPageLoadAnimation