import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

// import ActivityCard from "../../activity/ActivityCard";
import ActivityCard from "../../../components/home/ActivityCard";
import TitleGroup from "../../../components/common/typographies/TitleGroup";
import CommonButton from "../../../components/common/buttons/CommonButton";
import { WindowSize, breakpoint } from "../../../utils/responsive";
import { isNullOrBlank } from "../../../utils/common";

import ImgStickerArrow from "../../../assets/home_arrowSticker.svg";
import ImgActLine from "../../../assets/home_ActLineBg.svg";

function ActivitySession({ data }) {
  const { t } = useTranslation("home");
  const isMobile = WindowSize() == "mobile";

  if (isNullOrBlank(data)) {
    return null;
  } else {
    return (
      <StyledMainDiv>
        <ImageLingBg src={ImgActLine} />
        <StyledTitleMainDiv>
          <StyledTitleText>
            <TitleGroup zhTitle="特別活動" enTitle="ACTIVITY" />
          </StyledTitleText>
          <StickerArrow src={ImgStickerArrow} />
        </StyledTitleMainDiv>

        <Link to={`/activity`} style={{ textDecoration: "none" }}>
          <StyledButtonDiv>
            <CommonButton type={"outlined"} normalCase style={{width: isMobile ? '152px' : "267px"}}>
              {t("button.allActivity")}
            </CommonButton>
          </StyledButtonDiv>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Grid
            container
            alignItems="top"
            justifyContent="space-around"
            columnSpacing={{ xs: 2, md: 6 }}
            rowSpacing={{ xs: 0, sm: 12, md: 6 }}
            style={{
              padding: 20,
              width: "100%",
              marginBottom: 30,
            }}
          >
            {data &&
              data.map((activity, index) => {
                return (
                  <Grid item xs={12} md={4}>
                    <ActivityCard key={index} activity={activity} />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </StyledMainDiv>
    );
  }
}

const StyledMainDiv = styled.div`
  background-color: #c3a8ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media ${breakpoint.largeDesktop} {
    height: 100vh;
    min-height: 700px;
  }

  @media ${breakpoint.mobile} {
    padding-bottom: 50px;
  }
`;

const ImageLingBg = styled.img`
  position: absolute;
  top: 30px;
  left: 0px;
  height: 90%;
  visibility: hidden;
  user-drag: none;

  @media ${breakpoint.desktop} {
    visibility: visible;
  }
`;

const StyledTitleMainDiv = styled.div`
  position: relative;
  margin-top: 30px;
  @media ${breakpoint.largeDesktop} {
    margin-top: 6vh;
  }
`;

const StyledTitleText = styled.div`
  position: relative;
  z-index: 100;
`;

const StickerArrow = styled.img`
  user-drag: none;
  position: absolute;
  bottom: 0px;
  right: 40px;
  z-index: 1;
`;

const StyledButtonDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default ActivitySession;
