import React from "react";
import styled from "styled-components";
import CommonText from "../common/typographies/CommonText";

import IconMirror from "../../assets/home_mirrorLogo.png";
import IconMirrorStar from "../../assets/home_mirrorStarLogo.svg";
import MobileIconMirror from "../../assets/home_mirrorMobileLogo.png";
import MobileIconMirrorStar from "../../assets/home_mirrorStarMobileLogo.png";
import { Stack } from "@mui/material";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

// Sample Usage:
// <InfiniteTextLoopBar text="Jer Lau"></InfiniteTextLoopBar>

function InfiniteTextLoopBar(props) {
  let { text, color } = props;

  const _renderDesktop = () => {
    return (
      <StyledMainDiv>
        <StyledSpan1>
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
          <StyledStar src={IconMirrorStar} />
          <StyledLogo src={IconMirror} />
        </StyledSpan1>
      </StyledMainDiv>
    );
  };

  const _renderMobile = () => {
    return (
      <StyledMainDiv style={{height: 40}}>
        <StyledSpan1>
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
          <StyledStar src={MobileIconMirrorStar} />
          <StyledLogo src={MobileIconMirror} />
        </StyledSpan1>
      </StyledMainDiv>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
}

const StyledMainDiv = styled.div`
  background: #d479ff;
  width: 100%;
  height: 90px;

  overflow: hidden;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  transform: rotate(-7deg) scale(1.1);

  @media ${breakpoint.desktop} {
    margin-top: -30px;
  }
`;

const StyledLogo = styled.img`
  user-drag: none;
  transform: rotate(7deg);
  margin-right: 5px;

  @media ${breakpoint.desktop} {
    margin-right: 20px;
    margin-top: 10px;
    height: 70px;
  }
`;

const StyledStar = styled.img`
  user-drag: none;
  transform: rotate(8deg);
  margin-right: 5px;
  margin-bottom: 7px;

  @media ${breakpoint.desktop} {
    margin-right: 20px;
    height: 50px;
    margin-bottom: 10px;
  }
`;

const StyledSpan1 = styled.span`
  display: inline-block;
  animation: mymoveMobile 1.5s linear infinite;
  @keyframes mymoveMobile {
    0% {
      transform: 0;
    }
    100% {
      transform: translateX(-157px);
    }
  }

  @media ${breakpoint.desktop} {
    animation: mymoveTesktop 1.5s linear infinite;
    @keyframes mymoveTesktop {
      0% {
        transform: 0;
      }
      100% {
        transform: translateX(-317px);
      }
    }
  }
`;

export default InfiniteTextLoopBar;
