import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { createRef, useEffect, useState, useLayoutEffect } from "react";

import MemberProsBase from "../../components/home/memberProsBase";
import ImgMemProsOneLeft from "../../assets/home_memProsOneLeft.jpg";
import ImgMemProsOneRight from "../../assets/home_memProsOneRight.jpg";
import IconLike from "../../assets/home_likeSticker.svg";
import ImgMemProsTwo from "../../assets/home_memProsTwo.jpg";
import IconStar from "../../assets/home_starSticker.svg";
import ImgMemProsThreeLeft from "../../assets/home_memProsThreeLeft.jpg";
import ImgMemProsThreeRight from "../../assets/home_memProsThreeRight.jpg";
import StickerLove from "../../assets/home_loveSticker.svg";
import colors from "../../utils/colors.js";
import CommonText from "../../components/common/typographies/CommonText";
import { breakpoint, ResponsiveUI, WindowSize } from "../../utils/responsive";
import ImgLine from "../../assets/home_visitorLineBottom.png";
import { StepIcon } from "../../components/home/memberProsBase";

const MemberPros = ({ scrollProgress }) => {
  const { t, i18n } = useTranslation("home");
  const [currentStep, setCurrentStep] = useState("1");
  const isMobile = WindowSize() == "mobile";

  useEffect(() => {
    const winHeight = window.innerHeight;

    if (scrollProgress?.scrollTop > winHeight * 4 - 50) {
      setCurrentStep("3");
    } else if (scrollProgress?.scrollTop > winHeight * 3 - 50) {
      setCurrentStep("2");
    } else {
      setCurrentStep("1");
    }
  }, [scrollProgress]);

  const _renderOneImage = () => {
    return (
      <StyledOneImageDiv>
        <OneLeftDiv>
          <StyledImage src={ImgMemProsOneLeft} dWidth="70%" mWidth="90%" />
          <StyleLikeIcon src={IconLike} />
        </OneLeftDiv>
        <OneRightDiv>
          <OneRightImage src={ImgMemProsOneRight} />
        </OneRightDiv>
      </StyledOneImageDiv>
    );
  };

  const _renderTwoImage = () => {
    return (
      <StyledTwoImageDiv>
        <StyledImage src={ImgMemProsTwo} dWidth="65%" mWidth="100%" />
        <StyleStarIcon src={IconStar} />
      </StyledTwoImageDiv>
    );
  };

  const _renderThreeImage = () => {
    return (
      <Stack direction="column" style={{ paddingBottom: 80 }}>
        <StyledThreeImageDiv>
          <ThreeLeftImageDiv>
            <StyledImage src={ImgMemProsThreeLeft} dWidth="75%" mWidth="50%" />
          </ThreeLeftImageDiv>
          <ThreeRightImageDiv>
            <StyledImage src={ImgMemProsThreeRight} dWidth="75%" mWidth="80%" />
            <StyleLoveIcon src={StickerLove} />
          </ThreeRightImageDiv>
        </StyledThreeImageDiv>
        <div>
          <CommonText
            fontSize="14px"
            mFontSize="12px"
            color={colors.black60Alpha}
            style={{ marginTop: 15 }}
            textAlign="left"
          >
            {t("info.prosPhotosRemark")}
          </CommonText>
        </div>
      </Stack>
    );
  };

  return (
    <Container>
      <PageStep>
        {/* <div style={{height: 70}}></div>  */}
        <StepIcon step={currentStep} />
      </PageStep>
      <div style={{ marginTop: isMobile ? -100 : 0 }}>
        <MemberProsBase
          step="1"
          imgComponent={_renderOneImage()}
          chText="訂購MIRROR演唱會門票機會"
          enText="Opportunity to 
        purchase 
        MIRROR concert ticket"
        />
      </div>
      <div>
        <MemberProsBase
          step="2"
          imgComponent={_renderTwoImage()}
          chText="訂購MIRROR粉絲見面會門票機會"
          enText="Opportunity to 
        purchase 
        MIRROR fan meeting ticket"
        />
        <MemberProsBase
          step="3"
          imgComponent={_renderThreeImage()}
          chText="網上訂購會員專屬商品機會"
          enText="Opportunity to 
        purchase Member exclusive 
        merchandise online"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-wrap: wrap;
`;

const StyledImage = styled.img`
  object-fit: cover;
  user-drag: none;

  @media ${breakpoint.mobile} {
    width: ${(props) => (props.mWidth ? props.mWidth : "100%")};
  }

  @media ${breakpoint.desktop} {
    width: ${(props) => (props.dWidth ? props.dWidth : "100%")};
  }
`;

const OneLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

const OneRightImage = styled.img`
  object-fit: cover;
  user-drag: none;
  width: 15vw;

  @media ${breakpoint.mobile} {
    width: 30vw;
    margin-top: 30px;
  }
`;

const OneRightDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const StyledOneImageDiv = styled.div`
  display: flex;
  width: 100%;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const StyledTwoImageDiv = styled.div`
  position: relative;

  @media ${breakpoint.desktop} {
    margin-left: 50px;
  }
`;

const StyleLikeIcon = styled.img`
  z-index: 2;
  width: 20%;
  position: absolute;
  top: 20px;
  right: 20%;
  user-drag: none;

  @media ${breakpoint.mobile} {
    width: 25%;
    right: 0px;
  }
`;

const StyleStarIcon = styled.img`
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 27%;
  user-drag: none;

  @media ${breakpoint.mobile} {
    visibility: hidden;
  }

  @media ${breakpoint.desktop} {
    visibility: visible;
  }
`;

const StyledThreeImageDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
  }
`;

const ThreeLeftImageDiv = styled.div`
  @media ${breakpoint.mobile} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  @media ${breakpoint.desktop} {
    margin-right: 50px;
    margin-left: 70px;
  }
`;

const ThreeRightImageDiv = styled.div`
  position: relative;

  @media ${breakpoint.mobile} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-top: 25px;
  }
`;

const StyleLoveIcon = styled.img`
  z-index: 2;
  position: absolute;
  user-drag: none;

  @media ${breakpoint.mobile} {
    left: 0px;
    width: 25%;
    top: 20px;
  }

  @media ${breakpoint.desktop} {
    left: -20%;
    top: 0;
  }
`;

const PageStep = styled.div`
  position: sticky;
  align-self: flex-start;
  top: 80px;
  left: 90vw;
  width: 30px;
  z-index: 999;
  padding: 30px 0px;
`;

export default MemberPros;
