export const logout = ({navigate, navigateTo, setSession} = {}, callback) => {
  // TODO API logout

  // Local storage logout
  localLogout(setSession);

  // If useNavigate set, navigate to root main page.
  if (navigate) {
    navigate(navigateTo || '/')
  }

  // Logout success. callback true
  if (callback) {
    callback(true);
  }
}

export const localLogout = (setSession) => {
  // Local storage logout
  localStorage.removeItem('session');

  if (setSession) {
    setSession(null);
  }
}