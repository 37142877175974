import { useMediaQuery } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { ReactComponent as Heart } from "../../assets/join_heart.svg";
import { ReactComponent as Checked } from "../../assets/join_vote_checked.svg";
import { ReactComponent as GrayDot } from "../../assets/join_vote_unchecked.svg";
import Space from "../../components/common/Space";
import CommonText from "../../components/common/typographies/CommonText";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";

import EmojiCastle from '../../assets/join_emoji_castle_apple.png';
import EmojiHand from '../../assets/join_emoji_hand.png';
import EmojiMicrophone from '../../assets/join_emoji_microphone_apple.png';
import EmojiThought from '../../assets/join_emoji_thought_balloon_apple.png';
import EmojiVideoCam from '../../assets/join_emoji_video_camera_apple.png';
import { AnimationContext } from "../../pages/Join2024";
import ParallaxIcons from "./Parallax";
import StyledVoteContainerWithDelay from "./common/VoteContainer";
import { poll } from "../../apis";
import WithPageLoadAnimation from "./common/WithPageLoadAnimation";
import { useTranslation } from "react-i18next";


// emoji is image
const voteOptions = [
  { text: 'join2024.vote.voteOptions.0', emojiSrc: EmojiCastle },
  { text: 'join2024.vote.voteOptions.1', emojiSrc: EmojiHand },
  { text: 'join2024.vote.voteOptions.2', emojiSrc: EmojiMicrophone },
  { text: 'join2024.vote.voteOptions.3', emojiSrc: EmojiVideoCam },
];



const Vote = ({ currentAnimation }) => {
  const [votedIndex, setVotedIndex] = useState(null);
  const isLessThan600 = useMediaQuery(breakpoint.smallTablet)
  const isMobile = useMediaQuery(breakpoint.mobile)
  const { pages, getFadeInTime } = useContext(AnimationContext)
  const { t, i18n } = useTranslation("signin")

  const { data: votedData } = useQuery(["poll", votedIndex], () => poll(votedIndex), {
    enabled: votedIndex !== null
  });

  const votedPercentages = useMemo(() => {

    console.log("🚀 ~ file: Vote.js:50 ~ votedPercentages ~ votedPercentages:")
    if (votedData?.data.choices) {

      const choices = votedData.data.choices
      const percentageByChoice = { ...choices }
      const total = Object.values(choices).reduce((partialSum, a) => partialSum + a, 0);
      Object.entries(choices).forEach(([choice, count]) => {
        // not good in terms of precision
        percentageByChoice[choice] = Math.floor((count / total) * 100)
      })


      console.log("🚀 ~ file: Vote.js:54 ~ Object.entries ~ percentageByChoice:", percentageByChoice)
      // if not added to 100, round up the last item
      const totalPercentage = Object.values(percentageByChoice).reduce((partialSum, a) => { return partialSum + a }, 0);
      if (totalPercentage < 100) {
        percentageByChoice[3] = 100 - (percentageByChoice[0] + percentageByChoice[1] + percentageByChoice[2])
      }
      return percentageByChoice
    } else return null
  }, [votedData])


  const getItemPercentage = (index) => {
    if (votedData?.data.choices) {
      return votedPercentages[index]
    } else return null
  };

  const handleOptionClick = (idx) => {
    if (!votedIndex) setVotedIndex(idx);

  };
  return (
    <StyledContainerWithBackground>
      {!isMobile && <ParallaxIcons />}
      {isMobile && <WithPageLoadAnimation style={{ position: "absolute", width: 100, left: '85vw', top: -120 }}>
        <Heart style={{ width: '100%', transform: 'translateX(-40px)' }} />
      </WithPageLoadAnimation>}

      <StyledVoteContainerWithDelay delay={getFadeInTime(pages.VOTE)} className='with_page_load'>
        {!isMobile && <StyledHeart />}
        <div style={{ maxWidth: isLessThan600 ? 390 : null, minHeight: isLessThan600 ? 180 : null }}>
          <CommonText
            mFontSize="22px"
            fontSize="28px"
            fontWeight="600"
            lineHeight="140%"
            style={{whiteSpace:'pre-line'}}
          >
            {t('join2024.vote.title')}
          </CommonText>
          <ResponsiveUI
            desktop={
              <div style={{ marginBottom: "24px", marginTop: isMobile ? 8 : 16 }}>

                <CommonText
                  fontSize="16px"
                  lineHeight="160%"
                  letterSpacing="0.32px"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {t('join2024.vote.text1')}
                </CommonText>
              </div>
            }
            mobile={
              <CommonText
                fontSize="14px"
                lineHeight="160%"
                letterSpacing="0.32px"
                style={{ marginBottom: "24px" }}
              >

                {t('join2024.vote.text1')}
              </CommonText>
            }
          />
        </div>


        <StyledVoteContainer>
          {isMobile && <ParallaxIcons />}
          <CommonText mFontSize="14px" fontSize="18px" fontWeight='600' color="#FFF" style={{whiteSpace:'pre-line'}}>
            <img src={EmojiThought} alt='thought emoji' style={{ marginBottom: -8, width: isMobile ? 20 : 30 }} />{t('join2024.vote.voteTitle')}
          </CommonText>
          {voteOptions.map((option, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <StyledVoteItem
                  onClick={(e) => handleOptionClick(index)}
                  isVoted={!!votedData}
                  disabled={!!votedData}
                >
                  <StyledPercentageBar
                    percentage={getItemPercentage(index) / 100}
                    isVoted={votedIndex === index}
                  />
                  <StyledOptionWrapper>
                    {votedIndex === index ? <Checked style={{ width: 15, height: 15, marginRight: isLessThan600 ? 8 : 15, flexShrink: 0 }} /> : <StyledGrayDot style={{ width: 15, height: 15, marginRight: 16 }} />}
                    <OptionText fontSize="14px" lineHeight={isLessThan600 ? '120%' : '25px'} emojiSrc={option.emojiSrc}>
                      <span>{t(option.text)}</span>
                    </OptionText>
                  </StyledOptionWrapper>
                  {isLessThan600 && <StyledOptionEmoji
                    src={option.emojiSrc}
                    alt="emoji as icon"
                  />}
                </StyledVoteItem>
                <StyledPercentage mFontSize="14px" isVoted={!!votedData} selected={votedIndex === index}
                  fontWeight={votedIndex === index ? '700' : '400'}>{`${getItemPercentage(
                    index
                  )}%`}</StyledPercentage>
              </div>
            );
          })}
        </StyledVoteContainer>

        <Space size="24px" />
        <ResponsiveUI
          desktop={
            <CommonText style={{ whiteSpace: "pre-line" }}>
              {t("join2024.vote.text2")}
            </CommonText>
          }
          mobile={
            <div style={{ maxWidth: isLessThan600 ? 390 : null }}>
              <CommonText mFontSize="14px"
                style={{ whiteSpace: "pre-line" }}>{t("join2024.vote.text2")}</CommonText>
            </div>
          }
        />
      </StyledVoteContainerWithDelay>
    </StyledContainerWithBackground >
  );
};

const OptionText = styled(CommonText)`

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    // emoji after text > 600px
    @media ${breakpoint.onlyTablet},
           ${breakpoint.desktop}{
        span{

          margin-right: 8px;
        }
      &::after{
          background: ${props => `url(${props.emojiSrc})`} 0 0 no-repeat;
          content:"";
          background-size:contain;
          display: inline-block;
          position: relative;
           width: 25px;
          height: 25px; 
          vertical-align: middle;
          margin-bottom: -4px;
          top: -4px;
          
        }
    }
  

`


const StyledHeart = styled(Heart)`
 position: absolute;
 width: 186px; 
 top: -50px; 
 left: 100%; 
 z-index: -1;
 transform: translateX(-186px);

 @media ${breakpoint.desktop}{
  width: 156px;
  transform: translateX(-100px)

 } 
`

const StyledContainerWithBackground = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  @media ${breakpoint.desktop}{
    width: 100%;
  }
  @media ${breakpoint.mobile} {
    padding-bottom: 100px;
    width: 100%;
  }
`;



const StyledGrayDot = styled(GrayDot)`
  fill: #cacaca;
  stroke-width: 1px;
  stroke: rgba(37, 37, 37, 0.1);
  flex-shrink: 0;
`;

const StyledVoteContainer = styled.div`
  margin: auto;
  max-width: 400px;
  position: relative;

  > * + * {
    margin-top: 16px;
  }

  @media ${breakpoint.mobile} {
    max-width: 320px;
    margin: 0 auto;
    > * + * {
      margin-top: 8px;
    }
  }
  @media ${breakpoint.smallTablet} {
    max-width: 320px;
    margin: 0 40px;
  }
`;



const StyledVoteItem = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px 30px;
  box-shadow: 0 0 0 2px #DA9200;
  border-radius: 500px;
  background: #fff;
  text-align: start;
  box-sizing: content-box;
  max-height: 34px;

  @media ${breakpoint.smallTablet} {
    padding: 15.5px 20px;
    min-height: 25px;
    justify-content: space-between;
  }

  pointer-events: ${props => props.isVoted && 'none'};

  ${(props) => {
    return (
      !props.isVoted &&
      `

    cursor: pointer;
    &:hover{
      box-shadow: 0 0 0 2px #B17700;
      background: #FFEEDA;
      transition: all 250ms;
    }
  `
    );
  }}
`;


const StyledOptionEmoji = styled.img`
  font-size: 20px;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  z-index: 1
`


const StyledPercentageBar = styled.div`
  --min-width: 65px;
  background: ${(props) => (props.isVoted ? "#FEF387" : "#E4E4E4")};
  min-width: ${(props) => (props.percentage ? `var(--min-width)` : 0)};
  left: 0;
  text-align: right;
  font-size: 20px;
  border-radius: 500px;
 
  --remaining-space: calc(100% - var(--min-width));
  --extra-width: ${props => `var(--remaining-space) * ${props.percentage}`};
  width: ${(props) => props.percentage ? `calc(var(--min-width) + var(--extra-width))` : '0'};
  height: 100%;
  position: absolute;
  transition: 250ms width linear;
  
`;
const StyledPercentage = styled(CommonText)`
  position: absolute;
  margin-left: 16px;
  left: 100%;
  white-space: nowrap;

  @media ${breakpoint.mobile}{
    margin-left: 12px;
  }
  
  
  opacity: ${(props) => (props.isVoted ?
    props.selected ? 1 : 0.5
    : 0)};



  transition: 250ms all;
`;
const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  z-index: 1;
  @media ${breakpoint.mobile} {
    flex-shrink: 0;
    max-width: 100%;
  }

  @media ${breakpoint.smallTablet} {
    flex-shrink: 1;
    margin-right: 4px;
  }
`;




export default Vote;
