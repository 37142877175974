import { enrollActivity } from '../../../apis';
import PhoneTextField from '../../../components/common/textfields/PhoneTextField';
import FormTextField from '../../../components/common/textfields/FormTextField';
import { useState, useRef, useContext, useMemo } from 'react';
import { AppContext } from '../../../AppContext';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Space from '../../../components/common/Space';
import CommonButton from '../../../components/common/buttons/CommonButton';
import constants from '../../../constants';
import ReactGA from 'react-ga4';
import CheckBox from '../../../components/common/checkboxs/Checkbox';
import CommonText from '../../../components/common/typographies/CommonText';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import ReCAPTCHA from 'react-google-recaptcha';
import { MenuItem } from '@mui/material';
import { Form } from '@formio/react';
import { handleUpload } from '../../../components/common/buttons/UploadButton';
import FormSelect from '../../../components/common/selects/FormSelect';
import moment from 'moment';
import CircleOutlinedIcon from '../../../assets/home_circleOutlinedIcon.svg';
import CircleWithTick from '../../../assets/circleWithTick.svg';
import CheckboxCheckedIcon from '../../../assets/checkbox_checked.svg';
import CheckboxUnheckedIcon from '../../../assets/checkbox_unchecked.svg';
import CheckboxDisabledIcon from '../../../assets/checkbox_disabled.svg';
import RadioCheckedIcon from '../../../assets/radio_checked.svg';
import RadioUnheckedIcon from '../../../assets/radio_unchecked.svg';

export default function Formio({
  form,
  activity,
  tncPath,
  eventname,
  setRegisterResult,
  participant,
  disabled,
  id,
}) {
  const { t, i18n } = useTranslation(['common', 'activity']);
  const { session, setSession } = useContext(AppContext);
  const hkIdRef = useRef();
  // const [type, setType] = useState(null);
  const [phoneInput, setPhoneInput] = useState(null);
  const [email, setEmail] = useState(
    session.user.contactEmail || session.user.email || null
  );
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [formData, setFormData] = useState({ data: {} });
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = useRef(null);
  const fullname = session.user.lastName + ' ' + session.user.firstName;

  const validate = (needSetError = false) => {
    let error = {};
    if (agreeCheckbox === false) {
      error.agreement = t('activity:agreementCheck');
    }
    if (email === null || email === '') {
      error.email = t('activity:input_email');
    } else {
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(email)) {
        error.email = t('activity:invalid_email');
      }
    }
    if (phoneInput === null || phoneInput === '') {
      error.phone = t('activity:input_phone');
    } else {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(phoneInput)) {
        error.phone = t('activity:invalid_phone');
      }
    }
    if (activity.genderInput && !session.user.gender && !gender) {
      error.gender = t('activity:input_gender');
    }
    if (!recaptcha) {
      error.recaptcha = t('activity:missing_recaptcha');
      // setRecaptcha(null);
    }
    if (!formData?.isValid) {
      error.form = t('activity:invalid_form');
    }
    if (needSetError) setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const onSubmitHandler = async () => {
    // submit if no error
    if (!loading && validate(true)) {
      // handle formData include upload files
      let submitFormDate = { ...formData.data };
      for (let key in formData.data) {
        if (
          Array.isArray(formData.data[key]) &&
          formData.data[key].length > 0 &&
          formData.data[key][0].url
        ) {
          // upload file
          const urlPath = await handleUpload(
            formData.data[key][0],
            'form',
            activity._id + '/' + session.user.memberNo,
            true
          );
          submitFormDate[key][0].url = urlPath;
          submitFormDate.hasFile = true;
        }
      }
      const body = {
        memberNo: session.user.memberNo,
        name: fullname,
        email,
        formData: submitFormDate,
        phone: phoneInput,
        recaptcha,
      };
      if (activity.birthdayInput) {
        body.birthday = session.user.birthday;
      }
      if (activity.genderInput) {
        body.gender = gender || session.user.gender;
      }
      console.log(body);
      setLoading(true);
      const result = await enrollActivity(session.accessToken, id, body);
      if (result.success == true) {
        setRegisterResult(true);
      }
      setLoading(false);
    }
    ReactGA.event('activity_join', {
      param: eventname,
    });
  };

  let formI18n = useMemo(() => {
    let locale = { zh: {}, en: {} };
    try {
      locale =
        activity.formI18n &&
        JSON.parse(activity.formI18n).reduce(
          (acc, cur) => {
            acc.zh[cur.key] = cur.zh;
            acc.en[cur.key] = cur.en;
            return acc;
          },
          { zh: {}, en: {} }
        );
    } catch (e) {
      console.log(e);
    }
    locale.zh['Drop files to attach,'] = '拖延檔案至此';
    locale.zh['or'] = '或';
    locale.zh['browse'] = '上傳檔案';
    locale.en['Drop files to attach,'] = 'Drag and drop file here';
    locale.en['or'] = 'or';
    locale.en['browse'] = 'browser file';
    return locale;
  }, [activity.formI18n]);

  return (
    <StyledDiv style={{ opacity: disabled ? 0.5 : 1 }}>
      <Container>
        <div style={{ textAlign: 'left' }}>
          <CommonText mFontSize="14px">{t('activity:fullname')}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {fullname}
          </CommonText>
        </div>
        <div style={{ textAlign: 'left' }}>
          <CommonText mFontSize="14px">{t('activity:miroMemberId')}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {session.user.memberNo}
          </CommonText>
        </div>
        <FormTextField
          onChange={(event) =>
            setEmail(event.target.value.toLowerCase().replace(' ', ''))
          }
          disabled={disabled || !!session.user.contactEmail}
          value={participant?.email || email}
          errorText={error?.email}
          placeholder={t('activity:enter')}
          label={t('activity:email')}
          required={true}
        />
        <PhoneTextField
          onChange={(value) => setPhoneInput(value)}
          disabled={disabled}
          value={participant?.phone || phoneInput}
          errorText={error?.phone}
          placeholder={t('activity:enter')}
          label={t('activity:phone')}
          required={true}
        />
        {activity.birthdayInput && (
          <div style={{ textAlign: 'left' }}>
            <CommonText mFontSize="14px">{t('activity:birthday')}</CommonText>
            <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
              {moment(session.user?.birthday).format('DD/MM/YYYY')}
            </CommonText>
          </div>
        )}
        {activity.genderInput && (
          <>
            {participant?.gender || session.user.gender ? (
              <div style={{ textAlign: 'left' }}>
                <CommonText mFontSize="14px">{t('activity:gender')}</CommonText>
                <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
                  {t(
                    `common:gender.${
                      participant?.gender || session.user.gender
                    }`
                  )}
                </CommonText>
              </div>
            ) : (
              <div style={{ textAlign: 'left' }}>
                <FormSelect
                  label={t('activity:gender')}
                  required={true}
                  name="gender"
                  onChange={(event) => setGender(event.target.value)}
                  errorText={error?.gender}
                  value={gender}
                  placeholder={t('activity:select')}
                >
                  <MenuItem value="male">{t('common:gender.male')}</MenuItem>
                  <MenuItem value="female">
                    {t('common:gender.female')}
                  </MenuItem>
                  <MenuItem value="others">
                    {t('common:gender.others')}
                  </MenuItem>
                </FormSelect>
              </div>
            )}
          </>
        )}
        {/* <FormTextField inputRef={hkIdRef} errorText={error?.hkid} label={t("activity:identityNum")} /> */}
      </Container>
      <StyledFormContainer>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.form.io/formiojs/formio.full.min.css"
        />
        {participant ? (
          <Form
            form={form}
            options={{
              readOnly: true,
              language: i18n.language,
              i18n: formI18n,
            }}
            submission={{ data: participant.formData }}
          />
        ) : (
          <Form
            form={form}
            options={{
              language: i18n.language,
              i18n: formI18n,
            }}
            onSubmit={(payload) => onSubmitHandler(payload)}
            onChange={(payload) => {
              setFormData(payload);
            }}
          />
        )}
      </StyledFormContainer>
      <StyledStatementDiv>
        <CheckBox
          onClick={() => setAgreeCheckbox(!agreeCheckbox)}
          isChecked={disabled || agreeCheckbox}
          disabled={disabled}
        >
          <Trans
            t={t}
            i18nKey="activity:agreement"
            values={{ eventname: eventname, activity_id: activity._id }}
            components={[
              <a
                target="_blank"
                style={{ color: '#FF5C00' }}
                key={0}
                href="https://mirrorweare.com/privacy-terms"
                rel="noreferrer"
              >
                個人資料收集聲明
              </a>,
              tncPath && (
                <a
                  target="_blank"
                  style={{ color: '#FF5C00' }}
                  key={1}
                  href={tncPath}
                  rel="noreferrer"
                >
                  條款及條件
                </a>
              ),
            ]}
          />
          <span style={{ color: '#eb0000', marginLeft: 4 }}>*</span>
        </CheckBox>
        {error?.agreement && (
          <CommonText fontSize="13px" color="#dc3545">
            {error.agreement}
          </CommonText>
        )}
      </StyledStatementDiv>
      <Space size="24px"></Space>
      {!disabled && (
        <>
          <div>
            <ReCAPTCHA
              sitekey={constants.CONTACT_US_RECAPTCHA}
              onChange={(value) => setRecaptcha(value)}
              ref={recaptchaRef}
            />
            {error?.recaptcha && (
              <CommonText
                fontSize="12px"
                style={{ textAlign: 'left' }}
                color="#FF5C00"
              >
                {error.recaptcha}
              </CommonText>
            )}
            {error?.form && (
              <div>
                <Space size="24px"></Space>
                <CommonText
                  fontSize="12px"
                  style={{ textAlign: 'left' }}
                  color="#FF5C00"
                >
                  {error.form}
                </CommonText>
              </div>
            )}
          </div>
          <Space size="24px"></Space>
          <ResponsiveUI
            mobile={
              <div style={{ width: '100%' }}>
                {!loading && (
                  <CommonButton
                    width="300px"
                    onClick={() => onSubmitHandler()}
                    size={'large'}
                    type={'primary'}
                  >
                    {t('activity:submit')}
                  </CommonButton>
                )}
                {loading && (
                  <CommonButton disabled width="300px" type={'primary'}>
                    {t('activity:submit')}
                  </CommonButton>
                )}
              </div>
            }
            desktop={
              <div>
                {!loading && (
                  <CommonButton
                    onClick={() => onSubmitHandler()}
                    size={'large'}
                    type={'primary'}
                  >
                    {t('activity:submit')}
                  </CommonButton>
                )}
                {loading && (
                  <CommonButton disabled size={'large'} type={'primary'}>
                    {t('activity:submit')}
                  </CommonButton>
                )}
              </div>
            }
          />
        </>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  @media ${breakpoint.desktop} {
    padding: 0px 50px;
    max-width: 1364px;
    margin: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  margin-bottom: 20px;
  @media ${breakpoint.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 60px;
    column-gap: 25px;
    max-width: 1264px;
    margin-bottom: 60px;
  }
`;

const StyledStatementDiv = styled.div`
  width: 100%;
  text-align: left;
  @media ${breakpoint.desktop} {
    max-width: 1264px;
  }
`;

const StyledFormContainer = styled.div`
  width: 100%;
  text-align: left;
  a {
    color: #ff5c00;
    text-decoration: none !important;
    &:hover {
      color: #ff5c00;
      text-decoration: underline !important;
    }
  }
  .form-group {
    margin-bottom: 20px !important;
    @media ${breakpoint.desktop} {
      margin-bottom: 60px !important;
    }
  }
  textarea.form-control {
    color: #111111;
    border-color: rgba(0, 0, 0, 0.26);
    border-radius: 0;
    resize: none;
    line-height: 1.4375em;
    padding: 16.5px 14px;
    &:focus {
      border-color: #ff5c00;
      box-shadow: none;
    }
  }
  .card.card-body.bg-light {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.38);
    border-color: rgba(0, 0, 0, 0.26);
    border-radius: 0;
    cursor: not-allowed;
  }

  textarea.form-control:disabled {
    color: #111111;
    border-color: rgba(0, 0, 0, 0.26);
    border-radius: 0;
  }

  input[type='radio'] {
    background-position: 50% 50%;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${RadioUnheckedIcon}');
  }

  input[type='radio']:checked {
    background-image: url('${RadioCheckedIcon}');
  }

  .radio > .checkbox > label > input[type='checkbox'] {
    background-position: 50% 50%;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${CheckboxUnheckedIcon}');
  }

  .radio > .checkbox > label > input[type='checkbox']:checked {
    background-image: url('${CheckboxCheckedIcon}');
  }

  .radio > .checkbox > label > input[type='checkbox']:disabled {
    background-image: url('${CheckboxDisabledIcon}');
  }

  .formio-component-checkbox > .checkbox > label > input[type='checkbox'] {
    background-position: 50% 50%;
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${CircleOutlinedIcon}');
  }

  .formio-component-checkbox
    > .checkbox
    > label
    > input[type='checkbox']:checked {
    background-image: url('${CircleWithTick}');
  }

  .formio-component-checkbox > .checkbox > label > span {
    padding-left: 20px;
    line-height: 28px;
  }

  .checkbox > label > span {
    padding-left: 10px;
    line-height: 28px;
    -webkit-box-decoration-break: clone;
  }

  .radio > label > span {
    padding-left: 10px;
    line-height: 28px;
    -webkit-box-decoration-break: clone;
  }

  .col-form-label {
    color: #111111;
    font-size: 16px;
    @media ${breakpoint.mobile} {
      font-size: 14px;
    }
  }
  .btn-primary {
    display: none;
  }
`;
