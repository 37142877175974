import { useQuery } from "react-query";
import { getLanding } from "../apis";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import CircleAnimatedText from "../components/CircleAnimatedText";
import VistorWelcome from "../pageComponents/home/VistorWelcome";
import MemberWelcomePack from "../pageComponents/home/MemberWelcomePack";
import MemberPros from "../pageComponents/home/MemberPros";
import MemberPrice from "../pageComponents/home/MemberPrice";
import MemberWelcome from "../pageComponents/home/member/MemberWelcome";
import ActivitySession from "../pageComponents/home/member/ActivitySession";
import DiscographySession from "../pageComponents/home/member/DiscographySession";
import DisableMiroRegister from "../pageComponents/home/DisableMiroRegister";
import EshopSession from "../pageComponents/home/member/EshopSession";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import Loading from "../components/common/loading";

const RotatedMarquee = styled(Marquee)`
  margin-top: 500px;
  transform: rotate(-15deg);
`;

function Visitor({ scrollProgress }) {
  const { disableMiroReg, session } = useContext(AppContext);

  return (
    <div>
      <VistorWelcome disableMiroReg={disableMiroReg} user={session?.user} />
      {/* <MemberWelcomePack />
      <MemberPros scrollProgress={scrollProgress} />
      {disableMiroReg ? <DisableMiroRegister /> : <MemberPrice />} */}
    </div>
  );
}
function Member() {
  const { session } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ["getLanding", session?.accessToken],
    () => getLanding(session?.accessToken)
  );
  console.log(session);
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <MemberWelcome />
      <ActivitySession data={data && data.data.activites} />
      <DiscographySession data={data && data.data.discographies} />
      <EshopSession />
    </div>
  );
}
export default function Home({ scrollProgress }) {
  const { memberType } = useContext(AppContext);
  return memberType === "MIRO" ? (
    <Member />
  ) : (
    <Visitor scrollProgress={scrollProgress} />
  );
}
