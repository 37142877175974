import { Grid } from "@mui/material";
import styled from "styled-components";

const Content = ({ lang }) => {
  const _renderEnVersion = () => {
    return (
      <Grid item xs container direction="row" style={{ marginTop: 50 }}>
        <StyledDiv>
          <StyledTitle>Personal Information Collection Statement</StyledTitle>
          <StyledP>
            The personal data and other information (including without
            limitation, your name, user name, email address, delivery address,
            contact number(s), birthday information, gender, if applicable)
            (collectively, “Data”) that we, MakerVille Company Limited (a member
            of the PCCW Group) (“MakerVille”) receive about you and/or is
            provided by you as a result of or in connection with the Mirror
            Official Fan Club Membership and related services provided,
            subscribed and/or registered for (collectively, the “Services”), are
            collected, used and retained by MakerVille and, as the case may be,
            one or more of the members of the PCCW Group (being, PCCW Limited
            and its subsidiaries, affiliates and associated companies) as the
            relevant service provider(s) of the Services or other services
            provided by such members of the PCCW Group in association with
            MakerVille, in accordance with the requirements of the Personal Data
            (Privacy) Ordinance (Chapter 486 of the Laws of Hong Kong), other
            applicable laws, rules and regulations relating to data privacy and
            the respective Privacy Statement of the PCCW Group set out below
            (the “Privacy Statement”; available at{" "}
            <a href="http://www.pccw.com/privacy-statement/index.page?locale=en" target="_blank">
              http://www.pccw.com/privacy-statement/index.page?locale=en
            </a>
            ). The applicable terms and conditions of the Services, the Privacy
            Statement, and this Personal Information Collection Statement all
            apply to and govern our collection, use and disclosure of your Data.
          </StyledP>
          <StyledP>
            To the extent permitted under applicable laws, rules, regulations
            and licence requirements, your Data may be used or maintained by
            and/or disclosed to affiliates and/or related companies of the PCCW
            Group, their respective agents (including debt collection agents),
            dealers, licensors, providers, business partners and/or applicable
            regulatory bodies or governmental authorities for purposes in
            connection with the Services, including without limitation
            processing your application, providing the Services to you, enabling
            your access and retrieval of account information in relation to the
            Services, ensuring your compliance of applicable terms and
            conditions related to the Services or other services subscribed by
            you, and complying with applicable laws, rules, regulations and
            licence requirements.
          </StyledP>
          <StyledP>
            The Data not indicated as “voluntary” are mandatory in order for us
            to provide you with the Services. Data indicated as “voluntary” is
            provided by you on voluntary basis and is provided by you at your
            own choice. In the event you choose not to provide any “voluntary”
            Data, your user experience may be affected if the requested Data is
            used to enhance or personalise the Services offered or provided to
            your demographic of users.
          </StyledP>
          <StyledP>
            Our website and applications use cookies or similar tracking tools on your machine
            or device in order for us to maintain your identity across multiple
            webpages and/or Internet sessions; we also use your Data to compile
            aggregate data for performing research or statistical analysis in
            order to improve our products and services. We do not otherwise
            collect your cookies or other online identifiers, whether for the
            purposes of personalising your user experience, monitor your
            behavior, predict your preferences or otherwise. Our website and applications are
            initially set up to accept cookies. You can opt-out of or delete
            historical cookies by changing the settings on your web or device
            browsers; however, if you do so, you may find that certain features
            on our website do not work properly.
          </StyledP>
          <StyledP>
            Subject to your preference as indicated by checking the designated
            box or leaving it unchecked, we may, in compliance with applicable
            laws, rules, regulations and license requirements, use your Data
            (including without limitation, your name, user name, email address,
            delivery address, contact number(s), information about the goods and
            services you have purchased or subscribed and other information
            compiled subsequently) for us, members of the PCCW Group and our
            respective dealers, licensors, providers and/or business partners
            (collectively, “Service Providers”) to provide you, or facilitate
            our provision to you, with direct marketing content, including
            sending to you notices and/or updates about gifts, discounts,
            privileged offers, benefits and promotions related to the Services
            as well as other products and/or services offered by us, members of
            the Group and/or the Service Providers, including without
            limitation: TV, telecommunications, over-the-top (OTT) services,
            content services, mobile voice, SMS and data communications,
            IDD/roaming, Internet connectivity, cloud services, mobile payment,
            entertainment, secretarial services, personal assistant services and
            information services (such as weather, finance and news
            information), device accessories, mobile applications and software,
            computer peripheral, accessories and software (including notebooks,
            handsets, mobile devices and accessories, keyboards, security
            installations and mobile applications), reward, loyalty and
            privilege programs, lifestyle, networking events, travelling,
            banking, alcohol and tobacco, sports, music, gaming, transportation,
            household products, food and beverages, finance, insurance, wealth
            management services and products, pensions, investments, brokering,
            financial advisory, credit and other financial services and
            products, betting, education, health and wellness, beauty products
            and services, fashion and accessories, electronics, social
            networking, technology, e-commerce, digital assets and related offerings and services, logistics, retail, home and
            décor, media and high-end consumer products and services. The
            processing of your Data for the purposes of direct marketing is
            carried out in our legitimate interests.
          </StyledP>
          <StyledP>
            You have a right to withdraw your consent to the processing in the
            above manner at any time. You are also entitled to access, correct
            or enquire about the Data held by us about you. If you wish to
            withdraw your consent to our use of your Data in direct marketing,
            please do so by writing to: cs@mirrorweare.com. For all other data
            access and/or correction requests and/or general enquires about any
            Data held by us about you, or any data erasure requests, you can do so by writing to the PCCW
            Group’s Privacy Compliance Officer (address: GPO Box 9872, Hong Kong
            or via email to: privacy@pccw.com).
          </StyledP>
        </StyledDiv>
      </Grid>
    );
  };

  const _renderZhVersion = () => {
    return (
      <Grid item xs container direction="row" style={{ marginTop: 50 }}>
        <StyledDiv>
          <StyledTitle>個人資料收集聲明</StyledTitle>
          <StyledP>
            我們MakerVille Company
            Limited（電訊盈科集團成員公司）（「MakerVille」）就MIRROR OFFICIAL
            FAN CLUB
            會籍及相關服務（「本服務」）的提供、申請及／或登記或與其有關而獲取有關閣下的及/或閣下提供的個人資料和其他資料（包括但不限於閣下的姓名、用戶名稱、電郵地址、郵寄地址、聯絡號碼、生日資料、性別（如適用））（統稱「資料」）是由MakerVille及（視情況而定）一家或多家電訊盈科集團成員公司（即電訊盈科有限公司及其附屬公司、聯營公司和關聯公司）作為本服務或與MakerVille共同提供其他服務的電訊盈科集團成員的相關服務提供者，根據香港法例第486章《個人資料（私隱）條列》、與資料私隱相關的其他適用法律、規則及規例，以及電訊盈科集團的《私隱聲明》（載於
            <a href="http://www.pccw.com/privacy-statement/index.page?locale=zh" target="_blank">
              http://www.pccw.com/privacy-statement/index.page?locale=zh
            </a>
            ）（「《私隱聲明》」）的規定所收集、使用及保留。本服務的適用條款及條件、《私隱聲明》及此個人資料收集聲明均適用於及監管我們對閣下資料之收集、使用和披露。
          </StyledP>
          <StyledP>
            於適用法律、規定、規例及牌照規定允許的最大範圍內，我們可能將閣下的資料向電訊盈科集團聯營公司及／或關連公司、其各自的代理（包括債務代收代理）、零售商、授權人、供應商和業務合作夥伴及／或適用監管機構或政府機關披露而該些組織或人士或會使用及保存閣下的資料，以處理有關本服務的事項，包括但不限於處理閣下的申請、向閣下提供本服務、使閣下能進入及檢索有關本服務的帳戶資料，確保閣下遵守本服務或其他閣下訂購的服務適用的條款及條件，及履行適用法律、規定、規例及牌照規定。
          </StyledP>
          <StyledP>
            未指定為「可選填」的資料屬必須提供之資料以便我們向閣下提供本服務。指定為「可選填」的資料是以自願性質提供，閣下選擇提供指定為「可選填」的資料乃閣下自願提供。如閣下不提供指定為「可選填」的資料，而所要求提供的資料是用於閣下所屬群組的服務提升或向閣下所屬群組的用戶提供群組化的本服務，則閣下在使用本服務時的體驗或會因此而受到影響。
          </StyledP>
          <StyledP>
            我們的網站會在閣下的電腦或裝置中使用 cookies
            （小型文字檔案）或類似的追蹤工具，以便我們在多個網頁和／或互聯網段落中保留您的足跡；我們亦會使用閣下的資料來彙集群組數據以進行研究或統計分析，以改進我們的產品和服務。我們不會以其他方式收集您的
            cookies
            或其他線上識別碼（無論是用於個性化您的用戶體驗、監控您的行為、預測您的偏好或其他目的）。我們的網站在最初會設定為接受
            cookies。閣下可以透過更改閣下網頁或裝置瀏覽器的設定選擇拒絕或刪除
            cookies
            的歷史記錄；但是，閣下在更改有關設定後，可能無法如常使用我們網站及／或應用程式的某些功能。
          </StyledP>
          <StyledP>
            按照您剔選或留空指定方格所表示之意願，我們在遵守適用法律、規例、規例和牌照規定的情況下，或會使用閣下的資料（包括但不限於姓名、用戶名稱、電郵地址、郵寄地址、聯絡號碼、閣下已選購或申請的產品及服務的資料及其後編制的資料）以便我們、電訊盈科集團成員公司及我們各自的零售商、授權人、供應商及／或業務合作夥伴（統稱「服務供應商」）為閣下或協助我們為閣下提供直接促銷資料，包括向閣下發出與本服務以及由我們、電訊盈科集團成員公司及／或服務供應商所提供的其他產品及／或服務有關的禮品
            、折扣 、尊享優惠、優惠和推廣的通知及／或最新情報，包括但不限於：
            電視、電訊、OTT服務、資料服務、流動話音、短訊及數據通訊、IDD／漫遊
            、互聯網連接、雲端服務、手機付款、娛樂、秘書服務、個人助理服務和資訊服務（例如天氣、財經
            和新聞資訊）、裝置配件、流動應用程式和軟件、電腦周邊產品、配件和軟件
            （包括筆記型電腦、手提電話、流動裝置及配件、鍵盤、防禦裝置及流動應用程式）、
            獎賞、忠誠或尊貴計劃、生活方式、 社交活動、旅遊
            、銀行、酒精及煙草、運動、音樂、遊戲、交通、家居產品、餐飲、金融、保險、財富管理服務和產品、退休金、投資、經紀服務、理財顧問、信貸及其他理財服務和產品、博彩、教育、健康及保健、美容產品和服務、時尚及配飾、電子產品、社交網絡、科技、電子商務、數碼資產及相關產品和服務、物流、零售、家居佈置、媒體及高端消費類產品及服務。為直接促銷之目的處理閣下的資料時會根據我們的合法權益進行。
          </StyledP>
          <StyledP>
            閣下有權於任何時間就按照上述方式處理閣下的資料撤回同意。閣下亦有權查閱、修正或查詢我們所持有有關閣下的資料。如閣下有意撤回有關我們使用閣下的資料作直接促銷之同意，請以書面方式致函：cs@mirrorweare.com。倘若閣下就我們所持有有關閣下的資料有任何其他查詢資料及／或修正要求及／或一般查詢，或任何資料刪除要求，請以書面方式致函以下地址：
            電訊盈科集團私隱條例事務主任（香港郵政總局信箱9872號或電郵至
            privacy@pccw.com）。
          </StyledP>
        </StyledDiv>
      </Grid>
    );
  };

  return lang === "en" ? _renderEnVersion() : _renderZhVersion();
};

const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
`;

const StyledSubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: justify;
  padding-left: 0;
`;

const StyledP = styled.p`
  margin-bottom: 20px;
`;

const StyledFootnote = styled.div`
  text-align: right;
  margin-bottom: 10px;
  font-style: italic;
`;

const StyledDiv = styled.div`
  text-align: justify;
`;

const StyledUnorderedList = styled.ul`
  padding-left: 20px;
`;

export default Content;
