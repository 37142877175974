import constants from "../constants";

export const validateAddress = (address) => {
  console.log("validateAddress", address);
  if (!address) return false;
  const validPhone = new RegExp(constants.PHONE_REGEX);
  if (address.type == "Express") {
    if (
      address.regionZh &&
      address.regionEn &&
      address.areaZh &&
      address.areaEn &&
      address.address &&
      (address.address.street ||
        address.address.estate ||
        address.address.building ||
        address.address.block ||
        address.address.floor ||
        address.address.flat) &&
      address.name &&
      address.phone &&
      validPhone.test(address.phone)
    )
      return true;
  } else {
    if (
      address.regionZh &&
      address.regionEn &&
      address.areaZh &&
      address.areaEn &&
      address.address &&
      address.address.code &&
      address.name &&
      address.phone &&
      validPhone.test(address.phone)
    )
      return true;
  }
  return false;
};
