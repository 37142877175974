import styled from "styled-components";

import colors from "../../utils/colors";
import EshopInfiniteTextLoop from "../../components/home/EshopInfiniteTextLoop";
import BgRightLine from "../../assets/memProfile_rightLineBg.svg";
import ImgWowSticker from "../../assets/memProfile_wowSticker.svg";
import ImgMobileWowSticker from "../../assets/memProfile_wowMobileSticker.svg";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const ImageGallery = ({ data }) => {
  return (
    <div>
      <StyledMainDiv>
        <StyledTopDiv>
          <ResponsiveUI
            desktop={
              <EshopInfiniteTextLoop
                text="MEET MIRROR"
                color={colors.white40Alpha}
              />
            }
            mobile={<div />}
          />

          <div style={{ position: "relative" }}>
            <TopImage src={data && data.images[3] && data.images[3]} />
            <ResponsiveUI
              desktop={<StickerWow src={ImgWowSticker} />}
              mobile={<StickerMobileWow src={ImgMobileWowSticker} />}
            />
          </div>
        </StyledTopDiv>

        <StyledMiddleDiv>
          <MiddleImage src={data && data.images[4] && data.images[4]} />
        </StyledMiddleDiv>

        <StyledBottomDiv>
          <ResponsiveUI
            desktop={<StyledBgLine src={BgRightLine} />}
            mobile={<div />}
          />

          <BottomImage src={data && data.images[5] && data.images[5]} />
        </StyledBottomDiv>
      </StyledMainDiv>
    </div>
  );
};

const StyledMainDiv = styled.div`
  margin-top: 100px;
  position: relative;

  @media ${breakpoint.desktop} {
    margin-top: 250px;
  }
`;

const StyledTopDiv = styled.div`
  margin-bottom: 40px;

  @media ${breakpoint.desktop} {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }
`;

const TopImage = styled.img`
  width: 100%;
  object-fit: cover;
  padding: 0px 30px;
  user-drag: none;

  @media ${breakpoint.desktop} {
    max-width: 895px;
    position: relative;
    padding: 0px;
  }
`;
const StyledMiddleDiv = styled.div`
  height: auto;
`;

const StickerMobileWow = styled.img`
  position: absolute;
  right: 5%;
  bottom: -8vh;
  user-drag: none;
`;

const StickerWow = styled.img`
  position: absolute;
  right: 0px;
  bottom: -50%;
  user-drag: none;
`;

const MiddleImage = styled.img`
  width: 100%;
  object-fit: cover;
  user-drag: none;
`;

const StyledBottomDiv = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 20px;
`;

const StyledBgLine = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  user-drag: none;
`;

const BottomImage = styled.img`
  position: relative;
  width: 100%;
  z-index: 2;
  object-fit: cover;
  user-drag: none;

  @media ${breakpoint.desktop} {
    margin-top: 70px;
    padding: 0 10%;
  }
`;

export default ImageGallery;
