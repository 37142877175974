import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoint } from '../../utils/responsive'

const Disclaimer = (props) => {
  const { t, i18n } = useTranslation('signin')
  return (
    <>
      <StyledDisclaimer {...props}>
        <div>{t('join2024.disclaimer.0')}</div>
        <div>{t('join2024.disclaimer.1')}</div>
      </StyledDisclaimer>
    </>
  )
}

const StyledDisclaimer = styled.div`
  opacity: 0.6;
  font-size: 14px;
  text-align: start;


  display: flex;
  flex-wrap: wrap;


  column-gap: 20px;
  row-gap: 8px;



  // < 600
  @media ${breakpoint.smallTablet}{
    flex-direction: column;
  }

`

export default Disclaimer