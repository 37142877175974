import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../../utils/responsive'
import CommonText from '../../../components/common/typographies/CommonText'
import pin from '../../../assets/join_pin.png'
import BenefitCardCarousel from './BenefitCardCarousel'


export const BenefitCardVariants = {
  RED: 'red',
  BLUE: 'blue',
  LIGHTBLUE: 'lightBlue'
}
const cardStyle = {
  [BenefitCardVariants.RED]: {
    transform: "rotate(-7deg)",
    background: "#EE4027",
    foldColor: "#F79F93",
    animationStartTime: 1000,
  },
  [BenefitCardVariants.BLUE]: {
    transform: "rotate(3deg)",
    background: "#1859B7",
    foldColor: "#8BACDB",
    animationStartTime: 2000,
  },
  [BenefitCardVariants.LIGHTBLUE]: {
    transform: "rotate(10deg)",
    background: "#63B4E5",
    foldColor: "#B1DAF2",
    animationStartTime: 3000
  }
}





const MembershipBenefitCard = ({ variant, title, content, animationStartTime, ...props }) => {
  // calculate outer div width
  return (<>
    <div >
      <StyledCardWrapper variant={variant}>
        <img src={pin} alt="pin_icon" style={{ position: 'absolute', top: -25, width: 50, height: 50, left: '50%', transform: 'translateX(-50%)' }} />

        <StyledCard variant={variant}>
          <BenefitCardCarousel images={props.imgSrcList} animationStartTime={cardStyle[variant].animationStartTime} />
          <CommonText textAlign='start' color='#FFF' fontSize='24px' fontWeight='600' lineHeight='150%' style={{ marginBottom: 8 }}>{title}</CommonText>
          <CommonText textAlign='start' color='#FFF' fontSize='16px' fontWeight='400' lineHeight='150%' style={{ opacity: 0.8 }}>{content}</CommonText>

        </StyledCard>
        <StyledFoldWrapper>
          <div style={{ flex: 1, background: cardStyle[variant].background }}></div>
          <StyledFold variant={variant}></StyledFold>
        </StyledFoldWrapper>
      </StyledCardWrapper></div>
  </>


  )
}


const StyledImage = styled.img`
  width: 320px;
  height: 320px;
  @media ${breakpoint.mobile}{
    width: 280px;
    height: 280px;
  }
  margin-bottom: 24px;
`

const StyledCardWrapper = styled.div`
  position: relative;
  transform: ${(props) => cardStyle[props.variant].transform};
  margin: 10px;

  z-index: ${(props) => props.zIndex && props.zIndex};
`
const StyledCard = styled.div`
  width: 400px;
  padding: 40px;
  padding-bottom: 20px;
  background: ${(props) => cardStyle[props.variant].background};
  margin-bottom: -1px;
  @media ${breakpoint.mobile}{
    width: 344px;
    padding: 32px;
    padding-bottom: 16px;
  }

  
`

const StyledFoldWrapper = styled.div`
  display: flex;
`

const StyledFold = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 0;
  border-color: ${(props) => `${cardStyle[props.variant].foldColor} transparent transparent transparent`};
`

export default MembershipBenefitCard