import { useEffect, useMemo } from "react";
import CommonText from "../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CollapsibleItem from "../pageComponents/faq/collapsibleItem";
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import styled from "styled-components";
import Space from "../components/common/Space";
import GreySelectMenu from "../components/common/menus/GreySelectMenu";

const MENU_ITEMS = ["membership", "delivery", "payment"];

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Faq() {
  const { t, i18n } = useTranslation("faq");
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const currentId = query.get("id") || "";

  useEffect(() => {
    if (
      location.pathname === "/faq" &&
      (!location.search || !currentId || !MENU_ITEMS.includes(currentId))
    ) {
      navigate(`/faq?id=${MENU_ITEMS[0]}`, { replace: true });
    }
  }, [location, query]);

  const MEMBERSHIP_ITEMS = Object.keys(
    t("membership.questions", { returnObjects: true })
  );
  const DELIVERY_ITEMS = Object.keys(
    t("delivery.questions", { returnObjects: true })
  );
  const PAYMENT_ITEMS = Object.keys(
    t("payment.questions", { returnObjects: true })
  );

  return [
    <CommonNavBar theme="black" />,
    <GreySelectMenu
      pageId="faq"
      title={t("landing.title")}
      subTitle="FAQ"
      menuItems={MENU_ITEMS}
      currentId={currentId}
    >
      {location.search == "?id=membership" && (
        <div>
          {MEMBERSHIP_ITEMS.map((item, index) => {
            return (
              <CollapsibleItem
                header={t(`membership.questions.question${index + 1}`)}
                content={t(`membership.answers.answer${index + 1}`)}
              />
            );
          })}
        </div>
      )}

      {location.search == "?id=delivery" && (
        <div>
          {DELIVERY_ITEMS.map((item, index) => {
            return (
              <CollapsibleItem
                header={t(`delivery.questions.question${index + 1}`)}
                content={t(`delivery.answers.answer${index + 1}`)}
              />
            );
          })}
        </div>
      )}

      {location.search == "?id=payment" && (
        <div>
          {PAYMENT_ITEMS.map((item, index) => {
            return (
              <CollapsibleItem
                header={t(`payment.questions.question${index + 1}`)}
                content={t(`payment.answers.answer${index + 1}`)}
              />
            );
          })}
        </div>
      )}
      {i18n.language === "zh" ? (
        <CommonText
          style={{ textAlign: "center" }}
          type="primary"
        >{`*以上常見問題只供參考之用。有關詳情，請參閲適用的條款及條件 。`}</CommonText>
      ) : (
        <CommonText
          style={{ textAlign: "center" }}
          type="primary"
        >{`*The information provided in this FAQ is for reference only. For details, please refer to the applicable terms and conditions.`}</CommonText>
      )}
      <Space size="30px" />
    </GreySelectMenu>,
  ];
}

export default Faq;
