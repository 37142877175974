import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import { ResponsiveUI, breakpoint } from '../../utils/responsive';
import { ReactComponent as ForYou } from '../../assets/join_foryou.svg';
import MiroGift from './MembershipBenefit/MiroGift';
import Star from '../../assets/join_star.png';
import JoinRips from '../.././assets/join_rips.png';
import ExclusiveBenefit from './MembershipBenefit/ExclusiveBenefit';
import Disclaimer from './Disclaimer';
import RepectedBackground from '../../assets/join_repeat_background.png';
import { useMediaQuery } from '@mui/material';

const MembershipBenefits = ({ }) => {
  const { t, i18n } = useTranslation('signin');
  const isDesktop = useMediaQuery(breakpoint.desktop);
  const isSmallTablet = useMediaQuery(breakpoint.smallTablet);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <img
        className="sectionSeparator"
        src={JoinRips}
        style={{ minWidth: '100%', position: 'absolute', top: -36, left: 0 }}
      />
      <Container>
        <StyledHeader>
          <CommonText
            mFontSize="16px"
            fontSize="20px"
            fontWeight="600"
            lineHeight="140%"
          >
            {t('join2024.membershipBenefit.title1')}
          </CommonText>
          <CommonText
            mFontSize="26px"
            fontSize="32px"
            fontWeight="600"
            lineHeight="140%"
            style={{ margin: '8px 0' }}
          >
            {t('join2024.membershipBenefit.title2')}
          </CommonText>
          <CommonText
            mFontSize="16px"
            fontSize="20px"
            fontWeight="600"
            lineHeight="140%"
            style={{ marginBottom: isDesktop ? 10 : 16, whiteSpace: "pre-line" }}
          >

            {t('join2024.membershipBenefit.title3')}
          </CommonText>
          <div>
            <CommonText
              mFontSize="22px"
              fontSize="26px"
              fontWeight="600"
              lineHeight="140%"
            >
              {t('join2024.membershipBenefit.title4')}
            </CommonText>
            <ResponsiveUI
              mobile={
                <ForYou
                  style={{
                    position: 'absolute',
                    left: '50%',
                    right: 'auto',
                    top: '50%',
                    margin: 'auto',
                    width: 367,
                    transform: 'translateX(-50%)',
                    maxWidth: '100%',
                    zIndex: -1,
                  }}
                />
              }
              desktop={
                <ForYou
                  style={{
                    position: 'absolute',
                    left: '50%',
                    right: 'auto',
                    top: '50%',
                    margin: 'auto',
                    width: 640,
                    transform: 'translateX(-50%)',
                    zIndex: -1,
                  }}
                />
              }
            />
          </div>
        </StyledHeader>
        <MiroGift />
        <ExclusiveBenefit />
        <Disclaimer style={{ paddingLeft: 20, paddingRight: 20 }} />
        <StyledDisclaimer>{t('join2024.membershipBenefit.miss')}</StyledDisclaimer>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 60px;

  background: url(${RepectedBackground}), lightgray 0% 0% / 50px 50px repeat;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 20px;
  max-width: 1000px;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;

  @media ${breakpoint.smallTablet} {
    padding: 0 20px;
    max-width: 390px;
  }
`;

const StyledDisclaimer = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: 0.6;
  font-size: 14px;
  text-align: left;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
`

export default MembershipBenefits;
