import React from 'react'

import { ReactComponent as IconHexagon } from "../../../assets/ic-hexagon2.svg";
import CommonText from '../../../components/common/typographies/CommonText';
import styled from 'styled-components';

const RenewalBenefit = ({ title, content }) => {
  return (
    <StyledRenewalBenefit>
      <IconHexagon style={{ marginTop: 5, marginRight: 8, flexShrink: 0 }} fill='green' />
      <div>

        <CommonText fontSize='16px' fontWeight='600' lineHeight="140%">{title}</CommonText>
        <CommonText mFontSize='14px' fontSize='16px' fontWeight='400' lineHeight="140%" style={{ color: "#000000b3" }}>{content}</CommonText>
      </div>
    </StyledRenewalBenefit>
  )
}

const StyledRenewalBenefit = styled.div`
  display: flex;
`

export default RenewalBenefit