import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getPayment } from '../apis';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/common/loading';
import { useContext, useEffect, useState } from 'react';
import MiroRegisterSuccess from './MiroRegisterSuccess';
import MiroRegisterFail from './MiroRegisterFail';
import { AppContext } from '../AppContext';
import TickIcon from '../components/common/icons/TickIcon';
import CommonText from '../components/common/typographies/CommonText';
import Space from '../components/common/Space';
import CloseButton from '../components/common/buttons/CloseButton';

export default function PaymentComplete() {
  const { t, i18n } = useTranslation('paymentComplete');
  const [loading, setLoading] = useState(true);
  const { session, setSession, memberType } = useContext(AppContext);
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reference_number = searchParams.get('reference_number');
  const payment_method = searchParams.get('payment_method');
  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
    isRefetching,
    isRefetchError,
  } = useQuery(
    ['getPayment', reference_number, session?.accessToken],
    reference_number && session
      ? () => getPayment(session?.accessToken, reference_number)
      : null,
    {
      onSuccess: (data) => {
        if (data && data.success && data.data.decision) {
          setLoading(false);
          // if (
          //   data?.data?.order?.status == "new" ||
          //   data?.data?.order?.status == "open"
          // ) {
          //   window.dataLayer.push({
          //     event: "custom_event",
          //     event_name: "purchase",
          //     currency: data.data?.order?.currency,
          //     value: data.data?.order?.orderTotal,
          //     items: data.data?.order?.productItems,
          //     transaction_id: order_no,
          //   });
          // }
        } else {
          setLoading(true);
          setTimeout(() => {
            refetch();
          }, [3000]);
        }
      },
      onError: () => setLoading(false),
    }
  );
  const isPaymentSuccess = data?.data?.decision == 'ACCEPT' ? true : false;
  if (!session && payment_method == 'alipay_hk') {
    // special handle alipay
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <CommonText
          fontSize="24px"
          fontWeight={600}
          lineHeight="32px"
          color="#111111"
        >
          {t('success')}
        </CommonText>
        <Space size="30px" />
        <TickIcon />
        <Space size="15px" />
        <CommonText
          fontSize="16px"
          fontWeight={400}
          lineHeight="25px"
          color="#111111"
        >
          {t('close_app')}
        </CommonText>
        <CloseButton onClick={() => navigate('/', { replace: true })} />
      </div>
    );
  }
  if (loading) {
    return <Loading />;
  }
  if (isError || isRefetchError) {
    return <div>Error: {error?.message || ''}</div>;
  }
  if (isPaymentSuccess)
    return (
      <MiroRegisterSuccess
        session={session}
        memberType={memberType}
        payment={data.data}
      />
    );
  return (
    <MiroRegisterFail
      setSession={setSession}
      session={session}
      payment={data.data}
    />
  );
}
