import React, { useContext } from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../utils/responsive'
import { useMediaQuery } from '@mui/material'
import { AnimationContext } from '../../pages/Join2024'
import WithPageLoadAnimation from './common/WithPageLoadAnimation'
import MobileHero from '../../assets/join_hero_mobile_zh.png'
import TabletHero from '../../assets/join_hero_tablet_zh.png'
import DesktopHero from '../../assets/join_hero_web_zh.png'
import MobileHeroEn from '../../assets/join_hero_mobile_en.png'
import TabletHeroEn from '../../assets/join_hero_tablet_en.png'
import DesktopHeroEn from '../../assets/join_hero_web_en.png'
import { useTranslation } from 'react-i18next'


const Hero = () => {
  // hero image and text
  const { i18n } = useTranslation()
  const { pages, getFadeInTime } = useContext(AnimationContext)
  const isMobile = useMediaQuery(`(max-width: 600px)`)
  const isTablet = useMediaQuery(`(min-width: 601px) and (max-width: 860px)`);
  return (
    <WithPageLoadAnimation delay={getFadeInTime(pages.HERO)} className='with_page_load'>
      {i18n.language == 'zh' ? <StyledImage src={
        isMobile ?
          MobileHero :
          isTablet ?
            TabletHero
            : DesktopHero
      }
        alt='placeholder_hero_image' /> : <StyledImage src={
          isMobile ?
            MobileHeroEn :
            isTablet ?
              TabletHeroEn
              : DesktopHeroEn
        }
          alt='placeholder_hero_image' />
      }
    </WithPageLoadAnimation>

  )
}

const StyledImage = styled.img`
  width: 100%;
  @media ${breakpoint.desktop} {
    width: 120vw;
    min-width: 1000px;
  }
  max-width: 1440px;
  
  @media ${breakpoint.smallTablet}{
    margin-bottom: 140px;
    width: 440px;
  }
  @media ${breakpoint.mobile}{
    margin-bottom: 100px;
  }
`

export default Hero