import React, { useRef } from "react";
import styled from "styled-components";
import MembershipBenefitCard, {
  BenefitCardVariants,
} from "./MembershipBenefitCard";
import { breakpoint } from "../../../utils/responsive";
import CommonText from "../../../components/common/typographies/CommonText";
import OtherBenefit1 from "../../../assets/join_other_benefit1.png";
import OtherBenefit2 from "../../../assets/join_other_benefit2.png";
import Star from "../../../assets/join_star.png";
import MiroEventAssets from "../../../assets/join2024/exclusive_benefit/miro_event";
import SeeYouZoomAssets from "../../../assets/join2024/exclusive_benefit/see_you_zoom";
import TicketAssets from "../../../assets/join2024/exclusive_benefit/tickets";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";


const ExclusiveBenefit = () => {
  const { t, i18n } = useTranslation('signin')
  const benefitContainerRef = useRef()
  const isMobile = useMediaQuery(breakpoint.mobile)
  return (
    <StyledExclusiveBenefit>
      <div style={{ marginBottom: 50 }}>
        <CommonText fontSize="18px" lineHeight="140%">
          {t('join2024.membershipBenefit.enjoyMiroBenefit.title1')}
        </CommonText>
        <StyledExclusiveBenefitTitle>
          <CommonText
            mFontSize="28px"
            fontSize="36px"
            fontWeight="600"
            lineHeight="140%"
            style={{ position: "relative", padding: 10 }}
          >
            {t('join2024.membershipBenefit.enjoyMiroBenefit.title2')}

          </CommonText>
        </StyledExclusiveBenefitTitle>
      </div>

      <MembershipBenefitCardContainer>
        <MembershipBenefitCard
          imgSrcList={TicketAssets}
          variant={BenefitCardVariants.RED}
          title={t('join2024.membershipBenefit.membershipBenefitCard.benefit1.title')}
          content={t('join2024.membershipBenefit.membershipBenefitCard.benefit1.content')}
          zIndex="1"
        />
        <MembershipBenefitCard
          imgSrcList={MiroEventAssets}
          variant={BenefitCardVariants.BLUE}
          title={t('join2024.membershipBenefit.membershipBenefitCard.benefit2.title')}
          content={t('join2024.membershipBenefit.membershipBenefitCard.benefit2.content')}
          style={{ marginTop: 50 }}
        />
        <MembershipBenefitCard
          imgSrcList={SeeYouZoomAssets}
          variant={BenefitCardVariants.LIGHTBLUE}
          title={t('join2024.membershipBenefit.membershipBenefitCard.benefit3.title')}
        />

        <img
          src={Star}
          style={{
            position: "absolute",
            left: isMobile ? "105%" : "95%",
            transform: "translateX(-80px)",
            bottom: -30,
            width: 80,
            height: 80,
          }}
          alt="star asset"
        />
      </MembershipBenefitCardContainer>

      <OtherBenefitCardContainer ref={benefitContainerRef} >
        <OtherBenefitCard>
          <CommonText
            mFontSize="22px"
            fontSize="24px"
            style={{ opacity: 0.88 }}
          >{t('join2024.membershipBenefit.otherBenefitCard.benefit1.title1')}

          </CommonText>
          <CommonText
            mFontSize="28px"
            fontSize="32px"
            fontWeight="600"
            style={{ opacity: 0.88 }}
          >{t('join2024.membershipBenefit.otherBenefitCard.benefit1.title2')}

          </CommonText>
          <img src={OtherBenefit1} alt="mirror_shop_exclusive" />
        </OtherBenefitCard>
        <OtherBenefitCard>
          <CommonText
            mFontSize="28px"
            fontSize="32px"
            fontWeight="600"
            style={{ opacity: 0.88 }}
          >{t('join2024.membershipBenefit.otherBenefitCard.benefit2.title1')}

          </CommonText>
          <CommonText
            mFontSize="22px"
            fontSize="24px"
            style={{ opacity: 0.88 }}
          >{t('join2024.membershipBenefit.otherBenefitCard.benefit2.title2')}

          </CommonText>
          <img src={OtherBenefit2} alt="mirror_media_exclusive" />
        </OtherBenefitCard>
      </OtherBenefitCardContainer>
    </StyledExclusiveBenefit>
  );
};

const StyledExclusiveBenefit = styled.div``;

const StyledExclusiveBenefitTitle = styled.div`
  display: inline-block;
  border-bottom: 30px solid #ffcf74;
  position: relative;
  height: 70px;
  @media ${breakpoint.mobile} {
    height: 59px;
  }
`;

const MembershipBenefitCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;

  > div:nth-child(-n + 2) {
    margin-bottom: 50px;
  }
`;
const OtherBenefitCardContainer = styled.div`

  margin: 20px;  
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${breakpoint.desktop}{
    // grid layout for desktop
    display: grid;
    grid-template-columns: repeat(auto-fill,  420px);
    grid-column-gap: 80px;
  }
`;

const OtherBenefitCard = styled.div`
  width: 420px;
  @media ${breakpoint.mobile} {
    width: 320px;
  }

  img {
    padding: 43px 12.189px 42.864px 12px;
    aspect-ratio: 1/1;
    object-fit: contain;
    width: 100%;
  }
`;

export default ExclusiveBenefit;
