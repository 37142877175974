import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext';
import './i18n';
import constants from './constants';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Hong_Kong');
ReactGA.initialize(constants.GA4_ID);

if (!constants.IS_DEV) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const container = document.getElementById('root');
createRoot(container).render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
