import React from "react";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import SelectUndeline from "../../assets/SelectUnderline.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/zh-hk";
import { useTranslation } from "react-i18next";
import Polygon from "../../assets/Polygon_orange.png";
import { breakpoint } from "../../utils/responsive";
import LiveIndicator from "../../pageComponents/activity/detail/LiveIndicator";
import dayjs from "dayjs";

// Props(String): type, date, title, alt(for img description)
// Props(Others): src(image src), onClick
// Sample Usage: <ActivityCard type="secondary" date={'2022年4月3日'} title={'mirror 3週年生日會'} src={'https://i.kfs.io/album/global/126322086,1v1/fit/500x500.jpg'} alt="birthday"></ActivityCard>

function ActivityCard({ activity, ...props }) {
  let { size, onClick } = props;
  let navigate = useNavigate();
  const { t, i18n } = useTranslation("activity");
  let liveStart = dayjs(activity.liveStartTime);
  let liveEnd = dayjs(activity.liveEndTime);
  let currentTime = dayjs();
  const isLive =
    activity.isLive && currentTime > liveStart && currentTime < liveEnd;
  const onClickHandler = () => {
    navigate(`/activity/${activity._id}`);
  };

  return (
    <StyledMainDiv size={size} onClick={onClickHandler}>
      <StyledImage src={activity.coverImage + "-thumbnail"}></StyledImage>
      <LiveIndicatorContainer>
        <LiveIndicator t={t} isLive={isLive} />
      </LiveIndicatorContainer>
      <StyledInfoDiv size={size}>
        <div>
          <StyledDateDiv>
            <StyledDot src={Polygon}></StyledDot>
            {moment(activity.startTime).format("LLL")}
          </StyledDateDiv>
          <StyledTitleDiv>{activity.titleZh}</StyledTitleDiv>
        </div>

        <StyledCheckMore>
          <CommonText fontSize="18px" fontWeight="600" onClick={onClickHandler}>
            {t("checkNow")}
          </CommonText>
        </StyledCheckMore>
      </StyledInfoDiv>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${breakpoint.mobile} {
    margin-bottom: 40px;
  }
`;

const StyledInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px;
  background: #d1bbff;

  @media ${breakpoint.desktop} {
    height: 60%;
    min-height: 240px;
  }
`;

const StyledDateDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;

  @media ${breakpoint.mobile} {
    font-size: 16px;
  }

  @media ${breakpoint.desktop} {
    font-size: 18px;
  }

  @media ${breakpoint.largeDesktop} {
    margin-bottom: 1.1vw;
    font-size: 20px;
  }
`;

const StyledTitleDiv = styled.div`
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: auto;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${breakpoint.mobile} {
    font-size: 18px;
    font-weight: 500;
  }

  @media ${breakpoint.desktop} {
    font-size: 23px;
    font-weight: 500;
  }

  @media ${breakpoint.largeDesktop} {
    font-size: 23px;
    font-weight: 500;
  }
`;

const StyledDot = styled.img`
  height: 13px;
  width: 13px;
  margin-right: 10px;
`;

const StyledCheckMore = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-image: url(${SelectUndeline});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  @media ${breakpoint.mobile} {
    display: none;
  }
`;

const StyledImage = styled.img`
  user-drag: none;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 21/9;
`;

const LiveIndicatorContainer = styled.div`
  position: relative;
  height: 0px;
  top: -20px;
  left: 29px;
  z-index: 999;
`;

export default ActivityCard;
