import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import CommonButton from '../../components/common/buttons/CommonButton';
import CommonText from '../../components/common/typographies/CommonText';
import DotIcon from "../../assets/login_feature_dot.svg";
import Space from "../../components/common/Space";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import TitleGroup from "../../components/common/typographies/TitleGroup";
import LoginImage from "../../assets/login-image.jpg";

const Precaution = ({ children }) => {
    return (
            <ResponsiveUI
                mobile={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <img style={{ marginTop: 5 }} src={DotIcon} />
                        <div style={{ marginLeft: 10 }} />
                        <CommonText style={{ textAlign: 'left' }} fontSize="16px" lineHeight="25px">{children}</CommonText>
                    </div>
                }

                desktop={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <img style={{ marginTop: 15 }} src={DotIcon} />
                        <div style={{ marginLeft: 10 }} />
                        <CommonText style={{ textAlign: 'left' }} fontSize="18px" lineHeight="40px">{children}</CommonText>
                    </div>
                }

                largeDesktop={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <img style={{ marginTop: 15 }} src={DotIcon} />
                        <div style={{ marginLeft: 10 }} />
                        <CommonText style={{ textAlign: 'left' }} fontSize="20px" lineHeight="40px">{children}</CommonText>
                    </div>
                }
            />

    );
}
export default function Introduction({ handlePageChange }) {
    const { t } = useTranslation(['common', 'signin']);
    return (
    <div style={{width:'100%'}}>        
        <ResponsiveUI
            mobile={
                <StyledImg src={LoginImage}></StyledImg>
            }
        />
        <Container>
            <TitleGroup zhTitle={t('signin:register.miro_register_title1')} enTitle={t('signin:register.miro_register_title2')}/>
            {/* <CommonText fontSize="30px" lineHeight="45px"></CommonText>
            <Space size="5px" />
            <CommonText type="primary" fontSize="60px" lineHeight="65px"></CommonText> */}
            {/* <Space size="124px" /> */}
            <PrecautionContainer>
                <CommonText>{t('signin:register.miro_register_precautions')}</CommonText>
                <Space size="20px" />
                <Precaution>{t('signin:register.miro_register_precautions1')}</Precaution>
                <Precaution>{t('signin:register.miro_register_precautions2')}</Precaution>
                <Precaution>{t('signin:register.miro_register_precautions3')}</Precaution>
                <Precaution>{t('signin:register.miro_register_precautions4')}</Precaution>
            </PrecautionContainer>
            <Space size="64px" />
            <CommonButton type="primary" size="large" onClick={() => handlePageChange("root")}>{t('common:ok')}</CommonButton>
        </Container>
    </div>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 20px;
`
const PrecautionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 757px;
`
const StyledImg = styled.img`
    width: 100%;
    object-fit: cover;
    height: 360px;
    @media ${breakpoint.desktop} {
        display: none;
    }
`;