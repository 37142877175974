import React, { useCallback, useContext, useEffect } from "react";
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import TitleGroup from "../components/common/typographies/TitleGroup";
import colors from "../utils/colors";
import styled from "styled-components";
import { ResponsiveUI } from "../utils/responsive";
import { useQuery } from "react-query";
import { AppContext } from "../AppContext";
import { listMediaSeries } from "../apis";
import CommonBottomBar from "../components/common/navbar/CommonBottomBar";
import MediaSeriesList from "../pageComponents/mediaSeries/MediaSeriesList";
import StickerIcon from "../assets/resource_sticker.png";
import MobileStickerIcon from "../assets/resource_stickerMobile.png";
import StickerLine from "../assets/resource_line.svg";
import MobileStickerLine from "../assets/resource_lineMobile.svg";
import Space from "../components/common/Space";
import AgreementDialog from "../pageComponents/mediaSeries/AgreementDialog";
import Loading from "../components/common/loading";

const MediaSeries = () => {
  const { session } = useContext(AppContext);
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["mediaSeries", session?.accessToken],
    () => listMediaSeries(session?.accessToken)
  );

  const disableRightClick = useCallback((event) => {
    event.preventDefault();
  }, []);
  useEffect(() => {
    window.addEventListener("contextmenu", disableRightClick);
    return () => window.removeEventListener("contextmenu", disableRightClick);
  }, []);

  if (isLoading || !data) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }



  return (
    <Container>
      <CommonNavBar theme="black" />
      <TitleGroup zhTitle="多媒體區" enTitle="Media" color={colors.black} />
      <ResponsiveUI desktop={<Sticker src={StickerIcon} />} mobile={null} />
      <ResponsiveUI
        desktop={<Line src={StickerLine} style={{ width: "350px" }} />}
        mobile={<Line src={MobileStickerLine} />}
      />

      <ResponsiveUI
        desktop={<Space size="70px" />}
        mobile={
          <MobileSticker>
            <img src={MobileStickerIcon} />
          </MobileSticker>
        }
      />
      <MediaSeriesList data={data.data} />
      <CommonBottomBar theme="black" bgColor="greyBlue" />
      <AgreementDialog />
    </Container>
  );
};

const Container = styled.div`
  background-color: #b7c2cd;
  position: relative;
`;
const Sticker = styled.img`
  user-drag: none;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
`;

const MobileSticker = styled.div`
  user-drag: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const Line = styled.img`
  user-drag: none;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
`;
export default MediaSeries;
