import React from "react";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { useTranslation } from "react-i18next";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import { Link } from 'react-router-dom';

function ItemsGroup({handleClose}) {
  const { t, i18n } = useTranslation('menu');
  const _renderLargeDesktop = () => {
    return (
      <StyledDiv>
        <StyledListDiv>
            <StyledLink onClick={handleClose} to="/contact-us"><CommonText fontSize="18px">{t("contactUs")}</CommonText></StyledLink>
            <StyledLink onClick={handleClose} to="/external-link"><CommonText fontSize="18px">{t("externalLink")}</CommonText></StyledLink>
            <StyledLink onClick={handleClose} to="/faq"><CommonText fontSize="18px">{t("FAQ")}</CommonText></StyledLink>
            <StyledLink onClick={handleClose} to="/tnc"><CommonText fontSize="18px">{t("TNC")}</CommonText></StyledLink>
            <StyledLink onClick={handleClose} to="/privacy-terms"><CommonText fontSize="18px">{t("privacypolicy")}</CommonText></StyledLink>
        </StyledListDiv>
      </StyledDiv>
    );
  };

  const _renderDesktop = () => {
    return (
        <StyledDiv>
            <StyledListDiv>
                <StyledLink onClick={handleClose} to="/contact-us"><CommonText fontSize="16px">{t("contactUs")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/external-link"><CommonText fontSize="16px">{t("externalLink")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/faq"><CommonText fontSize="16px">{t("FAQ")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/tnc"><CommonText fontSize="16px">{t("TNC")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/privacy-terms"><CommonText fontSize="16px">{t("privacypolicy")}</CommonText></StyledLink>
            </StyledListDiv>
        </StyledDiv>
    );
  };

  const _renderMobile = () => {
    return (
        <StyledDiv>
            <StyledListDiv>
                <StyledLink onClick={handleClose} to="/contact-us"><CommonText fontSize="14px">{t("contactUs")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/external-link"><CommonText fontSize="14px">{t("externalLink")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/faq"><CommonText fontSize="14px">{t("FAQ")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/tnc"><CommonText fontSize="14px">{t("TNC")}</CommonText></StyledLink>
                <StyledLink onClick={handleClose} to="/privacy-terms"><CommonText fontSize="14px">{t("privacypolicy")}</CommonText></StyledLink>
            </StyledListDiv>
        </StyledDiv>
    );
  };

  return (
      <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} largeDesktop={_renderLargeDesktop()}/>
  );
}

const StyledDiv = styled.div`
    position: absolute;
    z-index: 200;
    @media ${breakpoint.desktop} {
        left: 0px;
        bottom: 10px;
    }

    @media ${breakpoint.mobile}{
        left: 15px;
        bottom: 65px;
    }
    
`


const StyledListDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 107px;
    justify-content: space-around;
    white-space: nowrap;
    @media ${breakpoint.desktop} {
        margin-left: 50px;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 5px 0;
`

export default ItemsGroup;
