import styled from "styled-components";
import CommonButton from "./common/buttons/CommonButton";
import FormTextField from "./common/textfields/FormTextField";
import FormSelect from "./common/selects/FormSelect";
import { useState, useRef, useContext } from "react";
import CommonText from "./common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import Space from "./common/Space";
import { AppContext } from "../AppContext";
import Districts from "../utils/districts.json";
import { useQuery } from "react-query";
import { listAddresses, listAreas, listRegions } from "../apis";
import { useEffect } from "react";
import PhoneTextfield from "./common/textfields/PhoneTextField";
import { Grid, MenuItem } from "@mui/material";
import _ from "lodash";
import { isNullOrBlank } from "../utils/common";
import OrangeThemeSelect from "./common/selects/OrangeThemeSelect";
import { validateAddress } from "../utils/validateAddress";

export const types = [
  {
    id: "Express",
    label: "common:address.express",
  },
  {
    id: "Store",
    label: "common:address.store",
  },
  {
    id: "Locker",
    label: "common:address.locker",
  },
  {
    id: "Service Point",
    label: "common:address.service_point",
  },
  {
    id: "Business Station",
    label: "common:address.business_station",
  },
];

export default function AddressInputForm({
  address: originalAddress,
  onChange,
  setDisableChecker,
}) {
  const { t, i18n } = useTranslation("common");
  const { session } = useContext(AppContext);
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState(
    originalAddress && originalAddress.type
      ? originalAddress
      : { type: "Express" }
  );
  //   const [error, setError] = useState(null);
  useEffect(() => {
    const valid = validateAddress(address);
    if (setDisableChecker) setDisableChecker(!valid);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const result = await listRegions(
        session?.accessToken,
        address.type,
        i18n.language
      );
      setRegions(result?.data);
    };
    if (address.type == "Express") {
      setRegions(Districts);
    } else fetch();
  }, [address.type]);
  useEffect(() => {
    const fetch = async () => {
      const result = await listAreas(
        session?.accessToken,
        address.type,
        i18n.language == "en" ? address.regionEn : address.regionZh,
        i18n.language
      );
      setAreas(result?.data);
    };
    if (address.type == "Express") {
      for (let i = 0; i < Districts.length; i++) {
        if (Districts[i].nameEn === address.regionEn) {
          setAreas(
            Districts[i].districts.sort((a, b) => {
              if (a.nameEn < b.nameEn) {
                return -1;
              }
              if (a.nameEn > b.nameEn) {
                return 1;
              }
              return 0;
            })
          );
        }
      }
    } else fetch();
  }, [address.regionZh]);
  useEffect(() => {
    const fetch = async () => {
      const result = await listAddresses(
        session?.accessToken,
        address.type,
        i18n.language == "en" ? address.areaEn : address.areaZh,
        i18n.language
      );
      setAddresses(result?.data);
    };
    fetch();
  }, [address.areaZh]);
  const handleChange = (event) => {
    let updatedAddress = null;
    if (event.target.name == "type") {
      updatedAddress = {
        ...address,
        type: event.target.value,
        regionEn: null,
        regionZh: null,
        areaEn: null,
        areaZh: null,
        address: null,
      };
    } else if (event.target.name == "region") {
      let pickedRegion = regions.filter(
        (option) =>
          option.nameEn == event.target.value ||
          option.nameZh == event.target.value
      )[0];
      updatedAddress = {
        ...address,
        regionEn: pickedRegion.nameEn,
        regionZh: pickedRegion.nameZh,
        areaEn: null,
        areaZh: null,
        address: null,
      };
    } else if (event.target.name == "area") {
      let pickedArea = areas.filter(
        (option) =>
          option.nameEn == event.target.value ||
          option.nameZh == event.target.value
      )[0];
      updatedAddress = {
        ...address,
        areaEn: pickedArea.nameEn,
        areaZh: pickedArea.nameZh,
        address: null,
      };
    } else if (event.target.name == "code") {
      let pickedAddress = addresses.filter(
        (option) => option.code == event.target.value
      )[0];
      updatedAddress = {
        ...address,
        address: pickedAddress,
      };
    } else if (
      event.target.name == "flat" ||
      event.target.name == "floor" ||
      event.target.name == "block" ||
      event.target.name == "building" ||
      event.target.name == "estate" ||
      event.target.name == "street"
    ) {
      updatedAddress = {
        ...address,
        address: {
          ...address.address,
          [event.target.name]: event.target.value,
        },
      };
    } else {
      updatedAddress = {
        ...address,
        [event.target.name]: event.target.value,
      };
    }
    const valid = validateAddress(updatedAddress);
    if (setDisableChecker) setDisableChecker(!valid);
    if (onChange) onChange(updatedAddress);
    setAddress(updatedAddress);
  };

  return (
    <>
      <div style={{ width: "100%", textAlign: "left" }}>
        <CommonText>{t("address.city")}</CommonText>
        <Space size="9px" />
        <CommonText>{t("address.hong_kong")}</CommonText>
      </div>
      <Space size="29px" />
      <OrangeThemeSelect
        label={t("address.type")}
        name="type"
        onChange={handleChange}
        value={address.type}
        options={types.map((type) => t("address." + type.id))}
      >
        {types.map((type) => (
          <MenuItem value={type.id}>{t("address." + type.id)}</MenuItem>
        ))}
      </OrangeThemeSelect>
      <Space size="20px" />
      <OrangeThemeSelect
        label={t("address.region")}
        name="region"
        // errorText={error?.region}
        onChange={handleChange}
        value={i18n.language == "en" ? address.regionEn : address.regionZh}
        options={regions.map((item) =>
          i18n.language == "en" ? item.nameEn : item.nameZh
        )}
      />
      <Space size="20px" />
      <OrangeThemeSelect
        label={t("address.area")}
        name="area"
        // errorText={error?.area}
        onChange={handleChange}
        value={i18n.language == "en" ? address.areaEn : address.areaZh}
        options={areas.map((item) =>
          i18n.language == "en" ? item.nameEn : item.nameZh
        )}
      />
      <Space size="20px" />
      {address.type == "Express" ? (
        <div>
          <CommonText
            style={{ textAlign: "left" }}
            mFontSize="14px"
            fontSize="15px"
            largeFontSize="16px"
          >
            {t("address.Express_address")}
          </CommonText>
          <Grid container flexDirection="row" spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTextField
                onChange={handleChange}
                name="flat"
                placeholder={t("address.flat")}
                value={address.address?.flat}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTextField
                onChange={handleChange}
                name="floor"
                value={address.address?.floor}
                placeholder={t("address.floor")}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTextField
                onChange={handleChange}
                name="block"
                value={address.address?.block}
                placeholder={t("address.block")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                onChange={handleChange}
                value={address.address?.building}
                name="building"
                placeholder={t("address.building")}
              />
            </Grid>
          </Grid>
          <FormTextField
            onChange={handleChange}
            value={address.address?.estate}
            name="estate"
            placeholder={t("address.estate")}
          />
          <FormTextField
            onChange={handleChange}
            value={address.address?.street}
            name="street"
            placeholder={t("address.street")}
          />
        </div>
      ) : (
        <OrangeThemeSelect
          label={t("address." + address.type + "_address")}
          name="code"
          onChange={handleChange}
          value={address.address?.code}
        >
          {addresses.map((addressOption) => {
            let address =
              i18n.language == "zh" ? addressOption.zh : addressOption.en;
            return (
              <MenuItem
                value={addressOption.code}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                }}
              >
                <MenuItemText>
                  {address.shop} - {addressOption.code}
                </MenuItemText>
                <MenuItemText fontSize="12px">{address.address}</MenuItemText>
              </MenuItem>
            );
          })}
        </OrangeThemeSelect>
      )}
      <Space size="20px" />
      <FormTextField
        name="name"
        onChange={handleChange}
        value={address.name}
        label={t("address.name")}
      />
      <Space size="20px" />
      <PhoneTextfield
        name="phone"
        onChange={(phone) =>
          handleChange({
            target: {
              name: "phone",
              value: phone,
            },
          })
        }
        value={address.phone}
        label={t("address.phone")}
      />
    </>
  );
}

const MenuItemText = styled.div`
  font-family: Open Sans;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  text-align: left;
  white-space: normal;
`;
