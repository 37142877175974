import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import ProfileButton from '../buttons/ProfileButton';
import LanguageButton from '../buttons/LanguageButton';
import CommonButton from '../buttons/CommonButton';
import ImageButton from '../buttons/ImageButton';
import CommonText from '../typographies/CommonText';
import NavBlackLogo from '../../../assets/nav_blackLogo.svg';
import NavWhiteLogo from '../../../assets/nav_whiteLogo.svg';
import NavBlackBurger from '../../../assets/nav_blackBurger.svg';
import NavWhiteBurger from '../../../assets/nav_whiteBurger.svg';
import CloseIcon from '../../../assets/nav_cross.svg';
import Menu from '../../Menu';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import MuiMenu from '@mui/material/Menu';
import { alpha, Grid, MenuItem } from '@mui/material';
import { ProfileMenuItem } from '../../../pages/ProfileMenu';
import { logout } from '../../../utils/logout';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import LoginRegisterButton from '../buttons/LoginRegisterButton';
import JoinBanner from './JoinBanner';
import moment from 'moment';
import constants from '../../../constants';

const StyledMenu = styled((props) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    // marginTop: theme.spacing(1),
    minWidth: 208,
    color: 'white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
      '&:active': {
        backgroundColor: 'white',
      },
    },
  },
}));

function CommonNavBar(props) {
  const { t } = useTranslation(['common', 'profile', 'menu']);
  const { memberType, session, setSession, disableMiroReg } =
    useContext(AppContext);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let { theme, handleClose, whiteMenu } = props;
  const login =
    session?.user?.memberType == 'MIRO' || session?.user?.memberType == 'WEB';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const username = session?.user?.username;
  const [openBanner, setOpenBanner] = useState(null);

  useEffect(()=>{
    props.onOpenBanner && props.onOpenBanner(openBanner)
  },[openBanner])

  useEffect(() => {
    if (session?.user?.renewPrice < 0) {
      // renewed
      if (memberType != session?.user?.memberType) {
        // waiting for 2024
        let lastShowWaitingBanner = localStorage.getItem(
          'waitingMiroBanner',
          null
        );
        console.log(
          'kazaf ',
          moment().diff(moment(lastShowWaitingBanner), 'hours')
        );
        if (
          !lastShowWaitingBanner ||
          moment().diff(moment(lastShowWaitingBanner), 'hours') >= 24
        ) {
          setOpenBanner('waitingMiro');
        }
      } else {
        // existing MIRO
      }
    } else if (!disableMiroReg) {
      // need renew
      if (session?.user?.memberType === 'MIRO') {
        // renew
        let lastShowRenewBanner = localStorage.getItem('renewMiroBanner', null);
        if (
          !lastShowRenewBanner ||
          moment().diff(moment(lastShowRenewBanner), 'hours') > 24
        ) {
          setOpenBanner('renewMiro');
        }
      } else {
        // new join
        let lastShowNewJoinBanner = localStorage.getItem(
          'joinMiroBanner',
          null
        );

        if (
          !lastShowNewJoinBanner ||
          moment().diff(moment(lastShowNewJoinBanner), 'hours') > 24
        ) {
          setOpenBanner('joinMiro');
        }
      }
    }
  }, [session]);

  const handleCloseBanner = () => {
    localStorage.setItem(openBanner + 'Banner', moment().format());
    setOpenBanner(null);
  };

  function handleLogout() {
    setAnchorEl(null);
    logout({ navigate, setSession });
  }

  return (
    <div style={{ width: '100%' }}>
      {openBanner && (
        <JoinBanner type={openBanner} handleClose={handleCloseBanner} />
      )}

      <StyledMainDiv openBanner={openBanner}>
        {handleClose ? (
          <StyledLeftDiv openBanner={openBanner}>
            <ImageButton onClick={handleClose} src={CloseIcon} />
            <ResponsiveUI mobile={<div></div>} desktop={<LanguageButton />} />
          </StyledLeftDiv>
        ) : (
          <StyledLeftDiv openBanner={openBanner}>
            <ImageButton
              onClick={() => setOpen(true)}
              src={theme === 'black' ? NavBlackBurger : NavWhiteBurger}
            />
            <Menu open={open} handleClose={() => setOpen(false)} />
          </StyledLeftDiv>
        )}
        {/* onClick={ toHome() } */}
        <StyledLogo
          openBanner={openBanner}
          src={theme === 'black' ? NavBlackLogo : NavWhiteLogo}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        ></StyledLogo>

        <StyledRightDiv openBanner={openBanner}>
          {/* 商店 */}
          {/* <ShopButton></ShopButton> */}
          {/* <StyledShopDiv>
          <CommonText style={{ fontSize: "18px", fontWeight: "600", color: theme }} onClick={true} >
            {t("shop")}
          </CommonText>
        </StyledShopDiv> */}

          {/* {!login && <LoginButton></LoginButton>} */}
          {/* Get Name From API */}
          {!login && (
            <Link to={'/signin'} style={{ textDecoration: 'none' }}>
              <LoginRegisterButton whiteMenu={whiteMenu}></LoginRegisterButton>
            </Link>
          )}
          {login && (
            <ProfileButton
              handleClose={handleClose}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              name={username}
              blackTheme={theme === 'black'}
            ></ProfileButton>
          )}
        </StyledRightDiv>

        <StyledMenu
          elevation={0}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          keepMounted
          style={{ zIndex: 9999 }}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <StyledProfileMenuContainer>
            <Link
              to={`/${ProfileMenuItem.PROFILE_INFO}`}
              style={{ textDecoration: 'none' }}
            >
              <CommonText fontWeight="600" size="18px" lineHeight="24px">
                {t('profile:menu.mange_personal_info')}
              </CommonText>
            </Link>

            <StyledProfileMenuRow>
              <Link
                to={`/${ProfileMenuItem.PROFILE_ORDER}`}
                style={{ textDecoration: 'none' }}
              >
                <CommonText fontWeight="600" size="18px" lineHeight="24px">
                  {t('profile:menu.my_order')}
                </CommonText>
              </Link>
            </StyledProfileMenuRow>

            {session?.user?.memberExpiresAt && (
              <StyledProfileMenuRow>
                <Link
                  to={`/${ProfileMenuItem.PROFILE_MEMBERSHIP}`}
                  style={{ textDecoration: 'none' }}
                >
                  <CommonText fontWeight="600" size="18px" lineHeight="24px">
                    {t('profile:menu.my_membership')}
                  </CommonText>
                </Link>
              </StyledProfileMenuRow>
            )}

            <StyledProfileMenuRow>
              <Link to={`/`} style={{ textDecoration: 'none' }}>
                <CommonText
                  onClick={handleLogout}
                  fontWeight="600"
                  size="18px"
                  lineHeight="24px"
                >
                  {t('profile:menu.logout')}
                </CommonText>
              </Link>
            </StyledProfileMenuRow>
          </StyledProfileMenuContainer>
        </StyledMenu>
      </StyledMainDiv>
    </div>
  );
}

const StyledProfileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px;
`;
const StyledProfileMenuRow = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;

const StyledMainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  @media ${breakpoint.mobile} {
    min-height: ${(props) => (props.openBanner ? '113px' : '60px')};
    padding-top: 10px;
    margin-bottom: 20px;
  }

  @media ${breakpoint.desktop} {
    min-height: ${(props) => (props.openBanner ? '153px' : '100px')};
  }

  @media ${breakpoint.largeDesktop} {
    min-height: ${(props) => (props.openBanner ? '183px' : '130px')};
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 76px;
  margin-top: ${(props) => (props.openBanner ? '78px' : '0px')};

  @media ${breakpoint.mobile} {
    height: 44px;
    margin-top: ${(props) => (props.openBanner ? '50px' : '0px')};
  }

  @media ${breakpoint.desktop} {
    height: 60px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 80px;
  }
`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  right: 16px;
  top: ${(props) => (props.openBanner ? '69px' : '18px')};
  @media ${breakpoint.desktop} {
    right: 50px;
    top: ${(props) => (props.openBanner ? '80px' : '35px')};
  }
`;

const StyledLeftDiv = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  left: 16px;
  top: ${(props) => (props.openBanner ? '69px' : '23px')};
  @media ${breakpoint.desktop} {
    left: 50px;
    top: ${(props) => (props.openBanner ? '93px' : '55px')};
  }
`;

const StyledShopDiv = styled.div`
  cursor: pointer;
  margin-right: 50px;
`;

export default CommonNavBar;
