import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import CommonText from '../typographies/CommonText';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../../../assets/menu_comingsoon.png';
import MiroZone from '../../../assets/menu_mirozone.png';
import MiroZoneEn from '../../../assets/miro_mirozoneEn.png';
import CommonImage from '../images/CommonImage';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import { Link } from 'react-router-dom';

function DisabledMenuItem(props) {
  const { t, i18n } = useTranslation();
  let { type, zhTitle, enTitle, link } = props;

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <StyledDiv>
        <StyledImgDiv lang={i18n.language}>
          <ResponsiveUI
            mobile={
              <div>
                {type === 'miro' ? (
                  i18n.language === 'zh' ? (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="66px"
                      width="93px"
                      src={MiroZone}
                    ></StyledImg>
                  ) : (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="66px"
                      width="93px"
                      src={MiroZoneEn}
                    ></StyledImg>
                  )
                ) : i18n.language === 'zh' ? (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="66px"
                    width="93px"
                    src={ComingSoon}
                  ></StyledImg>
                ) : (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="66px"
                    width="93px"
                    src={ComingSoon}
                  ></StyledImg>
                )}
              </div>
            }
            desktop={
              <div>
                {type === 'miro' ? (
                  i18n.language === 'zh' ? (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="101px"
                      width="168px"
                      src={MiroZone}
                    ></StyledImg>
                  ) : (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="101px"
                      width="168px"
                      src={MiroZoneEn}
                    ></StyledImg>
                  )
                ) : i18n.language === 'zh' ? (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="101px"
                    width="168px"
                    src={ComingSoon}
                  ></StyledImg>
                ) : (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="101px"
                    width="168px"
                    src={ComingSoon}
                  ></StyledImg>
                )}
              </div>
            }
            largeDesktop={
              <div>
                {type === 'miro' ? (
                  i18n.language === 'zh' ? (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="136px"
                      width="243px"
                      src={MiroZone}
                    ></StyledImg>
                  ) : (
                    <StyledImg
                      style={{ objectFit: 'contain' }}
                      height="136px"
                      width="243px"
                      src={MiroZoneEn}
                    ></StyledImg>
                  )
                ) : i18n.language === 'zh' ? (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="136px"
                    width="243px"
                    src={ComingSoon}
                  ></StyledImg>
                ) : (
                  <StyledImg
                    style={{ objectFit: 'contain' }}
                    height="136px"
                    width="243px"
                    src={ComingSoon}
                  ></StyledImg>
                )}
              </div>
            }
          />
        </StyledImgDiv>

        <StyledMenuItem disabled disableRipple>
          {i18n.language === 'zh' && (
            <ResponsiveUI
              mobile={
                <div>
                  <CommonText
                    style={{
                      textTransform: 'uppercase',
                      lineHeight: '25px',
                      fontSize: '16px',
                      color: 'black',
                      fontWeight: '400',
                    }}
                  >
                    {zhTitle ? zhTitle : ''}
                  </CommonText>
                </div>
              }
              desktop={
                <div>
                  <CommonText
                    style={{
                      textTransform: 'uppercase',
                      lineHeight: '25px',
                      fontSize: '20px',
                      color: 'black',
                      fontWeight: '400',
                    }}
                  >
                    {zhTitle ? zhTitle : ''}
                  </CommonText>
                </div>
              }
              largeDesktop={
                <div>
                  <CommonText
                    style={{
                      textTransform: 'uppercase',
                      lineHeight: '45px',
                      fontSize: '30px',
                      color: 'black',
                      fontWeight: '400',
                    }}
                  >
                    {zhTitle ? zhTitle : ''}
                  </CommonText>
                </div>
              }
            />
          )}

          <ResponsiveUI
            mobile={
              <CommonText
                type="primary"
                style={{
                  textTransform: 'uppercase',
                  lineHeight: '40px',
                  fontSize: '33px',
                  color: 'black',
                  fontWeight: '400',
                }}
              >
                {enTitle ? enTitle : ''}
              </CommonText>
            }
            desktop={
              <div>
                <CommonText
                  type="primary"
                  style={{
                    textTransform: 'uppercase',
                    lineHeight: '50px',
                    fontSize: '40px',
                    color: 'black',
                    fontWeight: '400',
                  }}
                >
                  {enTitle ? enTitle : ''}
                </CommonText>
              </div>
            }
            largeDesktop={
              <div>
                <CommonText
                  type="primary"
                  style={{
                    textTransform: 'uppercase',
                    lineHeight: '65px',
                    fontSize: '60px',
                    color: 'black',
                    fontWeight: '400',
                  }}
                >
                  {enTitle ? enTitle : ''}
                </CommonText>
              </div>
            }
          />
        </StyledMenuItem>
      </StyledDiv>
    </Link>
  );
}

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    flex-direction: column;
    position: relative;
    &.MuiMenuItem-root{
        margin-bottom: 10px;
        &:hover{
            background-color: transparent;
        }
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
`;

const StyledImgDiv = styled.div`
  position: absolute;
  z-index: 100;
  @media ${breakpoint.mobile} {
    margin-top: ${(props) => (props.lang === 'en' ? '-5px' : '0px')};
  }
  @media ${breakpoint.desktop} {
    margin-top: ${(props) => (props.lang === 'en' ? '-10px' : '0px')};
  }
  @media ${breakpoint.largeDesktop} {
    margin-top: ${(props) => (props.lang === 'en' ? '-20px' : '0px')};
  }
`;

const StyledImg = styled(CommonImage)``;

export default DisabledMenuItem;
