import React from "react";
import styled from "styled-components";
import CommonText from "./CommonText";
import { useTranslation } from "react-i18next";
import { ResponsiveUI } from "../../../utils/responsive";
import ExternalLinkIcon from "../../../assets/external_link.svg";

// Options(string): zhTitle, enTitle
// Sample Usage: <TitleGroup zhTitle="特別活動" enTitle="ACTIVITY"></TitleGroup>

function TitleGroup(props) {
  const { i18n } = useTranslation();
  let { zhTitle, enTitle, color, external = false } = props;

  const _renderLargeDesktop = () => {
    return (
      <StyledDiv>
        {i18n.language === "zh" && (
          <CommonText
            style={{
              textTransform: "uppercase",
              lineHeight: "45px",
              fontSize: "30px",
              fontWeight: "400",
            }}
            color={color}
          >
            {zhTitle ? zhTitle : ""}
          </CommonText>
        )}

        <ENTitleContainer>
          <CommonText
            type="primary"
            style={{
              textTransform: "uppercase",
              lineHeight: "65px",
              fontSize: "60px",
              fontWeight: "400",
            }}
            color={color}
          >
            {enTitle ? enTitle : ""}
          </CommonText>
          {external && (
            <img style={{ marginLeft: 10 }} src={ExternalLinkIcon} />
          )}
        </ENTitleContainer>
      </StyledDiv>
    );
  };

  const _renderDesktop = () => {
    return (
      <StyledDiv>
        {i18n.language === "zh" && (
          <CommonText
            style={{
              textTransform: "uppercase",
              fontSize: "20px",
              lineHeight: "25px",
              fontWeight: "400",
            }}
            color={color}
          >
            {zhTitle ? zhTitle : ""}
          </CommonText>
        )}

        <ENTitleContainer>
          <CommonText
            type="primary"
            style={{
              textTransform: "uppercase",
              fontSize: "40px",
              lineHeight: "50px",
              fontWeight: "400",
            }}
            color={color}
          >
            {enTitle ? enTitle : ""}
          </CommonText>
          {external && (
            <img style={{ marginLeft: 10 }} src={ExternalLinkIcon} />
          )}
        </ENTitleContainer>
      </StyledDiv>
    );
  };

  const _renderMobile = () => {
    return (
      <StyledDiv>
        {i18n.language === "zh" && (
          <CommonText
            style={{
              textTransform: "uppercase",
              fontSize: "16px",
              lineHeight: "25px",
              fontWeight: "400",
            }}
            color={color}
          >
            {zhTitle ? zhTitle : ""}
          </CommonText>
        )}
        <ENTitleContainer>
          <CommonText
            type="primary"
            style={{
              textTransform: "uppercase",
              fontSize: "33px",
              lineHeight: "40px",
              fontWeight: "400",
            }}
            color={color}
          >
            {enTitle ? enTitle : ""}
          </CommonText>
          {external && (
            <img style={{ marginLeft: 10 }} src={ExternalLinkIcon} />
          )}
        </ENTitleContainer>
      </StyledDiv>
    );
  };

  return (
    <StyledDiv noMarginBottom={true}>
      <ResponsiveUI
        mobile={_renderMobile()}
        desktop={_renderDesktop()}
        largeDesktop={_renderLargeDesktop()}
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.noMarginBottom ? "0px" : "10px")};
  z-index: 50;
`;

const ENTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default TitleGroup;
