import { socialRegister, appleRegister } from "../../apis";
import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import FormTextField from "../../components/common/textfields/FormTextField";
import { useState, useRef, useContext } from "react";
import LanguageToggleButton from "../../components/common/buttons/LanguageToggleButton";
import CheckBox from "../../components/common/checkboxs/Checkbox";
import CommonText from "../../components/common/typographies/CommonText";
import { Trans, useTranslation } from "react-i18next";
import Space from "../../components/common/Space";
import { AppContext } from "../../AppContext";
import constants from "../../constants";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Grid } from "@mui/material";
import PhoneTextfield from "../../components/common/textfields/PhoneTextField";
import EmailTextfield from "../../components/common/textfields/EmailTextField";
import DownArrowIcon from "../../assets/down_arrow.svg";
import { ResponsiveUI } from "../../utils/responsive";
import CommonLabel from "../../components/common/labels/CommonLabel";
import ReactGA from "react-ga4";

export default function SocialRegister({ handlePageChange, type, profile }) {
  console.log("profile", profile);
  const { t } = useTranslation(["common", "profile", "signin"]);
  const [loading, setLoading] = useState(false);
  const [showContactEmail, setShowContactEmail] = useState(false);
  const [language, setLanguage] = useState("chinese");
  const [phone, setPhone] = useState(null);
  const [promotion, setPromotion] = useState(false);
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const { setSession } = useContext(AppContext);
  const emailRef = useRef();
  const emailOTPRef = useRef();
  const usernameRef = useRef();
  // const recaptchaRef = useRef(null);
  const onReCaptchaChange = (value) => {
    setRecaptcha(value);
  };
  const validate = () => {
    let error = {};
    if (
      usernameRef.current.value.length < 2 ||
      usernameRef.current.value.length > 50
    )
      error.username = t("signin:register.invalid_username");
    if (phone && phone != "") {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(phone))
        error.phone = t("signin:register.invalid_phone");
    }
    if (showContactEmail) {
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(emailRef.current.value))
        error.email = t("signin:register.invalid_email");
      if (emailOTPRef.current.value.length != 6)
        error.emailOTP = t("signin:register.invalid_email_otp");
    }
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleRegister = async () => {
    if (!loading && validate()) {
      if (type == "apple") {
        setLoading(true);
        const result = await appleRegister(
          profile.code,
          profile.lastName,
          profile.firstName,
          "WEB",
          usernameRef.current.value,
          phone,
          promotion,
          language,
          showContactEmail ? emailRef.current.value : null,
          showContactEmail ? emailOTPRef.current.value : null
        );
        console.log(result);
        if (result.success) {
          localStorage.setItem("session", JSON.stringify(result.data));
          setSession(result.data);
          ReactGA.event("sign_up", {
            method: type,
          });
        } else {
          // set api error
        }
        // recaptchaRef.current.reset();
        setLoading(false);
      } else if (type == "facebook" || type == "google") {
        setLoading(true);
        const result = await socialRegister(
          type,
          profile[type + ".accessToken"],
          "WEB",
          usernameRef.current.value,
          phone,
          promotion,
          language,
          showContactEmail ? emailRef.current.value : null,
          showContactEmail ? emailOTPRef.current.value : null
        );
        console.log(result);
        if (result.success) {
          localStorage.setItem("session", JSON.stringify(result.data));
          setSession(result.data);
          ReactGA.event("sign_up", {
            method: type,
          });
        } else {
          // set api error
        }
        // recaptchaRef.current.reset();
        setLoading(false);
      }
    }
  };
  return (
    <Container>
      <Space size="100px" />
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {t("signin:register.title")}
      </CommonText>
      <Space size="82px" />
      <FormTextField
        inputRef={usernameRef}
        errorText={error?.username}
        label={t("signin:register.username")}
      />
      <Space size="20px" />
      <CommonLabel style={{ width: "100%", textAlign: "left" }}>
        {t("signin:register.email")}
      </CommonLabel>
      <ResponsiveUI
        desktop={
          <Grid container justifyContent="space-between">
            <CommonLabel style={{ textAlign: "left" }}>
              {profile?.email}
            </CommonLabel>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={() => setShowContactEmail(!showContactEmail)}
            >
              <CommonLabel style={{ cursor: "pointer" }}>
                {t("signin:register.add_contact_email")}
              </CommonLabel>
              {!showContactEmail ? (
                <img
                  style={{ width: 20, transform: "rotate(0)" }}
                  src={DownArrowIcon}
                />
              ) : (
                <img
                  style={{ width: 20, transform: "rotate(180deg)" }}
                  src={DownArrowIcon}
                />
              )}
            </div>
          </Grid>
        }
        mobile={
          <Grid container flexDirection="column" justifyContent="flex-start">
            <CommonLabel style={{ textAlign: "left" }}>
              {profile?.email}
            </CommonLabel>
            <Space size="10px" />
            <Grid
              container
              justifyContent="flex-start"
              onClick={() => setShowContactEmail(!showContactEmail)}
            >
              <CommonLabel style={{ cursor: "pointer" }}>
                {t("signin:register.add_contact_email")}
              </CommonLabel>
              {!showContactEmail ? (
                <img
                  style={{ width: 20, transform: "rotate(0)" }}
                  src={DownArrowIcon}
                />
              ) : (
                <img
                  style={{ width: 20, transform: "rotate(180deg)" }}
                  src={DownArrowIcon}
                />
              )}
            </Grid>
          </Grid>
        }
        mobileOffset={300}
      />
      {showContactEmail && (
        <>
          <Space size="20px" />
          <EmailTextfield
            contactHint={true}
            errorText={error?.email}
            inputRef={emailRef}
            label={t("signin:register.contact_email_optional")}
            purpose="contact"
            requestOTP
          />
          <Space size="20px" />
          <FormTextField
            inputRef={emailOTPRef}
            errorText={error?.emailOTP}
            label={t("signin:register.email_otp")}
          />
        </>
      )}
      <Space size="20px" />
      <PhoneTextfield
        onChange={(value) => setPhone(value)}
        errorText={error?.phone}
        label={t("signin:register.phone")}
      />
      <Space size="25px" />
      <Grid container justifyContent="flex-start">
        <CheckBox
          onClick={() => setPromotion(!promotion)}
          isChecked={promotion}
        >
          <Trans
            t={t}
            i18nKey="signin:register.promote"
            components={[
              <Link
                style={{
                  color: "#FF5C00",
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={0}
                target="_blank"
                to="/privacy-terms"
              />,
            ]}
          />
        </CheckBox>
      </Grid>
      <Space size="22px" />
      <Grid container justifyContent="flex-start">
        <CheckBox onClick={() => setAgree(!agree)} isChecked={agree}>
          <Trans
            t={t}
            i18nKey="signin:register.web_agreement"
            components={[
              <Link
                style={{
                  color: "#FF5C00",
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={0}
                target="_blank"
                to="/tnc?id=web"
              />,
              <Link
                style={{
                  color: "#FF5C00",
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                key={2}
                target="_blank"
                to="/privacy-terms"
              />,
            ]}
          />
        </CheckBox>
      </Grid>
      <Space size="23px" />
      <CommonText style={{ width: "100%", textAlign: "left" }} mFontSize="14px">
        {t("profile:info.receive_promote_language")}
      </CommonText>
      <Space size="9px" />
      <LanguageToggleButton
        value={language}
        onChange={(event) => setLanguage(event.target.value)}
      />
      <Space size="18px" />
      {/* <ReCAPTCHA
                sitekey={constants.RECAPTCHA}
                onChange={onReCaptchaChange}
                ref={recaptchaRef}
            />
            <Space size="18px" /> */}
      <CommonButton
        disabled={!agree}
        size={"large"}
        type={"primary"}
        onClick={handleRegister}
      >
        {t("signin:register.register")}
      </CommonButton>
      <Space size="100px" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
