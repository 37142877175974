import React, { Component, useEffect, useRef, useState } from "react";
import styled from 'styled-components'
import { breakpoint } from '../../../utils/responsive'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@mui/material";


// TODO: this is copy from CommonCarousel, should be generalize
const BenefitCardCarousel = ({ images, imageProps, animationStartTime = 0, ...props }) => {

  const [isFirstAnimation, setIsFirstAnimation] = useState(false)
  const isMobile = useMediaQuery(breakpoint.mobile)
  const settings = {
    autoplay: true,
    autoplaySpeed: isFirstAnimation ? 3000 : animationStartTime + 3000,
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1
  };





  return <div style={{ marginBottom: 24 }}>
    <Slider {...settings}
      afterChange={() => { setIsFirstAnimation(true) }}>

      {Object.values(images).map((image, index) => {
        return <StyledImage src={image} style={{ width: isMobile ? 280 : 320, height: isMobile ? 280 : 320, padding: '0 1px' }} alt={`image_${index}`}>
        </StyledImage>
      })}

    </Slider>
  </div>
};
const StyledImage = styled.img`
  object-fit: cover;
  aspect-ratio: 1/1;
`

const StyledDiv = styled.div`
  width: 100%;
   height: 320px;
  @media ${breakpoint.mobile}{
    height: 280px;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    opacity: 1;
    transition: all 0.5s;
  }
  .slick-center {
    opacity: 1;
  }

  .slick-track{
    margin: 0;
  }
`;

export default BenefitCardCarousel;
