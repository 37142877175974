import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CommonNavBar from "../components/common/navbar/CommonNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import WebTnc from "../pageComponents/tnc/web";
import MembershipTnc from "../pageComponents/tnc/membership";
import GreySelectMenu from "../components/common/menus/GreySelectMenu";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Tnc() {
  const { t } = useTranslation("tnc");
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const currentId = query.get("id") || "";

  const webTnc = WebTnc();
  const membershipTnc = MembershipTnc();

  const MENU_ITEMS = Object.keys(t("menu", { returnObjects: true }));

  useEffect(() => {
    if (
      location.pathname === "/tnc" &&
      (!location.search || !currentId || !MENU_ITEMS.includes(currentId))
    ) {
      navigate(`/tnc?id=${MENU_ITEMS[0]}`, { replace: true });
    }
  }, [location, query]);

  return [
    <CommonNavBar theme="black" />,
    <GreySelectMenu
      pageId="tnc"
      title={t("landing.title")}
      subTitle="Terms and Conditions"
      menuItems={MENU_ITEMS}
      currentId={currentId}
    >
      {location.search === "?id=web" && <div>{webTnc}</div>}

      {location.search === "?id=membership" && <div>{membershipTnc}</div>}
    </GreySelectMenu>,
  ];
}

export default Tnc;
