import styled from "styled-components";
import CommonButton from "../../components/common/buttons/CommonButton";
import CommonText from "../../components/common/typographies/CommonText";
import Space from "../../components/common/Space";
import { useTranslation, Trans } from "react-i18next";
import AddressInputForm from "../../components/AddressInputForm";
import { Link } from "react-router-dom";
import NoticeIcon from "../../assets/register_notice.svg";
import { useState } from "react";
import { Dialog } from "@mui/material";
// import { types } from "../../components/AddressInputForm";
import _ from "lodash";
import { breakpoint } from "../../utils/responsive";
import { formatAddress } from "../../utils/addressFormat";
import { getOrderCounter } from "../../apis";
import { useQuery } from "react-query";
import moment from "moment";

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}
const DeliveryAddress = ({
  step,
  session,
  handleNext,
  address,
  setAddress,
}) => {
  const { t, i18n } = useTranslation(["signin", "common"]);
  const [disableChecker, setDisableChecker] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, isError, data } = useQuery(
    ["getOrderCounter", session?.accessToken],
    () => getOrderCounter(session?.accessToken)
  );

  return (
    <Container>
      <CommonText lineHeight="140%">{t("register.step") + step}</CommonText>
      <Space size="30px" />
      <CommonText fontSize="20px" mFontSize="16px" lineHeight="140%" whiteSpace="pre-line">
        {data?.data?.deliveryDate &&
          t("register.fill_in_address", {
            deliveryDate: moment(data?.data?.deliveryDate, "YYYY-M-D")
              .locale("en")
              .format(i18n.language == "zh" ? "YYYY年M月D日" : "D MMMM YYYY"),
          })}
      </CommonText>
      <Space size="30px" />
      <AddressInputForm
        address={address}
        onChange={(address) => setAddress(address)}
        setDisableChecker={setDisableChecker}
      />

      <Space size="30px" />
      <RemarkContainer>
        <RemarkOne>
          <img src={NoticeIcon} />
          <div style={{ marginLeft: 5 }}>
            <CommonText fontSize="18px" mFontSize="16px">
              <Trans
                t={t}
                i18nKey="common:address.sf_delivery_remark1"
                components={
                  <a
                    style={{
                      color: "#FF5C00",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    key={0}
                    target="_blank"
                    href={
                      i18n.language === "zh"
                        ? "https://htm.sf-express.com/hk/tc/dynamic_function/range/"
                        : "https://htm.sf-express.com/hk/en/dynamic_function/range/"
                    }
                  />
                }
              />
            </CommonText>
          </div>
        </RemarkOne>
        <Space size="12px" />
        <CommonText fontSize="16px" mFontSize="14px">
          {t("common:address.sf_delivery_remark2")}
        </CommonText>
      </RemarkContainer>

      <Space size="40px" />
      <ButtonContainer>
        <CommonButton
          style={{ width: "50%", maxWidth: 440 }}
          onClick={() => handleNext(true)}
        >
          {t("register.previous")}
        </CommonButton>
        <CommonButton
          style={{ width: "50%", maxWidth: 440, marginLeft: 30 }}
          type={"primary"}
          disabled={disableChecker}
          onClick={() => setIsOpen(true)}
        >
          {t("register.next")}
        </CommonButton>
      </ButtonContainer>

      <Dialog open={isOpen}>
        <DialogContainer>
          <CommonText textAlign="center" style={{ whiteSpace: "pre-line" }}>
            {t("register.address_remark")}
          </CommonText>

          <Space size="30px" />
          <CommonText fontSize="14px">{t("common:address.type")}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {t("common:address." + address?.type + "_address")}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">
            {t("common:address.Express_address")}
          </CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {formatAddress(address, i18n.language)}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">{t("common:address.name")}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {address?.name}
          </CommonText>
          <Space size="20px" />
          <CommonText fontSize="14px">{t("common:address.phone")}</CommonText>
          <CommonText fontSize="18px" mFontSize="16px">
            {address?.phone && insert(address?.phone, 4, "")}
          </CommonText>

          <Space size="40px" />
          <ButtonContainer>
            <CommonButton
              style={{ width: "50%", maxWidth: 440 }}
              onClick={() => setIsOpen(false)}
            >
              {t("common:update")}
            </CommonButton>
            <CommonButton
              style={{ width: "50%", maxWidth: 440, marginLeft: 30 }}
              type={"primary"}
              onClick={() => handleNext(false)}
            >
              {t("common:confirm")}
            </CommonButton>
          </ButtonContainer>
        </DialogContainer>
      </Dialog>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const RemarkContainer = styled.div`
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
  text-align: left;
  padding: 20px 16px;
`;

const RemarkOne = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DialogContainer = styled.div`
  @media ${breakpoint.desktop} {
    padding: 60px;
  }

  @media ${breakpoint.mobile} {
    padding: 20px;
  }
`;

export default DeliveryAddress;
