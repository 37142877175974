import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import InfiniteTextLoop from "../../components/home/InfiniteTextLoop";
import colors from "../../utils/colors";
import CommonText from "../../components/common/typographies/CommonText";
import ImgSmile from "../../assets/home_smileSticker.svg";
import ImgCrown from "../../assets/home_crownLineSticker.svg";
import MobileImgCrown from "../../assets/home_crownLineMobileSticker.svg";
import CommonBottomBar from "../../components/common/navbar/CommonBottomBar";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import Stack from "@mui/material/Stack";
import Space from "../../components/common/Space";

const DisableMiroRegister = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <StyledMainDiv>
      <StyledBarDiv>
        <GreenBar />
        <InfiniteTextLoop />
      </StyledBarDiv>
      <StyledContent>
        <ResponsiveUI
          mobile={
            <MobileStickerCrown>
              <StickerCrown src={MobileImgCrown} />
            </MobileStickerCrown>
          }
          desktop={<StickerCrown src={ImgCrown} />}
        />
        <div>
          <CommonText
            mFontSize="16px"
            fontSize={i18n.language === "zh" ? "24px" : "22px"}
            lineHeight="1.5"
            type="primary"
            whiteSpace="pre-wrap"
            style={{ position: "relative" }}
          >
            {t("info.suspendMiro")}
          </CommonText>
        </div>
      </StyledContent>
      <CommonBottomBar theme="black" />
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  &:before {
    position: absolute;
    top: 0px;

    border-top: solid 50px ${colors.white};
    border-right: 100vw solid rgba(0, 0, 0, 0);
    content: "";

    @media ${breakpoint.desktop} {
      border-top: solid 200px ${colors.white};
    }
  }

  position: relative;
  background-color: #aee020;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  height: auto;
  // @media ${breakpoint.largeDesktop} {
  //   height: 100vh;
  // }
`;

const StyledBarDiv = styled.div`
  position: absolute;
  top: 10px;
`;

const StyledContent = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 15px;

  @media ${breakpoint.mobile} {
    margin-top: 100px;
    margin-bottom: 60px;
  }

  @media ${breakpoint.desktop} {
    margin-top: 340px;
    margin-bottom: 40px;
    max-width: 980px;
    width: auto;
  }

  @media ${breakpoint.largeDesktop} {
    margin-top: 340px;
  }
`;

const ChineseTitle = styled.div`
  @media ${breakpoint.mobile} {
    margin-bottom: 30px;
  }

  @media ${breakpoint.desktop} {
    margin-bottom: 56px;
  }
`;

const GreenBar = styled.div`
  background: #0d5b57;
  width: 100%;
  height: 30px;
  transform: rotate(2deg) scale(1.1);

  position: absolute;
  top: 55%;

  @media ${breakpoint.desktop} {
    height: 70px;
    position: relative;
  }
`;

const MobileStickerCrown = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StickerCrown = styled.img`
  user-drag: none;

  @media ${breakpoint.mobile} {
    width: 150px;
  }

  @media ${breakpoint.desktop} {
    position: absolute;
    top: -95%;
    right: 0;
  }
`;

export default DisableMiroRegister;
