import React, { useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonText from "../common/typographies/CommonText";
import SelectUndeline from "../../assets/SelectUnderline.svg";
import colors from "../../utils/colors";
import { breakpoint, ResponsiveUI } from "../../utils/responsive";

const MemberCarousel = ({ images, data, handleClick }) => {
  const { t, i18n } = useTranslation("home");
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };

  return (
    <StyledMainDiv>
      <StyledSlider {...settings} ref={sliderRef}>
        {Object.values(images).map((image, index) => {
          if (index === currentSlide) {
            return (
              <StyledSlideDiv>
                <StyledImageDiv>
                  <StyledCurrentImg src={image + "-thumbnail"} />
                  <ShadowDiv />
                </StyledImageDiv>
                <StyledTitle>
                    <CommonText fontSize="50px" fontWeight="600" color={colors.white} uppercase>
                      {i18n.language === "en"
                        ? data && data[index].nameEn
                        : data && data[index].nameZh}
                    </CommonText>

                    <CommonText
                      fontSize="32px"
                      fontWeight="500"
                      color={colors.white}
                      uppercase
                    >
                      {i18n.language === "en"
                        ? data && data[index].performersEn
                        : data && data[index].performersZh}
                    </CommonText>

                    <StyledViewNow onClick={() => handleClick(index)}>
                      <CommonText
                        fontSize="18px"
                        fontWeight="600"
                        color={colors.white}
                      >
                        {t("button.viewNow")}
                      </CommonText>
                      <StyledSelectImg src={SelectUndeline} />
                    </StyledViewNow>
                  </StyledTitle>
              </StyledSlideDiv>
            );
          } else {
            return (
              <StyledSlideDiv>
                <StyledImg src={image + "-thumbnail"}></StyledImg>
              </StyledSlideDiv>
            );
          }
        })}
      </StyledSlider>
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  width: 100%;
`;

const StyledImg = styled.img`
  position: relative;
  height: 48vh;
  width: 85vh;
  object-fit: cover;
  padding: 0 15px;
  padding-top: 20px;
  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};
`;

const StyledCurrentImg = styled.img`
  position: relative;
  object-fit: cover;
  height: 48vh;
  width: 85vh;
  padding: 0 15px;
  padding-top: 20px;
  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};
  z-index: 10;
`;

const StyledSlideDiv = styled.div`
  width: 100%;
  height: calc(47vh + 100px);
  margin-top: 10px;
`;

const StyledImageDiv = styled.div`
  position: relative;
`;

const ShadowDiv = styled.div`
  height: 45vh;
  width: 83vh;
  transform: rotate(5deg);
  background: #d13333;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
`;

const StyledTitle = styled.div`
  text-align: left;
  position: relative;
  margin-left: 15px;
`;

const StyledSelectImg = styled.img`
  visibility: hidden;
`;

const StyledViewNow = styled.div`
  padding-top: 30px;
  cursor: pointer;
  &:hover {
    ${StyledSelectImg} {
      visibility: visible;
    }
  }
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    opacity: 0.3;
    transition: all 0.5s;
  }
  .slick-center {
    opacity: 1;
  }
`;

export default MemberCarousel;
