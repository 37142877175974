import { useMediaQuery } from "@mui/material";
import { useContext, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactGA from 'react-ga4'
import styled from "styled-components";
import { AppContext } from "../../AppContext";
import CommonButton from "../../components/common/buttons/CommonButton";
import CommonText from "../../components/common/typographies/CommonText";
import { breakpoint } from "../../utils/responsive";
import Disclaimer from "./Disclaimer";
import AvailableDate from "./RenewalMember/AvailableDate";
import RenewalBenefit from "./RenewalMember/RenewalBenefit";
import SmallStar from "../../assets/join_small_star.jpg";
import { ReactComponent as FeeIcon } from "../../assets/join_fee_icon.svg";
import { ReactComponent as DeadlineIcon } from "../../assets/join_deadline_icon.svg";
import { useNavigate } from "react-router-dom";

const benefits = [
  {
    title: 'join2024.renewalMember.benefits.0.title',
    content: 'join2024.renewalMember.benefits.0.content',
  },
  {
    title: 'join2024.renewalMember.benefits.1.title',
    content: 'join2024.renewalMember.benefits.1.content',
  },
  {
    title: 'join2024.renewalMember.benefits.2.title',
    content: 'join2024.renewalMember.benefits.2.content',
  },
  {
    title: 'join2024.renewalMember.benefits.3.title',
  },
  {
    title: 'join2024.renewalMember.benefits.4.title',
  },
  {
    title: 'join2024.renewalMember.benefits.5.title',
  },
];

export const isContainerWrapped = (ref) => {
  // if the flex container's element wrapped, element's x direction will be different
  let offsetTop;
  let flag = false;
  if (!ref) return false;
  ref?.current?.childNodes.forEach((item) => {
    if (offsetTop === undefined) {
      offsetTop = item.offsetTop;
    } else if (item.offsetTop !== offsetTop) {
      flag = true;
    }
  });
  return flag;
};

const RenewalMember = ({ data }) => {
  const { t, i18n } = useTranslation("signin");
  const { session } = useContext(AppContext);
  const navigate = useNavigate();
  const isLessThan860 = useMediaQuery(breakpoint.mobile);
  const isLessThan600 = useMediaQuery(breakpoint.smallTablet);
  const benefitsAndJoinContainerWrapperRef = useRef(null);
  const benefitsAndJoinContainerWrapped = isContainerWrapped(
    benefitsAndJoinContainerWrapperRef
  );

  const isButtonContainerDirectionRow = () => {
    if (isLessThan600) return false;
    if (!isLessThan600 && isLessThan860)
      return benefitsAndJoinContainerWrapped ? false : true;
    return true;
  };

  const getButtons = () => {
    if (session?.user?.memberType === "MIRO") {
      //existing miro
      return (
        <CommonButton
          width="100%"
          style={{
            maxWidth: 390,
            flexGrow: 1,
            backgroundColor: "black",
            color: "white",
            borderColor: "black",
          }}
          onClick={() => {
            ReactGA.event("button_click", { param: t('join2024.button.renew') })
            navigate("/renew", { replace: true })
          }}
        >
          {t('join2024.button.renew')}
        </CommonButton>
      );
    } else if (session?.user?.memberType === "WEB") {
      // expired miro
      return (
        <CommonButton
          width="100%"
          style={{
            maxWidth: 390,
            flexGrow: 1,
            backgroundColor: "black",
            color: "white",
            borderColor: "black",
          }}
          onClick={() => {

            ReactGA.event("button_click", { param: t('join2024.button.join') })
            navigate("/miro-register", { replace: true })
          }}
        >
          {t('join2024.button.join')}
        </CommonButton>
      );
    } else {
      return (
        <>
          <CommonButton
            type="filter"
            width="100%"
            style={{
              maxWidth: 390,
              flexGrow: 1,
              borderColor: "black",
              color: "black",
            }}
            onClick={() => {

              ReactGA.event("button_click", { param: t('join2024.button.signin') })
              navigate("/signin", { replace: true })
            }}
          >
            {t('join2024.button.signin')}
          </CommonButton>
          <CommonButton
            width="100%"
            style={{
              maxWidth: 390,
              flexGrow: 1,
              backgroundColor: "black",
              color: "white",
              borderColor: "black",
            }}
            onClick={() => {

              ReactGA.event("button_click", { param: t('join2024.button.join') })
              navigate("/miro-register", { replace: true })
            }}
          >
            {t('join2024.button.join')}
          </CommonButton>
        </>
      );
    }
  };

  return (
    <StyledContainer>
      <StyledStar style={{ left: "80%", top: 30 }} />
      <AvailableDate />
      <StyledBenefitsAndJoinContainer ref={benefitsAndJoinContainerWrapperRef}>
        <TimeAndFee>
          <StyledStar style={{ left: "10%", top: isLessThan860 ? -70 : -180 }} />
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FeeIcon style={{ marginRight: 8 }} />
              <CommonText mFontSize="16px" fontSize="20px" fontWeight="600">
                {t('join2024.renewalMember.membershipFeeTitle')}
              </CommonText>
            </div>
            <CommonText mFontSize="24px" fontSize="40px" fontWeight="800">
              HK$ <LargerText>440</LargerText>
            </CommonText>
          </div>
          <div style={{ marginTop: 40, marginBottom: 100 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DeadlineIcon style={{ marginRight: 8 }} />
              <CommonText mFontSize="16px" fontSize="20px" fontWeight="600">
                {t('join2024.renewalMember.deadlineTitle')}
              </CommonText>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

              <CommonText mFontSize="28px" fontSize="36px" fontWeight="800" whiteSpace='nowrap' style={{ marginRight: 8 }}>
                <Trans
                  t={t}
                  i18nKey="join2024.renewalMember.deadlineDate"
                  components={[
                    <SmallerText1></SmallerText1>,
                    <SmallerText1></SmallerText1>,
                    <SmallerText1></SmallerText1>,
                  ]}
                />
              </CommonText>
              <CommonText mFontSize="28px" fontSize="36px" fontWeight="800">
                <Trans
                  t={t}
                  i18nKey="join2024.renewalMember.deadlineTime"
                  components={[
                    <DueTimeText></DueTimeText>,
                  ]}
                />
              </CommonText>
            </div>
          </div>
        </TimeAndFee>
        <RenewalBenefitContainer>
          <CommonText mFontSize="20px" fontSize="24px" fontWeight="600">
            {t('join2024.renewalMember.benefits.title1')}
          </CommonText>
          {[benefits[0]].map((benefit, idx) => {
            return <RenewalBenefit title={t(benefit.title)} content={t(benefit.content)} key={idx} />;
          })}
          <CommonText mFontSize="16px" fontSize="16px" fontWeight="600">
            {t('join2024.renewalMember.benefits.title2')}
          </CommonText>
          {benefits.slice(1).map((benefit, idx) => {
            return <RenewalBenefit title={t(benefit.title)} content={t(benefit.content)} key={idx} />;
          })}
          <Disclaimer />
        </RenewalBenefitContainer>
      </StyledBenefitsAndJoinContainer>
      <StyledButtonContainer isRow={isButtonContainerDirectionRow()}>
        {getButtons()}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

const StyledStar = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(${SmallStar});
`;

const StyledContainer = styled.div`
  position: relative;
  @media ${breakpoint.mobile} {
    margin: 0 20px;
  }
  @media ${breakpoint.smallTablet} {
    margin: 0;
  }
  @media ${breakpoint.desktop} {
    margin: 0 50px;
  }
`;
const StyledBenefitsAndJoinContainer = styled.div`
  position: relative;
  margin: auto;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  @media ${breakpoint.mobile} {
    max-width: 660px;
  }
  @media ${breakpoint.smallTablet} {
    width: 100%;
    max-width: 390px;
    flex-direction: column;
    padding: 0 20px;
  }
`;

const TimeAndFee = styled.div`
  text-align: start;
  flex-shrink: 0;
  padding-right: 60px;
  @media ${breakpoint.onlyTablet}{
    padding-right: 20px;
  }
  @media ${breakpoint.smallTablet} {
    padding-right: 0;
  }
`;
const RenewalBenefitContainer = styled.div`
  flex-grow: 1;
  > * + * {
    margin-top: 24px;
  }

  @media ${breakpoint.mobile} {
    flex-basis: 320px;
  }
  @media ${breakpoint.smallTablet} {
    flex-basis: 0px;
  }

  @media ${breakpoint.desktop} {
    flex-basis: 380px;
  }
  text-align: start;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isRow ? "row" : "column")};
  justify-content: center;
  @media ${breakpoint.mobile} {
    width: 100%;
    max-width: 660px;
    margin: auto;

    button:first-child {
      margin-right: ${(props) => props.isRow && "14px"};
      margin-bottom: ${(props) => !props.isRow && "14px"};
    }
  }
  @media ${breakpoint.smallTablet} {
    max-width: 390px;
    padding: 20px;
  }

  @media ${breakpoint.desktop} {
    button:first-child {
      margin-right: 24px;
    }
  }
`;

const LargerText = styled.span`
  //26
  font-size: 90px;
  @media ${breakpoint.isMobile} {
    font-size: 60px;
  }
  //18
`;

const SmallerText1 = styled.span`
  margin: 0 2px;
  //26
  font-size: 28px;
  @media ${breakpoint.isMobile} {
    font-size: 18px;
  }
  //18
`;
const DueTimeText = styled.span`
  white-space: nowrap;
  //18
  font-size: 18px;
  //16
  @media ${breakpoint.isMobile} {
    font-size: 16px;
  }
`;

export default RenewalMember;
